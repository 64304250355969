.product-base-top-actions {
  background-color: #fff;

  &__item {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    background-color: var(--grey-light-color);
    padding: 6px 10px;
  }

  &__icon {
    display: flex;
  }

  &__item-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    margin-left: 10px;
  }
}

@include respond-up('large') {
  .product-base-top-actions {
    padding: 20px 30px;
  }
}

@include respond('medium') {
  .product-base-top-actions {
    padding: 20px 28px;
  }
}

@include respond-down('small') {
  .product-base-top-actions {
    padding: 20px 18px;
  }
}