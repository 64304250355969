.catalog-base {
  background-color: var(--gray-background-color);
}

@include respond-up('large') {
  .catalog-base {
    &__main {
      padding-bottom: 120px;
    }

    &__producers {
      margin-top: 120px;
    }

    &__info {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .catalog-base {

  }
}

@include respond('medium') {
  .catalog-base {
    &__main {
      padding-bottom: 90px;
    }

    &__producers,
    &__info {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .catalog-base {

  }
}

@include respond-down('small') {
  .catalog-base {
    &__main {
      padding-bottom: 50px;
    }

    &__producers,
    &__info {
      margin-top: 50px;
    }
  }
}