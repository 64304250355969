.custom-select {
  position: relative;

  &_single {
    .custom-select {
      &__input {
        &:checked + .custom-select__label {
          background-color: var(--grey-stroke-color);
        }
      }
    }
  }

  &_multi {
    .custom-select {
      &__choice-name {
        margin-left: 8px;
      }
    }
  }

  &._open {
    z-index: 500;

    .custom-select {
      &__arrow-icon svg {
        transform: rotate(180deg);
      }

      &__content-wrap,
      &__list {
        max-height: 246px;
      }
    }
  }

  &._disabled {
    cursor: not-allowed;

    .custom-select {
      &__inner {
        opacity: .6;
        pointer-events: none;
      }
    }
  }

  &__preloader {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 50;
    top: 50%;
    right: 30px;
    transform: translate3d(0, -50%, 0);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    flex: 0 0 100%;
  }

  &__field-wrapper {
    width: 100%;
    position: relative;
    z-index: 35;
  }

  &__arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 8px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    right: 15px;

    svg {
      width: 100%;
      height: 100%;
      transition: transform .2s;
    }
  }

  &__field {
    @extend .text__small;
    height: 44px !important;
    padding: 11px 40px 11px 15px !important;
    border: 1px solid var(--grey-stroke-color) !important;
    width: 100%;

    &:read-only {
      cursor: pointer;
    }
  }

  &__content-wrap {
    display: block;
    background-color: var(--grey-light-color);
    filter: drop-shadow(0px 10px 20px rgba(45, 41, 40, 0.1));
    max-height: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    min-width: 100%;
    top: 100%;
    z-index: 30;
  }

  &__list {
    overflow: auto;
    padding-top: 11px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  &__item {
    width: 100%;
    display: block;

    &._hidden {
      display: none;
    }

    &:hover {
      background-color: var(--color-grey-backgorund);
    }
  }

  &__input {
    display: none;

    &:checked + .custom-select__label {
      .custom-select {
        &__choice-icon {
          border-color: var(--black-color);

          svg {
            opacity: 1;
          }
        }
      }
    }

    + .custom-select__label {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 4px 15px;
      margin: 0;

      &_type-url{
        padding: 0;
      }

      &:hover {
        background-color: #fff;
      }

      &::after, &::before {
        display: none;
      }
    }
  }

  &__choice-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid var(--grey-stroke-color);
    transition: background-color .2s, border-color .2s;
    transition-timing-function: var(--default-timing-function);

    svg {
      width: 100%;
      height: 100%;
      transition: opacity .2s var(--default-timing-function);
      opacity: 0;
    }
  }

  &__choice-name {
    @extend .text__small;
    color: var(--black-color);
  }

  &__filter {
    position: relative;
    padding: 0 12px;
    margin-top: 10px;
  }

  &__filter-label {
    height: 34px;
    width: 100%;
    border: 1px solid var(--color-gray-border);
    padding: 7px 32px 7px 8px;
  }

  &__filter-icon {
    display: flex;
    height: max-content;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 20px;
    transform: translate3d(0, -50%, 0);
  }

  &__no-choices {
    @extend .text__small;
    color: var(--black-color);
    padding: 8px;
  }
}