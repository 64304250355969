.product-base-tabs {
  &__tabs {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__description {
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .product-base-tabs {
    &__item {
      padding: 50px calc(var(--grid-column3) + var(--grid-gap)) 50px 50px;
    }
  }
}

@include respond-up('medium') {
  .product-base-tabs {

  }
}

@include respond('medium') {
  .product-base-tabs {
    &__item {
      padding: 35px 28px;
    }
  }
}

@include respond-down('medium') {
  .product-base-tabs {

  }
}

@include respond-down('small') {
  .product-base-tabs {

  }
}