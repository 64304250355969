.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--gray-background-color);

  &._menu-open{
    .layout{
      &__main{
        &:before{
          background: rgba(0,0,0, .3);
        }
      }
    }
  }

  &__main{
    position: relative;
    &:before{
      z-index: 300;
      transition: background .3s;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: transparent;
      pointer-events: none;
    }
  }
}