.filter-mobile {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-background-color-50);
  z-index: 1500;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;

  &._visible {
    opacity: 1;
    pointer-events: auto;
  }

  &__inner {
    position: relative;
    background-color: var(--grey-light-color);
    display: flex;
    flex-direction: column;
  }

  &__heading {
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-stroke-color);
    flex: 0 0 auto;
  }

  &__heading-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    margin-left: 45px;
  }

  &__close {
    display: flex;
    padding: 15px;
    margin: -15px;
    margin-left: auto;
  }

  &__main {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 94px;
    flex: 0 1 100%;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  &__switcher-wrap {
    padding: 20px var(--grid-spacer);
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__filters-wrap {

  }

  &__submit-wrap {
    position: absolute;
    z-index: 50;
    bottom: 25px;
    left: var(--grid-spacer);
    right: var(--grid-spacer);
  }

  &__submit {
    width: 100%;
  }
}

@include respond-up('large') {
  .filter-mobile {

  }
}

@include respond-up('medium') {
  .filter-mobile {

  }
}

@include respond('medium') {
  .filter-mobile {
    &__inner {
      width: 406px;
    }

    &__heading {
      padding: 16px 34px;
    }
  }
}

@include respond-down('medium') {
  .filter-mobile {

  }
}

@include respond-down('small') {
  .filter-mobile {
    &__inner {
      width: 100%;
    }

    &__heading {
      padding: 16px var(--grid-spacer);
    }
  }
}