.profile-header {
  display: flex;
  justify-content: space-between;

  &_h1 {

  }

  &__logout {
    display: flex;
    align-items: center;
  }

  &__logout-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  &__logout-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: var(--black-color);
  }
}

@include respond-up('large') {
  .profile-header {

  }
}

@include respond-up('medium') {
  .profile-header {

  }
}

@include respond('medium') {
  .profile-header {

  }
}

@include respond-down('medium') {
  .profile-header {

  }
}

@include respond-down('small') {
  .profile-header {

  }
}