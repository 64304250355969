.profile-layout {
  &__menu {

  }

  &__content {

  }
}

@include respond-up('large') {
  .profile-layout {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns-number), 1fr);
    grid-gap: var(--grid-gap);
    padding-bottom: 120px;

    &__menu {
      grid-column: 1/4;
    }

    &__content {
      grid-column: 4/calc(var(--grid-columns-number) + 1);
    }
  }
}

@include respond-up('medium') {
  .profile-layout {

  }
}

@include respond('medium') {
  .profile-layout {
    padding-bottom: 90px;

    &__content {
      margin-top: 25px;
    }
  }
}

@include respond-down('medium') {
  .profile-layout {
    display: flex;
    flex-wrap: wrap;

    &__menu, &__content {
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .profile-layout {
    padding-bottom: 50px;

    &__content {
      margin-top: 22px;
    }
  }
}