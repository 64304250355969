.article-base {
  &__top {
    background-color: #fff;
  }

  &__sticky {
    position: sticky;
    top: 140px;
  }

  &__picture,
  &__picture-small-first,
  &__picture-small-second {
    display: flex;
    width: 100%;
  }

  &__image,
  &__image-small-first,
  &__image-small-second {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__sticky-date {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  &__sticky-item {
    &:last-child {
      .article-base {
        &__sticky-link {
          border-bottom: 1px solid var(--grey-stroke-color);
        }
      }
    }
  }

  &__sticky-link {
    display: block;
    border-top: 1px solid var(--grey-stroke-color);
    padding: 20px 0;
  }

  &__sticky-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__sticky-link-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__sticky-list {
    margin-top: 22px;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__image-wrap {
    display: flex;
    column-gap: var(--grid-gap);
  }
}

@include respond-up('large') {
  .article-base {
    &__main {
      grid-column: 1/9;
    }

    &__sticky-wrap {
      grid-column: 10/13;
    }

    &__picture {
      height: calc((492 / 1500) * 100vw);
      max-height: 619px;
    }

    &__picture-small-first {
      height: calc((var(--grid-column4) * 393/380));
    }

    &__picture-small-second {
      height: calc((var(--grid-column4) * 254/380));
    }

    &__top {
      padding: 40px 0 100px;
    }

    &__sticky-link-title {
      display: inline;
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color .4s;
    }

    &__sticky-link {
      &:hover {
        .article-base {
          &__sticky-link-title {
            border-bottom-color: var(--black-color);
          }
        }
      }
    }

    &__list {
      margin-top: 70px;
      row-gap: 60px;
    }

    &__image-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .article-base {
    &__image-description {
      margin-top: 22px;
    }
  }
}

@include respond('medium') {
  .article-base {
    &__main {
      grid-column: 1/7;
    }

    &__picture {
      height: calc((437 / 768) * 100vw);
    }

    &__top {
      padding: 30px 0 70px;
    }

    &__picture-small-first {
      height: calc((var(--grid-column3) * 352/340));
    }

    &__picture-small-second {
      height: calc((var(--grid-column3) * 227/340));
    }
  }
}

@include respond-down('medium') {
  .article-base {
    &__sticky-wrap {
      display: none;
    }

    &__list {
      margin-top: 40px;
      row-gap: 40px;
    }

    &__image-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .article-base {
    &__main {
      grid-column: 1/5;
    }

    &__picture {
      height: calc((211 / 375) * 100vw);
    }

    &__top {
      padding: 20px 0 40px;
    }

    &__picture-small-first {
      height: calc((var(--grid-column2) * 168/162));
    }

    &__picture-small-second {
      height: calc((var(--grid-column2) * 108/162));
    }
    &__image-description {
      margin-top: 18px;
    }
  }
}