.cart-oils{
  position: relative;
  .swiper-button-next{
    display: none;
  }
  .swiper-button-prev{
    display: none;
  }

  &__item{
    background: var(--white-color);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__top{
    display: grid;
    grid-template-columns: 30% 1fr;
  }

  &__bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image-wrap{
    //height: calc(74 / 1920 * 100vw);
    //width: calc(80 / 1920 * 100vw);
  }

  &__image{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__name{
    font-size: 15px;
    font-weight: 400;
    line-height: 20.25px;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--black-color);
  }
}

@include respond-up('large') {
  .cart-oils{
    margin-top: 50px;
    &__title{
      margin-bottom: 19px;
    }
    &__item{
      height: 169px;
    }

    &__name{
      margin-left: 16px;
    }

    &__nav{
      position: absolute;
      top: 0;
      right: 0;
    }

    &__prev{
      margin-right: 9px;
    }

    &__prev, &__next{
      width: 30px !important;
      height: 30px !important;
    }
  }
}

@include respond('medium') {
  .cart-oils{
    margin-top: 45px;

    &__top{
      margin-bottom: 16px;
    }
    &__image-wrap{
      margin-right: 10px;
    }
    &__title{
      margin-bottom: 15px;
    }
    &__slider-wrap{
      overflow: hidden;
      margin-left: -30px;
      margin-right: -30px;
    }

    &__nav{
      display: none;
    }

    &__slider{
      &.swiper{
        margin-left: -30px;
        margin-right: 90px;
        width: auto;
        overflow: visible;
      }
      .swiper-wrapper{
        margin-left: 60px;
      }
    }

    &__name{
      font-size: clamp(13px, calc(14/1199 * 100vw), 14px);
      font-weight: 400;
      line-height: 18.9px;
      letter-spacing: -0.02em;
    }
  }
}

@include respond-down('small') {
  .cart-oils{
    margin-top: 35px;
    &__title{
      margin-bottom: 15px;
    }
    &__slider-wrap{
      overflow: hidden;
      margin-left: -19px;
      margin-right: -19px;
    }

    &__nav{
      display: none;
    }

    &__slider{
      &.swiper{
        margin-left: -19px;
        margin-right: 90px;
        width: auto;
        overflow: visible;
      }
      .swiper-wrapper{
        margin-left: 38px;
      }
    }

    &__slide{
      height: 131px;
      justify-content: space-between;
    }

    &__top{
      margin-bottom: 16px;
    }
    &__name{
      font-size: clamp(13px, calc(14/768 * 100vw), 14px);
      font-weight: 400;
      line-height: 18.9px;
      letter-spacing: -0.02em;
      margin-left: 10px;
    }
  }
}