.background {
  &_gray{
    background-color: #EEEEEE;
  }
}

@include respond-up('large') {
  .background {

  }
}

@include respond-up('medium') {
  .background {

  }
}

@include respond('medium') {
  .background {

  }
}

@include respond-down('medium') {
  .background {

  }
}

@include respond-down('small') {
  .background {

  }
}