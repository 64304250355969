.product-added {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: var(--grid-column8);

  &__title {
    text-align: center;
  }

  &__main {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__bottom {
    display: flex;
    margin-top: 35px;
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
  }

  &__total-items {
    color: var(--grey-text-color);
  }

  &__total-price {
    margin-top: 4px;
  }

  &__already-in-cart-item {
    &:first-child {
      border-top: 1px solid var(--grey-stroke-color);
    }
  }

  &__bottom-info {
    margin-right: 10px;
  }
}

@include respond-up('large') {
  .product-added {
    &__already-in-cart {
      margin-top: 35px;
    }

    &__already-in-cart-list {
      margin-top: 15px;
    }
  }
}

@include respond-up('medium') {
  .product-added {
    &__bottom {
      justify-content: space-between;
    }

    &__to-cart {
      &.button {
        margin-left: 12px;
      }
    }

    &__main {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  .product-added {

  }
}

@include respond-down('medium') {
  .product-added {
    &__already-in-cart {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .product-added {
    &__already-in-cart-list {
      margin-top: 12px;
    }

    &__main {
      margin-top: 20px;
    }

    &__bottom {
      flex-direction: column;
    }

    &__buttons {
      flex-direction: column;
      margin-top: 15px;
    }

    &__close {
      &.button {
        width: 100%;
      }
    }

    &__to-cart {
      &.button {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
}