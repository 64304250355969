.product-added-card {
  display: grid;
  border-bottom: 1px solid var(--grey-stroke-color);

  &__image-wrap {
    grid-area: image;
    display: flex;
  }

  &__name-wrap {
    grid-area: name;
  }

  &__panel-wrap {
    grid-area: panel;
    display: flex;
    align-items: flex-start;
  }

  &__price-wrap {
    grid-area: price;
    display: flex;
  }

  &__remove {
    grid-area: remove;
    display: flex;
    height: max-content;
  }

  &__panel-input {
    border: none;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: var(--black-color);
    flex: 0 0 28px;
    width: 28px;
    height: 38px;
    margin: 0 4px;
    pointer-events: none;
  }

  &__panel-button {
    background-color: unset;
    color: var(--black-color);
    border-radius: 100px;
    flex: 0 0 38px;
    width: 38px;
    height: 38px;
    border: 1px solid var(--grey-stroke-color);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
      height: 10px;
      margin: 0;
    }
  }

  &__panel {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  &__panel-message.text {
    z-index: 30;
    position: absolute;
    background-color: white;
    color: var(--grey-text-color);
    font-size: 12px;
    width: 174px;
    top: calc(100% + 5px);
    padding: 14px;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));

    &::before {
      content: '';
      position: absolute;
      border: 7px solid var(--t);
      border-bottom-color: white;
      top: -14px;
    }
  }

  &__measure.text {
    color: var(--grey-text-color);
    margin-left: 4px;
  }

  &__item-price {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  &__picture {
    display: flex;
    overflow: hidden;
  }

  &__image {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &__img {
    object-fit: contain;
    object-position: center;
  }
}

@include respond-up('large') {
  .product-added-card {
    grid-template-columns: auto 1fr auto var(--grid-column2) auto;

    &__image-wrap {
      padding-right: 30px;
    }

    &__picture {
      width: 121px;
      height: 111px;
    }

    &__name-wrap {
      padding-top: 10px;
      padding-right: 40px;
    }

    &__panel-wrap {
      margin-top: 10px;
    }

    &__price-wrap {
      margin-top: 16px;
    }

    &__remove {
      margin-top: 17px;
    }
  }
}

@include respond-up('medium') {
  .product-added-card {
    padding: 20px 0;
    grid-template-areas:
            "image name panel price remove"
            "image info info info info";

    &:nth-child(n + 2) {
      margin-top: 14px;
    }

    &__price-wrap {
      justify-content: center;
    }
  }
}

@include respond('medium') {
  .product-added-card {
    grid-template-columns: auto 1fr auto calc(var(--grid-column1) + var(--grid-gap) * 2) auto;

    &__image-wrap {
      padding-right: 14px;
    }

    &__picture {
      width: 107px;
      height: 98px;
    }

    &__name-wrap {
      padding-right: var(--grid-gap);
    }

    &__price-wrap {
      margin-top: 6px;
      padding: 0 var(--grid-gap);
    }

    &__remove {
      margin-top: 6px;
    }
  }
}

@include respond-down('medium') {
  .product-added-card {

  }
}

@include respond-down('small') {
  .product-added-card {
    padding: 15px 0;
    grid-template-columns: auto 1fr 1fr auto;
    grid-template-areas:
            "image name name remove"
            "image info info info"
            "panel panel price price";

    &:nth-child(n + 2) {
      margin-top: 8px;
    }

    &__image-wrap {
      padding-right: 10px;
    }

    &__picture {
      width: 74px;
      height: 68px;
    }

    &__name-wrap {
      margin-top: 3px;
      padding-right: 24px;
    }

    &__price-wrap {
      align-items: center;
      margin-top: 20px;
    }

    &__panel-wrap {
      margin-top: 14px;
    }
  }
}