.price-competitor-filters {
  width: var(--grid-column12);

  &__fields {
    display: flex;
    flex-wrap: wrap;
  }

  &__field {
    flex: 0 0 200px;
    margin-right: var(--grid-gap);
  }

  &__bottom {
    margin-top: var(--grid-gap);
    display: flex;
    flex-wrap: wrap;
  }

  &__prices-field {
    display: flex;
    align-items: flex-end;

    .vue-field_select {
      margin-left: 10px;
    }
  }

  &__prices-field-title {
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 44px;
  }

  &__prices-field-checkbox {
    height: 44px;
  }

  &__tracked,
  &__failed-links {
    margin-left: var(--grid-gap);
  }
}

@include respond-up('large') {
  .price-competitor-filters {

  }
}

@include respond-up('medium') {
  .price-competitor-filters {

  }
}

@include respond('medium') {
  .price-competitor-filters {

  }
}

@include respond-down('medium') {
  .price-competitor-filters {

  }
}

@include respond-down('small') {
  .price-competitor-filters {

  }
}