.price-manual {
  .price-competitor-main{
    &__inputs{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 30px;
    }
  }

  .price-competitor-filters__bottom{
    margin-bottom: 30px;
    .vue-field{
      margin-right: 30px;
    }
  }

  .price-competitor-main__heading-column,
  .price-competitor-main__item-column {
    width: 1000px;
  }


  .price-competitor-main__heading-row,.price-competitor-main__item{
    height: 145px;
  }

  .price-competitor-main__price-button {
    &._hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}