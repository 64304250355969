.catalog-selection-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    background-color: var(--blue-background-color);
    width: 100%;
  }

  &__title {
    position: relative;
    z-index: 20;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 20;
  }

  &__icon-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__circle-wrap {
    display: flex;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-color);
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }

  &__icon {
    display: flex;
  }

  &__arrow-wrap {
    width: 8px;
    height: 14px;
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .catalog-selection-card {
    padding: 25px 22px 9px;
    width: 285px;

    &::before {
      transition: height .4s;
      height: 71px;
    }

    &:hover {
      &::before {
        height: 100%;
      }

      .catalog-selection-card {
        &__circle {
          background-color: var(--primary-color);

          svg path {
            stroke: #fff;
          }
        }
      }
    }

    &__icons {
      margin-top: 36px;
    }

    &__icon-wrap {
      padding-top: 25px;
    }

    &__circle {
      transition: background-color .4s;

      svg path {
        transition: stroke .4s;
      }
    }
  }
}

@include respond-up('medium') {
  .catalog-selection-card {

  }
}

@include respond('medium') {
  .catalog-selection-card {
    padding: 22px 22px 10px;

    &::before {
      height: 65px;
    }

    &__icons {
      margin-top: 22px;
    }

    &__icon-wrap {
      padding-top: 19px;
    }
  }
}

@include respond-down('medium') {
  .catalog-selection-card {

  }
}

@include respond-down('small') {
  .catalog-selection-card {
    padding: 22px 22px 6px;

    &::before {
      height: 64px;
    }

    &__icons {
      margin-top: 10px;
    }

    &__icon-wrap {
      padding-top: 22px;
    }
  }
}