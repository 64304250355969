.company-partners {
  &__item {
    background-color: #fff;
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-stroke-color);
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;object-fit: cover;
  }

  &__certificate {
    background-color: #fff;
    box-shadow: 0 5px 20px rgba(50, 59, 86, 0.12);
    border-radius: 3px;
    display: flex;
    width: max-content;
    height: max-content;
  }

  &__item-inner {
    display: flex;
    width: 100%;
  }

  &__item-description {
    color: var(--grey-text-color);
    margin-top: 15px;
  }
}

@include respond-up('large') {
  .company-partners {
    &__item {
      padding: 35px 45px 30px;
    }

    &__logo {
      .company-partners__picture {
        width: 150px;
        height: 80px;
      }
    }

    &__item-inner {
      margin-left: 111px;
    }

    &__certificate {
      padding: 8px;

      .company-partners__picture {
        width: 129px;
        height: 177px;
      }
    }

    &__text-wrap {
      margin-right: calc(var(--grid-column) + var(--grid-gap));
    }

    &__list {
      margin-top: 36px;
    }
  }
}

@include respond-up('medium') {
  .company-partners {
    &__item-inner {
      justify-content: space-between;
    }

    //&__item {
    //  justify-content: space-between;
    //}
  }
}

@include respond('medium') {
  .company-partners {
    &__item {
      padding: 30px 25px;
    }

    &__item-inner {
      margin-left: 35px;
    }

    &__list {
      margin-top: 28px;
    }

    &__text-wrap {
      margin-right: 35px;
    }
  }
}

@include respond-down('medium') {
  .company-partners {
    &__certificate {
      padding: 5px;
    }

    &__logo {
      .company-partners__picture {
        width: 105px;
        height: 59px;
      }
    }

    &__certificate {
      .company-partners__picture {
        width: 79px;
        height: 108px;
      }
    }
  }
}

@include respond-down('small') {
  .company-partners {
    &__item {
      padding: 20px;
      flex-direction: column;
    }

    &__item-inner {
      flex-direction: column;
      margin-top: 25px;
    }

    &__list {
      margin-top: 20px;
    }

    &__certificate {
      margin-top: 25px;
    }
  }
}