.record-for-service-edit-confirm {
  display: flex;

  &__buttons {
    display: flex;
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: var(--grey-text-color);
  }

  &__remove-icon {
    display: flex;

    svg path {
      fill: var(--grey-stroke-color);
    }
  }

  &__remove-title {
    margin-left: 6px;
  }
}

@include respond-up('large') {
  .record-for-service-edit-confirm {
    &__submit {
      margin-left: 12px;
    }
  }
}

@include respond-up('medium') {
  .record-for-service-edit-confirm {
    align-items: center;
    justify-content: space-between;

    &__buttons {
      align-items: center;
    }

    &__remove {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .record-for-service-edit-confirm {
    &__submit {
      margin-left: 8px;
    }
  }
}

@include respond-down('medium') {
  .record-for-service-edit-confirm {

  }
}

@include respond-down('small') {
  .record-for-service-edit-confirm {
    flex-direction: column-reverse;

    &__buttons {
      flex-direction: column-reverse;
      padding: 25px 18px;
    }

    &__cancel {
      margin-top: 8px;
      width: 100%;
    }

    &__submit {
      width: 100%;
    }

    &__remove {
      padding: 15px;
      font-size: 13px;
      border-top: 1px solid var(--grey-stroke-color);
    }
  }
}