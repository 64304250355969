
.record-modal {
  max-width: 600px;
  width: 600px;

  &__record-number {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #798297;
    background: #F1F2F4;
    border-radius: 3px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 6px;
  }

  &__record-date {
    font-weight: 500;
    font-size: 24px;
    line-height: 117%;
    color: #1F2129;
    box-shadow: 0px 1px 0px #E7E7E7;
    margin: 0 -33px;
    padding: 10px 33px 24px;
  }

  &__tabs {
    border-bottom: 1px solid var(--gray);
    background: #F5F5F6;
    border-radius: 3px 3px 0px 0px;
    padding: 30px 33px 15px;
  }

  &__tabs-list {
    display: flex;
  }

  &__tabs-item {
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      background: transparent;
      width: 100%;
      right: 0;
      left: 0;
      bottom: -15px;
      height: 2px;
      display: block;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    &._active {
      &:before {
        background: #000000;
      }
    }
  }

  &__tabs-item-link {
    background: rgba(121, 130, 151, 0.3);
    border-radius: 3px;
    padding: 8px 14px;
    display: flex;
  }

  &__tabs-content {
    padding: 30px 33px;
    border-radius: 0px 0px 3px 3px;
  }

  &__tabs-content-list {

  }

  &__tabs-content-item {
    display: none;

    &._active {
      display: block;
    }
  }
}
