.custom-sort-field {
  &__container {
    position: relative;

    &._open {
      z-index: 500;

      .custom-sort-field {
        &__icon svg {
          transform: rotate(180deg);
        }

        &__values-wrapper,
        &__list {
          max-height: 246px;
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 7px;
    margin: -7px;
    font-size: 14px;
  }

  &__icon {
    margin-left: 8px;

    svg {
      transition: transform .3s;

      path {
        fill: var(--primary-color);
      }
    }
  }

  &__values-wrapper {
    display: block;
    background-color: #fff;
    filter: drop-shadow(0px 10px 20px rgba(45, 41, 40, 0.1));
    max-height: 0;
    overflow: hidden;
    position: absolute;
    left: 50px;
    min-width: max-content;
    top: 100%;
    z-index: 30;
  }

  &__values {
    padding: 11px 0;
  }

  &__value {
    background-color: var(--t);
    display: flex;

    input {
      display: none;
    }

    label {
      cursor: pointer;
      padding: 4px 15px;
      width: 100%;
    }
  }

  &__choice-name {
    &_value {
      color: var(--primary-color);
    }
  }

  &__item-label {
    text-transform: capitalize;
  }
}

@include respond-up('large') {
  .custom-sort-field {
    &__value {
      transition: background-color .3s;

      &:hover {
        background-color: var(--grey-light-color);
      }
    }
  }
}

@media (max-width: 371px){
  .custom-sort-field {
    &__link {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}