.company-advantages {
  position: relative;
  display: flex;

  &__heading {
    position: absolute;
    z-index: 25;
    border-bottom: 1px solid var(--white-color-60);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #fff;
  }

  &__pagination {
    position: relative;
    color: #fff;
    width: var(--grid-column1);
    height: 22px;

    .swiper-pagination {
      bottom: 0;
    }
  }

  &__text-slider {
    background-color: var(--primary-color);
  }

  &__images-slider-wrap {
    display: flex;
  }

  &__picture-wrap {
    width: 100%;
    height: 100%;
    transition: transform .8s;
  }

  &__picture {
    position: relative;
    display: flex;
    overflow: hidden;
    transition: width .8s;
    width: 100%;
    height: 100%;
  }

  &__image {
    position: absolute;
    max-width: unset;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    object-fit: cover;
  }

  &__text-slider-title {
    color: #fff;
  }

  &__text-slider-description {
    color: #fff;
    margin-top: 20px;
  }

  &__text-slider-item {
    display: flex;
    flex-direction: column;

    &.swiper-slide-active {
      transition-delay: .4s !important;
    }
  }

  &__arrows {
    position: absolute;
    z-index: 25;
  }

  &__images-slider-list {
    transform: translate3d(0, 0, 0) !important;
  }

  &__images-slider {
    &.swiper-initialized {
      .company-advantages {
        &__images-slider-item {
          &.swiper-slide,
          &.swiper-slide-prev,
          &.swiper-slide-next {
            opacity: 1 !important;
            z-index: 25;

            .company-advantages {
              &__picture-wrap {
                transform: translate3d(100%, 0, 0);
                transition-delay: .8s;
                transition-duration: 0s;
              }

              &__picture {
                width: 50%;
                transition-delay: .8s;
                transition-duration: 0s;
              }

              &__image {
                transform: translate3d(-100%, -50%, 0);
                transition-duration: .8s;
              }
            }
          }

          &.swiper-slide-duplicate-active,
          &.swiper-slide-active {
            opacity: 1 !important;
            z-index: 50;

            .company-advantages {
              &__picture-wrap {
                transform: translate3d(0, 0, 0);
                transition-delay: 0s;
                transition-duration: .8s;
              }

              &__picture {
                width: 100%;
                transition-delay: 0s;
                transition-duration: .8s;
              }

              &__image {
                transform: translate3d(-50%, -50%, 0);
                transition-duration: 0s;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .company-advantages {
    height: calc((602 / 1500) * 100vw);
    max-height: 700px;

    &__image {
      height: calc((602 / 1500) * 100vw);
      max-height: 700px;
      width: calc(var(--grid-column6) + (var(--grid-gap) / 2));
    }

    &__heading {
      top: 30px;
      left: 45px;
      right: 45px;
    }

    &__text-slider-item {
      justify-content: center;
      padding: 0 45px;
    }

    &__arrows {
      left: 45px;
      bottom: 45px;
    }

    &__images-slider {
      &.swiper {
        width: calc(var(--grid-column6) + (var(--grid-gap) / 2));
      }
    }
  }
}

@include respond-up('medium') {
  .company-advantages {
    &__text-slider,
    &__images-slider-wrap {
      flex: 0 0 50%;
    }

    &__heading {
      padding-bottom: 15px;
    }
  }
}

@include respond('medium') {
  .company-advantages {
    height: calc((421 / 768) * 100vw);

    &__image {
      height: calc((421 / 768) * 100vw);
      width: 50vw;
    }

    &__text-slider-item {
      padding: 105px var(--grid-spacer) 117px;
    }

    &__arrows {
      bottom: 30px;
    }

    &__images-slider {
      &.swiper {
        width: 50vw;
      }
    }
  }
}

@include respond-down('medium') {
  .company-advantages {
    margin: 0 calc(var(--grid-spacer) * -1);

    &__heading {
      top: 18px;
      left: var(--grid-spacer);
      right: var(--grid-spacer);
    }

    &__arrows {
      left: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .company-advantages {
    flex-direction: column-reverse;

    &__image {
      height: calc((328 / 375) * 100vw);
      width: 100vw;
    }

    &__text-slider-item {
      padding: 25px var(--grid-spacer) 65px;
    }

    &__heading {
      padding-bottom: 10px;
    }

    &__arrows {
      bottom: 20px;
    }

    &__text-slider {
      width: 100%;
    }

    &__images-slider-wrap {
      position: relative;
      height: calc((328 / 375) * 100vw);
    }

    &__images-slider {
      &.swiper {
        width: 100vw;
      }
    }
  }
}