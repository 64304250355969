button {
  text-align: center;
}

button, .button {
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  border-radius: 100px;
  width: fit-content;
  background: var(--primary-color);
  color: var(--white-color);
  border: 1px solid var(--primary-color);
  padding: 14px 23px 13px;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  svg {
    display: flex;
    height: 16px;
    width: auto;
    margin-right: 10px;
  }

  &_wide {
    width: 100%;
  }

  &_primary {
    letter-spacing: 1px;
    background: var(--primary-color);
    color: var(--white-color);
    border: 1px solid var(--primary-color);

    svg {
      path {
        stroke: var(--white-color);
      }
    }
  }

  &_primary-light-hover {
    @extend .button_primary;
  }

  &_secondary {
    background-color: var(--t);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    svg {
      path, circle, line {
        stroke: var(--primary-color);
      }
    }
  }

  &_secondary-white {
    background-color: var(--t);
    border: 1px solid var(--white-color);
    color: var(--white-color);
  }

  &_pagination {
    padding: 9px 23px 8px;
    background-color: var(--t);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    svg {
      margin-right: 0;
      margin-left: 15px;

      path, circle, line {
        stroke: var(--primary-color);
      }
    }
  }

  &_checkmark {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: var(--black-color);
    border: unset;
    border-radius: unset;
    background-color: var(--t);
    padding: 15px;
    margin: -15px;

    svg {
      width: 100%;
      height: 100%;
      margin-right: unset;

      path {
        fill: var(--black-color)
      }
    }

    .button__title {
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: uppercase;
      color: var(--black-color);
    }

    .button__icon {
      margin-left: 6px;
      padding-bottom: 2px;
    }
  }

  &_black {
    @extend .button_primary;
    background-color: var(--black-color);
    border-color: var(--black-color);
    color: #fff;
  }

  &_question {
    display: inline-flex;
    align-items: center;
    background-color: var(--t);
    border: none;
    text-transform: none;

    .button {
      &__icon {
        svg {
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }

      &__title {
        color: var(--grey-text-color);
        margin-left: 4px;
      }
    }
  }
}

@include respond-up('large') {
  button, .button {
    transition: background-color var(--default-transition-timing) var(--default-transition-function), border-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);

    svg {
      path {
        transition: stroke var(--default-transition-timing) var(--default-transition-function);
      }
    }

    &_primary {
      &:hover {
        background: var(--black-color);
        border-color: var(--black-color);
      }
    }

    &_primary-light-hover {
      &:hover {
        background: #fff;
        color: var(--black-color);
      }
    }

    &_secondary, &_pagination {
      &:hover {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: var(--white-color);

        svg {
          path {
            stroke: var(--white-color);
          }
        }
      }
    }

    &_secondary-white {
      &:hover {
        background-color: var(--white-color);
        color: var(--black-color);
      }
    }

    &_black {
      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: var(--black-color);
      }
    }

    &_question {
      .button__title {
        transition: color .4s;
      }

      &:hover {
        .button__title {
          color: var(--primary-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  button, .button {
    &_checkmark {
      .button__title {
        font-size: 14px;
      }
    }
  }
}

@include respond('medium') {
  button, .button {
    //фильтры
    &_filter {
      width: 100%;
    }
  }
}

@include respond-down('medium') {
  button, .button {

  }
}

@include respond-down('small') {
  button, .button {
    padding: 11px 16px 10px;

    &_checkmark {
      padding: 15px;
      margin: -15px;

      .button__title {
        font-size: 13px;
      }
    }
  }
}