.product-page-head-sticky {
  &__wrapper{
    border-bottom: 1px solid var(--grey-stroke-color);
    background: white;
    position: fixed;
    width: 100%;
    z-index: 90;
    top: -25px;
    transition: top 0.3s ease-in-out;
  }

  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 0;
  }

  &__content-text{
    width: var(--grid-column2);
  }

   &__cart-summary{
     display: flex;
     align-items: center;
   }

  &__cart-price{
    font-weight: 600 !important;
  }
}

@include respond-up('large') {
  .product-page-head-sticky {
    //&__wrapper{
    //  display: none;
    //}
    &__content-text{
      width: var(--grid-column4);
    }
  }
}

@include respond-up('medium') {
  .product-page-head-sticky {
    &__cart-summary{

      >:first-child{
        margin-right: 50px;
      }

      > :nth-child(2) {
        margin-right: 20px;
      }

    }

    &__cart-mobile{
      display: none!important;
    }
  }
}

@include respond('medium') {
  .product-page-head-sticky {

  }
}

@include respond-down('medium') {
  .product-page-head-sticky {
    &__wrapper{
      display: block;
    }
  }
}

@include respond-down('small') {
  .product-page-head-sticky {
    &__wrapper{
      top: -35px
    }

    &__cart-summary{
      gap: var(--grid-gap);
    }

    &__favorite{
      display: none!important;
    }

    &__cart-mobile{
      display: flex!important;
    }

    &__cart-desktop{
      display: none!important;
    }
  }
}