.car-selection-head{
  background: #FFFFFF;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-stroke-color);
  &__item{
    border-top: 3px solid transparent;
    position: relative;
    display: flex;
    align-items: center;
    &._active{
      border-top: 3px solid var(--primary-color);
      .car-selection-head{
        &__index, &__text{
          color: var(--primary-color);
        }
      }
    }

    &._available {
      .car-selection-head{
        &__index, &__text{
          color: var(--black-color);
        }
      }
    }

    &:before, &:after{
      top: -3px;
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  &__index{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-stroke-color);
    border-radius: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--grey-text-color);
    margin-right: 10px;
  }

  &__text{
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .car-selection-head {
    &__item{
      padding: 22px 35px 22px 45px;
    }
  }
}

@include respond-up('medium') {
  .car-selection-head {
    &__text{
      font-size: 16px;
    }

    &__item{
      &:before{
        right: -10px;
        border-width: 38.5px 0 38.5px 10px;
        border-color: transparent transparent transparent #ffffff;
        z-index: 2;
      }

      &:after{
        right: -11px;
        border-width: 38.5px 0 38.5px 10px;
        border-color: transparent transparent transparent var(--grey-stroke-color);
        z-index: 1;
      }
    }
  }
}

@include respond('medium') {
  .car-selection-head {
    &__item{
      padding: 22px 28px 22px 25px;
    }
  }
}

@include respond-down('medium') {
  .car-selection-head {

  }
}

@include respond-down('small') {
  .car-selection-head {
    overflow-x: scroll;
    flex-wrap: nowrap;
    &::-webkit-scrollbar{
      display: none;
    }

    &__item{
      padding: 7px 20px 7px 17px;
      flex-wrap: nowrap;
      &:before{
        right: -10px;
        border-width: 23.5px 0 23.5px 10px;
        border-color: transparent transparent transparent #ffffff;
        z-index: 2;
      }

      &:after{
        right: -11px;
        border-width: 23.5px 0 23.5px 10px;
        border-color: transparent transparent transparent var(--grey-stroke-color);
        z-index: 1;
      }
    }

    &__text{
      font-size: 15px;
      white-space: nowrap;
    }
  }
}