.contacts-bottom {
  &__requisites-list {
    display: grid;
  }

  &__requisites-item {
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 10px 0;
    display: flex;
  }

  &__requisites-item-title {
    color: var(--grey-text-color);
  }

  &__requisites-item-value {
    flex: 0 1 auto;
  }
}

@include respond-up('large') {
  .contacts-bottom {
    &__requisites-list {
      margin-top: 30px;
      grid-template-columns: repeat(2, var(--grid-column5));
      grid-column-gap: calc(var(--grid-column1) + (var(--grid-gap) * 2));
    }

    &__requisites-item-title {
      flex: 0 0 130px;
    }
  }
}

@include respond-up('medium') {
  .contacts-bottom {
    &__requisites-item {
      &:nth-child(-n+2) {
        border-top: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond('medium') {
  .contacts-bottom {
    &__requisites-list {
      grid-template-columns: repeat(2, var(--grid-column3));
      grid-column-gap: calc(var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .contacts-bottom {
    &__requisites-list {
      margin-top: 20px;
    }

    &__requisites-item-title {
      flex: 0 0 107px;
    }
  }
}

@include respond-down('small') {
  .contacts-bottom {
    &__requisites-list {
      grid-template-columns: 1fr;
    }

    &__requisites-item {
      &:nth-child(1) {
        border-top: 1px solid var(--grey-stroke-color);
      }
    }
  }
}