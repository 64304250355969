.side-menu {
  &__list {
  }

  &__item {
    background-color: white;
    position: relative;

    &._active {
      .side-menu {
        &__icon {
          svg {
            [fill] {
              fill: var(--primary-color);
            }
            [stroke] {
              stroke: var(--primary-color);
            }
          }
        }

        &__name {
          color: var(--primary-color)
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__name {
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }
}

@include respond-up('large') {
  .side-menu {
    &__list {
    }

    &__item {
      &._active {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 3px;
          background-color: var(--primary-color);
        }
      }

      &:nth-child(n + 2) {
        border-top: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond-up('medium') {
  .side-menu {
    &__link {
      padding: 17px 18px;
    }

    &__name {
      font-size: 16px;
    }

    &__icon {
      margin-right: 16px;
    }
  }
}

@include respond('medium') {
  .side-menu {

  }
}

@include respond-down('medium') {
  .side-menu {
    &__list {
      display: flex;
    }

    &__item {
      flex: 1 1 auto;
      &._active {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 3px;
          width: 100%;
          background-color: var(--primary-color);
        }
      }

      &:nth-child(n + 2) {
        border-left: 1px solid var(--grey-stroke-color);
      }
    }

    &__link {
      justify-content: center;
    }
  }
}

@include respond-down('small') {
  .side-menu {
    &__link {
      padding: 12px 5px;
    }

    &__name {
      font-size: 15px;
    }

    &__icon {
      margin-right: 10px;
    }
  }
}