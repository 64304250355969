.footer-info {
  &__call-phone{
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--white-color);
  }

  &__write{
    color: var(--white-color);
  }

  &__address{
    color: var(--white-color);
    max-width: 190px;
    &:before{
      background-color: var(--primary-color);
      content: "";
      height: 10px;
      position: static;
      width: 10px;
      left: 0;
      display: inline-block;
      border-radius: 100%;
      margin-right: 10px;
    }
  }
}

@include respond-up('large') {
  .footer-info {
    &__call{
      margin-bottom: 15px;
    }

    &__call-phone{
      font-size: 20px;
    }

    &__write{
      margin-bottom: 40px;
    }

    &__address{
      margin-bottom: 45px;
    }

    &__button{
      margin-bottom: 108px;
    }
  }
}

@include respond-up('medium') {
  .footer-info{

  }
}

@include respond('medium') {
  .footer-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    &__call{
      margin-bottom: 10px;
    }

    &__write{
      margin-bottom: 30px;
    }

    &__address{
      margin-bottom: 35px;
    }

    &__socials{
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  }
}

@include respond-down('medium') {
  .footer-info {
    &__call-phone{
      font-size: 18px;
    }
  }
}

@include respond-down('small') {
  .footer-info {
    &__socials{
      display: none;
    }

    &__call{
      margin-bottom: 10px;
    }

    &__write{
      margin-bottom: 25px;
    }

    &__address{
      margin-bottom: 30px;
    }

    &__button{
      margin-bottom: 40px;
    }
  }
}