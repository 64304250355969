.price-competitor {
  padding: 194px var(--grid-spacer) 50px;
  width: max-content;
  min-height: 100vh;
  background-color: var(--gray-background-color);

  &__main {
    margin-top: 50px;
  }

  &__total-title {
    margin-top: 50px;
  }

  &_main {
    .price-competitor-main {
      &__heading-row,
      &__item {
        height: 332px;
      }

      &__heading-column,
      &__item-column {
        width: 240px;
      }

      &__item-column {
        padding: 10px;
      }

      &__form {
        input {
          width: 100%;
        }
      }

      &__tyre-link {
        padding: 10px 0;
        color: var(--primary-color);
      }
    }
  }
}