.payment {
  grid-row-gap: 0;

  &__tabs {
    position: sticky;
    top: calc(var(--header-height) + 20px);
  }
}

@include respond-up('large') {
  .payment {
    --header-height: 87px;
    margin-top: 50px;
    padding-bottom: 120px;

    &__tabs-wrap {
      grid-column: 10/13;
      grid-row: 1;
    }

    &__blocks-wrap {
      grid-column: 1/10;
      grid-row: 1;
    }

    &__form-wrap {
      grid-column: 1/13;
      grid-row: 2;
      margin-top: 120px;
    }

    &__delivery-block,
    &__payment-block {
      margin-top: 27px;
    }
  }
}

@include respond-up('medium') {
  .payment {

  }
}

@include respond('medium') {
  .payment {
    margin-top: 30px;
    padding-bottom: 90px;
    --header-height: 74px;

    &__tabs-wrap {
      grid-column: 1/7;
      grid-row: 1;
    }

    &__blocks-wrap {
      grid-column: 1/7;
      grid-row: 2;
      margin: 30px calc(0px - var(--grid-spacer-and-indent)) 0;
    }

    &__form-wrap {
      grid-column: 1/7;
      grid-row: 3;
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .payment {
    &__delivery-block,
    &__payment-block {
      margin-top: 14px;
    }
  }
}

@include respond-down('small') {
  .payment {
    margin-top: 22px;
    padding-bottom: 50px;
    --header-height: 58px;

    &__tabs-wrap {
      grid-column: 1/5;
      grid-row: 1;
      overflow-x: scroll;
      margin: 0 calc(var(--grid-spacer) * -1);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__tabs {
      padding: 0 var(--grid-spacer);
    }

    &__blocks-wrap {
      grid-column: 1/5;
      grid-row: 2;
      margin: 22px calc(0px - var(--grid-spacer-and-indent)) 0;
    }

    &__form-wrap {
      grid-column: 1/5;
      grid-row: 3;
      margin-top: 40px;
    }
  }
}