.services-index {
  &__title{
    @extend h2
  }

  &__title-wrapper{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__list{
    display: grid;
  }
}

@include respond-up('large') {
  .services-index {
    &__title-wrapper{
      padding-bottom: 35px;
    }
    &__list{
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 28px;
    }
  }
}

@include respond-up('medium') {
  .services-index {

  }
}

@include respond('medium') {
  .services-index {
    &__title-wrapper{
      padding-bottom: 28px;
    }
    &__list{
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 14px;
    }
  }
}

@include respond-down('medium') {
  .services-index {
    &__item{
      &:last-child{
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .services-index {
    &__title-wrapper{
      padding-bottom: 20px;
    }
    &__list{
      grid-template-columns: 1fr;
      grid-gap: 8px;
    }
  }
}