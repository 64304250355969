.action-page {
  &__interested-title {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
  }

  &__interested-list {
    display: grid;
    grid-gap: var(--grid-gap);
  }
}

@include respond-up('large') {
  .action-page {
    &__interested-title {
      font-size: 28px;
    }

    &__action-products{
      margin-top: 103px;
    }

    &__interested-list {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 35px;
    }

    &__bottom {
      padding: 0 0 120px;
    }

    &__interested {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .action-page {

  }
}

@include respond('medium') {
  .action-page {
    &__interested-title {
      font-size: 22px;
    }

    &__interested-list {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 28px;
    }

    &__bottom {
      padding: 0 0 90px;
    }

    &__interested {
      margin-top: 90px;
    }

    &__action-products{
      margin-top: 80px;
    }
  }
}

@include respond-down('medium') {
  .action-page {
    &__interested-item {
      &:last-child {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .action-page {
    &__interested-title {
      font-size: 20px;
    }

    &__interested-list {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
      margin-top: 22px;
    }

    &__bottom {
      padding: 0 0 50px;
    }

    &__interested {
      margin-top: 50px;
    }

    &__action-products{
      margin-top: 40px;
    }
  }
}