.cart-order-delivery {
  &__conditions {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__condition {

  }

  &__condition-title {

  }

  &__text {
    &.text {
      color: var(--grey-text-color);
    }

    &_primary {
      color: var(--primary-color);
    }
  }
}

@include respond-up('large') {
  .cart-order-delivery {
    &__conditions {
      margin-top: 40px;
    }

    &__condition {
      &:nth-child(n + 2) {
        padding-left: 35px;
      }
    }

    &__text {
      padding-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .cart-order-delivery {
    &__conditions {
      display: flex;
    }

    &__condition {
      flex: 0 0 50%;
      padding-top: 20px;

      &:nth-child(n + 2) {
        border-left: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond('medium') {
  .cart-order-delivery {
    &__conditions {
      margin-top: 35px;
    }

    &__condition {
      &:nth-child(n + 2) {
        padding-left: 25px;
      }
    }
  }
}

@include respond-down('medium') {
  .cart-order-delivery {

  }
}

@include respond-down('small') {
  .cart-order-delivery {
    &__conditions {
      margin-top: 30px;
    }

    &__condition {
      &:nth-child(n + 2) {
        border-top: 1px solid var(--grey-stroke-color);
        margin-top: 22px;
      }
    }

    &__condition-title {
      padding: 15px 0;
    }
  }
}