.model-page-tabs-mobile {
  &__size-item {
    &:last-child {
      border-bottom: none;
    }
  }
}

@include respond-up('large') {
  .model-page-tabs-mobile {

  }
}

@include respond-up('medium') {
  .model-page-tabs-mobile {

  }
}

@include respond('medium') {
  .model-page-tabs-mobile {

  }
}

@include respond-down('medium') {
  .model-page-tabs-mobile {

  }
}

@include respond-down('small') {
  .model-page-tabs-mobile {

  }
}