.login-form {
  &__fields {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__field {
    position: relative;
  }

  &__bottom {
    display: flex;
  }

  //&__errors.text__small {
  //  color: var(--errors-color);
  //  margin-top: 8px;
  //}
}

@include respond-up('large') {
  .login-form {
    //&__input-wrap input{
    //  width: 311px;
    //}
  }
}

@include respond-up('medium') {
  .login-form {
    &__fields {
      padding-top: 35px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--grid-gap);
    }

    &__bottom {
      padding-top: 35px;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__politic {
      display: none;
    }
  }
}

@include respond('medium') {
  .login-form {
  }
}

@include respond-down('medium') {
  .login-form {

  }
}

@include respond-down('small') {
  .login-form {
    &__fields {
      padding-top: 20px;
    }

    &__bottom {
      padding-top: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__field {
      &:nth-child(n + 2) {
        padding-top: 20px;
      }
    }

    &__button.button {
      margin: 25px 0 12px;
      width: 100%;
    }
  }
}