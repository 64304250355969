.header-contact-menu {

}

@include respond-up('large') {
  .header-contact-menu {
    display: none;
  }
}

@include respond('medium') {
  .header-contact-menu {
    top: 74px;
  }
}

@include respond-down('medium') {
  .header-contact-menu {
    height: fit-content;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08);
    overflow-y: scroll;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      timing-function: var(--default-transition-function);
    }
    transform: translateY(-100%);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--white-color);
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    &._menu-opened {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }

    &__contact-item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid var(--grey-stroke-color);
      }
    }

    &__phone {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      letter-spacing: -0.32px;
      padding: 18px;
      margin: 0 -18px;
    }

    &__text {
      margin-left: 12px;
    }
  }
}

@include respond-down('small') {
  .header-contact-menu {
    top: 57px;
  }
}