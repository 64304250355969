.arrows {
  display: flex;
  align-items: center;

  &__left {
    transform: scale(-1, 1)
  }
}

@include respond-up('large') {
  .arrows {

  }
}

@include respond-up('medium') {
  .arrows {
    &__right {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .arrows {

  }
}

@include respond-down('medium') {
  .arrows {

  }
}

@include respond-down('small') {
  .arrows {
    &__right {
      margin-left: 15px;
    }
  }
}