.record-for-service-form {
  &__info {
    color: var(--primary-color);
  }

  &__fields {
    display: grid;
  }
}

@include respond-up('large') {
  .record-for-service-form {
    &__info {
      margin-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .record-for-service-form {
    &__fields {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 22px;
    }
  }
}

@include respond('medium') {
  .record-for-service-form {
    &__info {
      margin-top: 22px;
    }
  }
}

@include respond-down('medium') {
  .record-for-service-form {

  }
}

@include respond-down('small') {
  .record-for-service-form {
    &__info {
      margin-top: 20px;
    }

    &__fields {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
  }
}