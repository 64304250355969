.company-ceo {
  &__inner {
    position: relative;
    display: flex;
  }

  &__heading {
    position: absolute;
    z-index: 10;
    border-bottom: 1px solid var(--white-color-60);
    color: #fff;
  }

  &__text-wrap {
    position: relative;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title,
  &__subtitle,
  &__description {
    color: #fff;
  }

  &__title-wrap {
    margin-top: 25px;
  }

  &__description-wrap {
    display: flex;
  }

  &__description-icon {
    svg path {
      fill: #fff;
    }
  }

  &__about {
    svg path {
      fill: #fff;
      stroke: var(--t);
    }
  }

  &__about-text {
    color: #fff;
  }
}

@include respond-up('large') {
  .company-ceo {
    &__heading {
      top: 30px;
      left: 45px;
      right: 45px;
    }

    &__text-wrap {
      padding: 137px 45px 80px;
    }

    &__description {
      margin-left: 50px;
    }

    &__title-wrap {
      padding-left: 98px;
    }

    &__about {
      top: 30px;
      right: 45px;
    }
  }
}

@include respond-up('medium') {
  .company-ceo {
    &__heading {
      padding-bottom: 15px;
    }

    &__image-wrap,
    &__text-wrap {
      flex: 0 0 50%;
    }

    &__about {
      position: absolute;
      z-index: 15;
    }
  }
}

@include respond('medium') {
  .company-ceo {
    &__text-wrap {
      padding: 85px 32px 32px;
    }

    &__description {
      margin-left: 20px;
    }

    &__title-wrap {
      padding-left: 44px;
    }

    &__about {
      top: 22px;
      right: 34px;
    }
  }
}

@include respond-down('medium') {
  .company-ceo {
    &__inner {
      margin: 0 calc(var(--grid-spacer) * -1);
    }

    &__heading {
      top: 20px;
      left: var(--grid-spacer);
      right: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .company-ceo {
    &__inner {
      flex-direction: column;
    }

    &__heading {
      padding-bottom: 10px;
    }

    &__text-wrap {
      padding: 25px 18px 40px;
    }

    &__description {
      margin-left: 22px;
    }

    &__title-wrap {
      padding-left: 54px;
    }

    &__about {
      position: relative;
      margin: 35px 0 0;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        z-index: 10;
        top: 0;
        left: calc(var(--grid-spacer) * -1);
        width: 100vw;
        border-top: 1px solid #fff;
      }
    }
  }
}