.index-delivery-pay {
  display: grid;
  grid-gap: var(--grid-gap);

  &__link {
    display: flex;
    position: relative;
    grid-column: span 1;
    background-image: url('../images/bg/light-blue-bg.jpg');
    background-position: top left;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  &__picture-wrapper {
    display: flex;
    justify-content: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title,
  &__subtitle {
    color: var(--black-color);
  }

  &__subtitle {
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;

    svg {
      transform: rotate(-90deg);
      path {
        fill: var(--black-color);
      }
    }
  }
}

@include respond-up('large') {
  .index-delivery-pay {
    &__link {
      height: 250px;
    }

    &__text-wrapper {
      width: 50%;
      height: 100%;
      justify-content: space-between;
      padding: 30px 0 30px 30px;
    }

    &__picture-wrapper {
      width: 50%;
      height: 100%;
    }

    &__title {
      max-width: 243px;
    }
  }
}

@include respond-up('medium') {
  .index-delivery-pay {
    grid-template-columns: repeat(2, 1fr);

    &__subtitle {
      font-size: 14px;
      line-height: 17px;
      margin-top: 25px;
    }
  }
}

@include respond('medium') {
  .index-delivery-pay {
    &__link {
      height: 395px;
    }

    &__picture {
      width: 283px;
      height: 241px;
    }

    &__text-wrapper {
      padding: 25px 25px 0;
    }
  }
}

@include respond-down('medium') {
  .index-delivery-pay {
    &__link {
      flex-direction: column;
    }

    &__picture-wrapper {
      margin-top: auto;
    }
  }
}

@include respond-down('small') {
  .index-delivery-pay {
    grid-template-columns: 1fr;

    &__link {
      height: 342px;
    }

    &__picture {
      width: 260px;
      height: 220px;
    }

    &__text-wrapper {
      padding: 25px 20px 0;
    }

    &__subtitle {
      font-size: 13px;
      line-height: 16px;
      margin-top: 20px;
    }
  }
}