.dropdown-field {
  &__label {
    color: var(--grey-text-color);
  }

  select {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__preloader {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate3d(0, -50%, 0);
  }
}

@include respond-up('large') {
  .dropdown-field {

  }
}

@include respond-up('medium') {
  .dropdown-field {
    &__wrapper {
      margin-top: 8px;
    }
  }
}

@include respond('medium') {
  .dropdown-field {

  }
}

@include respond-down('medium') {
  .dropdown-field {

  }
}

@include respond-down('small') {
  .dropdown-field {
    &__wrapper {
      margin-top: 6px;
    }
  }
}