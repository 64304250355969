.politic-string {
  &_white-link {
    .politic-string__link {
      &.text.text__twelve {
        color: #fff;
      }
    }
  }

  &__text.text__twelve {
    color: var(--grey-text-color);
  }

  &__link {

  }
}

@include respond-up('large') {
  .politic-string {

  }
}

@include respond-up('medium') {
  .politic-string {

  }
}

@include respond('medium') {
  .politic-string {

  }
}

@include respond-down('medium') {
  .politic-string {

  }
}

@include respond-down('small') {
  .politic-string {

  }
}