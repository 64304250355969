.cart-positions {
  &__list {

  }

  &__item {
    background-color: white;
    display: grid;
  }

  &__image-wrap {
    grid-area: image;
  }

  &__name-wrap {
    grid-area: name;
  }

  &__panel-wrap {
    grid-area: panel;
    display: flex;
    align-items: flex-start;
  }

  &__info-wrap {
    grid-area: info;
  }

  &__price-wrap {
    grid-area: price;
    display: flex;
  }

  &__buttons-wrap {
    grid-area: buttons;
    display: flex;
    align-items: flex-start;
  }

  &__name {
  }

  &__item-price {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  &__price {
    font-weight: 600;
    line-height: 125%;
    color: var(--black-color);
  }

  &__measure.text {
    color: var(--grey-text-color);
    margin-left: 4px;
  }

  &__vendor-code.text {
    color: var(--grey-text-color);
  }

  &__delivery {
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__button-icon {
    display: flex;
    align-items: center;
  }

  &__panel-input {
    border: none;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: var(--black-color);
    flex: 0 0 28px;
    width: 28px;
    height: 38px;
    margin: 0 4px;
    pointer-events: none;
  }

  &__panel-button {
    background-color: unset;
    color: var(--black-color);
    border-radius: 100px;
    flex: 0 0 38px;
    width: 38px;
    height: 38px;
    border: 1px solid var(--grey-stroke-color);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
      height: 10px;
      margin: 0;
    }
  }

  &__panel {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  &__panel-message.text {
    z-index: 30;
    position: absolute;
    background-color: white;
    color: var(--grey-text-color);
    font-size: 12px;
    width: 174px;
    top: calc(100% + 5px);
    padding: 14px;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));

    &::before {
      content: '';
      position: absolute;
      border: 7px solid var(--t);
      border-bottom-color: white;
      top: -14px;
    }
  }
}

@include respond-up('large') {
  .cart-positions {
    &__item {
      padding: 20px;
      grid-template-columns: auto 1fr auto var(--grid-column2) auto;
    }

    &__image-wrap {
      padding-right: 30px;
      width: 121px;
      height: 111px;
    }

    &__name-wrap {
      padding-top: 10px;
      padding-right: 10px;
    }

    &__price-wrap {
      margin-top: 16px;
    }

    &__buttons-wrap {
      margin-top: 6px;
    }

    &__panel-wrap {
      margin-top: 10px;
    }

    &__button-icon {
      padding: 10px;
    }
  }
}

@include respond-up('medium') {
  .cart-positions {
    &__item {
      grid-template-areas:
              "image name panel price buttons"
              "image info info info info";

      &:nth-child(n + 2) {
        margin-top: 14px;
      }
    }

    &__price-wrap {
      justify-content: center;
    }

    &__info-wrap {
      display: flex;
    }

    &__delivery {
      margin-left: 40px;
    }

    &__price {
      &_item {
        font-size: 18px;
      }

      &_total {
        font-size: 20px;
      }
    }

    &__panel-message.text {
      left: calc(50% - 174px / 2);

      &::before {
        left: calc(50% - 7px);
      }
    }
  }
}

@include respond('medium') {
  .cart-positions {
    &__item {
      padding: 20px 14px 20px 20px;

      grid-template-columns: auto 1fr auto calc(var(--grid-column1) + var(--grid-gap) * 2) auto;
    }

    &__image-wrap {
      padding-right: 14px;
      width: 107px;
      height: 98px;
    }

    &__name-wrap {
      padding-right: var(--grid-gap);
    }

    &__price-wrap {
      margin-top: 6px;
      padding: 0 var(--grid-gap);
    }

    &__button-icon {
      padding: 6px;
    }
  }
}

@include respond-down('medium') {
  .cart-positions {

  }
}

@include respond-down('small') {
  .cart-positions {
    &__item {
      padding: 15px;
      grid-template-columns: auto 1fr 1fr auto;
      grid-template-areas:
              "image name name buttons"
              "image info info info"
              "panel panel price price";

      &:nth-child(n + 2) {
        margin-top: 8px;
      }
    }

    &__image-wrap {
      padding-right: 10px;
      width: 74px;
      height: 68px;
    }

    &__name-wrap {
      margin-top: 3px;
    }

    &__price-wrap {
      align-items: center;
    }

    &__button-icon {
      padding: 5px;
      margin: -5px -5px 0 5px;
    }

    &__info-wrap {
      padding: 10px 0 16px;
    }

    &__delivery {
      margin-top: 6px;
    }

    &__panel-message.text {
      left: 0;

      &::before {
        left: 50px;
      }
    }
  }
}