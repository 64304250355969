.product-base-warranty-tab {
  &__title {
    strong {
      color: var(--primary-color);
    }
  }

  &__description {
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .product-base-warranty-tab {
    &__description {
      margin-top: 25px;
    }
  }
}

@include respond('medium') {
  .product-base-warranty-tab {
    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .product-base-warranty-tab {
    &__description {
      margin-top: 12px;
    }
  }
}