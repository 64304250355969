.cart-result {
  z-index: 200;

  &__content {
    background-color: white;
  }

  &__info {
    padding: 15px 0 20px;
    margin: 15px 0 20px;
    border-top: 1px solid var(--grey-stroke-color);
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__info-name.text {
    color: var(--grey-text-color);
  }

  &__info-item {
    &:nth-child(n + 2) {
      margin-top: 20px;
    }
  }

  &__info-value {
    font-size: 16px;
    margin-top: 6px;
  }

  &__button.button {
    width: 100%;
  }

  &__help-header {
    display: flex;
    align-items: center;
  }

  &__help-title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__help-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #B6B7BA;
      }
    }
  }

  &__price {
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    margin-bottom: 20px;
  }

  &__promocode-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  &__promocode {
    display: flex;
    border: unset;
    background: var(--grey-light-color);
    width: 100%;
    height: 45px;
    padding: 13px 12px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    transition: color var(--default-transition-timing) var(--default-transition-function);

    &::placeholder {
      color: var(--black-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 135%;
      letter-spacing: -0.02em;
      transition: color var(--default-transition-timing) var(--default-transition-function);
    }

    &_error {
      color: #BA3636;

      &::placeholder {
        color: #BA3636;
      }
    }
  }

  &__promocode-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    transition: background var(--default-transition-timing) var(--default-transition-function);
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    width: 35px;
    height: 35px;
    z-index: 20;

    &._active {
      background: var(--black-color);

      .cart-result {
        &__promocode-button-icon {
          &_success {
            opacity: 1;
          }

          &_arrow {
            opacity: 0;
          }
        }
      }
    }
  }

  &__promocode-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity var(--default-transition-timing) var(--default-transition-function);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    &_arrow {
      width: 17px;
      height: 19px;
    }

    &_success {
      width: 21px;
      height: 21px;
      opacity: 0;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__promocode-error {
    margin-top: 6px;

    &.text {
      color: var(--grey-text-color);
    }
  }

  &__buttons-wrap {
    display: flex;
    flex-direction: column;
  }

  &__promocode-hint {
    display: flex;
    align-items: center;
    margin-top: 6px;
    width: 100%;

    &.text {
      color: var(--primary-color);
    }

    &._active {
      &.text {
        color: var(--black-color);
      }

      .cart-result {
        &__promocode-hint-text {
          &_success {
            display: block;
          }

          &_help {
            display: none;
          }
        }

        &__promocode-icon {
          display: none;
        }
      }
    }
  }

  &__promocode-hint-text {
    &_success {
      display: none;
    }
  }

  &__promocode-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    width: 8px;
    height: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('large') {
  .cart-result {
    position: sticky;
    top: 87px + 14px;

    &__button {
      &.button {
        margin-top: 18px;
      }
    }

    &__help-header {
      justify-content: center;
      margin-top: 12px;
    }
  }
}

@include respond-up('medium') {
  .cart-result {
    &__content {
      padding: 25px;
    }

    &__help-title {
      line-height: 135%;
    }
  }
}

@include respond('medium') {
  .cart-result {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__title {
      grid-column: 1/3;
      grid-row: 1;
    }

    &__info {
      grid-column: 1/2;
      grid-row: 2;
      padding-right: calc(var(--grid-gap)/2);
    }

    &__promo-wrap {
      grid-column: 2/3;
      grid-row: 2;

      padding: 15px 0 calc(var(--grid-gap)/2) 20px;
      margin: 15px 0 20px;
      border-top: 1px solid var(--grey-stroke-color);
      border-bottom: 1px solid var(--grey-stroke-color);
    }

    &__price {
      grid-column: 1/2;
      grid-row: 3;
    }

    &__buttons-wrap {
      grid-column: 2/3;
      grid-row: 3;
      justify-self: flex-end;
    }

    &__button {
      &.button {
        width: fit-content;
        padding: 14px 49px;
      }
    }

    &__help-header {
      margin-top: 15px;
    }
  }
}

@include respond-down('medium') {
  .cart-result {

  }
}

@include respond-down('small') {
  .cart-result {
    &__content {
      padding: 20px 18px;
    }

    &__button {
      &.button {
        margin-top: 18px;
      }
    }

    &__help-title {
      line-height: 120%;
    }

    &__help-header {
      justify-content: center;
      margin-top: 12px;
    }
  }
}