.recall-form-product-card {
  background: var(--black-color);
  overflow: hidden;

  &__title,
  &__description {
    color: #fff;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    &_top {
      top: 0;
      left: 0;
      bottom: 0;
    }

    &_bottom {
      bottom: 0;
      right: 0;
      top: 0;
    }

    &__small {
      left: 0;
      right: 0;
      bottom: 0;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__bottom {
    display: flex;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
  }
}

@include respond-up('large') {
  .recall-form-product-card {
    margin-top: 80px;
    padding: 30px calc(var(--grid-column) + var(--grid-gap));

    &__data {
      column-gap: calc(var(--grid-column) + var(--grid-gap) * 2);
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column4);
    }

    &__form-wrap {
      flex: 0 0 var(--grid-column5);
    }

    &__politic {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-up('medium') {
  .recall-form-product-card {
    &__data {
      display: flex;
      align-items: flex-start;
    }

    &__description {
      margin-top: 12px;
    }

    &__bottom {
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 18px;
    }

    &__icon {
      &_small {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .recall-form-product-card {
    margin-top: 60px;
    padding: 30px;

    &__data {
      column-gap: 48px;
    }

    &__text-wrap {
      flex: 0 0 calc(var(--grid-column2) - 30px);
    }

    &__form-wrap {
      flex: auto;
    }

    &__bottom {
      column-gap: 16px;
    }

    &__button-wrap {
      flex: 0 0 calc(var(--grid-column2) - 30px);
    }

    &__icon {
      top: unset;
    }
  }
}

@include respond-down('medium') {
  .recall-form-product-card {
    &__icon {
      &_top {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .recall-form-product-card {
    margin-top: 40px;
    padding: 30px 25px 34px;

    &__description {
      margin-top: 10px;
    }

    &__form-wrap {
      margin-top: 20px;
    }

    &__bottom {
      flex-direction: column;
      row-gap: 14px;
      margin-top: 14px;
    }

    &__button-wrap {
      display: flex;
      justify-content: flex-end;
    }

    &__icon {
      width: 338px;
      height: 173px;
      bottom: 0;
      right: 0;

      &_bottom {
        display: none;
      }
    }
  }
}