h1, .h1, .h1_article, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: var(--black-color);
}

@include respond-up("large") {
  h1, .h1 {
    --h-size: 42px;
  }

  .h1_article {
    --h-size: 35px;
  }

  h2, .h2 {
    --h-size: 27px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, .h5 {
    --h-size: 18px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: 35px;
  }

  .h1_article {
    --h-size: 28px;
  }

  h2, .h2 {
    --h-size: 22px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, .h5 {
    --h-size: 18px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 26px;
  }

  .h1_article {
    --h-size: 22px;
  }

  h2, .h2 {
    --h-size: 20px;
  }

  h3, .h3 {
    --h-size: 18px;
  }

  h4, .h4 {
    --h-size: 16px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}
