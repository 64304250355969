.messengers {
  display: flex;
  margin: -12px;

  &__title {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    padding-left: 13px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      border-radius: 100%;
      width: 8px;
      height: 8px;
    }
  }

  &__link {
    display: flex;
    padding: 12px;

    &_viber {
      .messengers__title::before {
        background-color: var(--purple-color);
      }
    }

    &_whatsapp {
      .messengers__title::before {
        background-color: var(--green-color);
      }
    }

    &_telegram {
      .messengers__title::before {
        background-color: var(--blue-color);
      }
    }
  }
}

@include respond-up('large') {
  .messengers {
    &__link {
      &:hover {
        .messengers__title {
          color: var(--grey-text-color);
        }
      }
    }

    &__title {
      transition: color var(--default-transition-timing);
    }
  }
}

@include respond-up('medium') {
  .messengers {
    &__title {
      &::before {
        top: calc(50% - 1px);
      }
    }
  }
}

@include respond('medium') {
  .messengers {

  }
}

@include respond-down('medium') {
  .messengers {

  }
}

@include respond-down('small') {
  .messengers {

  }
}