.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 16px;
    height: 16px;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/checkbox-checked.svg");
      }
    }
  }

  label {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--white-color-50);
    padding-left: 25px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url("../images/svg/checkbox-unchecked.svg");
      transition: background 0.2s;
    }
  }

  &__policy-link {
    color: white;
    width: max-content;
  }

  &__error {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: calc(100% + 12px);
    background-color: #fff;
    padding: 8px 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s var(--default-transition-function);

    &::before {
      content: '';
      position: absolute;
      left: 4px;
      top: -4px;
      width: 8px;
      height: 8px;
      background-color: #fff;
      transform: rotate(45deg);
    }

    &._visible {
      opacity: 1;
    }
  }

  &__error-title {
    margin-left: 8px;
    color: var(--primary-color);
  }

  &_dark {
    input {
      &:checked + label {
        &:before {
          background-image: url("../images/svg/checkbox-checked-dark.svg");
        }
      }
    }

    label {
      color: var(--grey-text-color);

      &:before {
        background-image: url("../images/svg/checkbox-unchecked-dark.svg");
      }
    }

    .policy-checkbox {
      &__policy-link {
        color: var(--black-color);
      }
    }
  }
}

@include respond-up('large') {
  .policy-checkbox {
    &__policy-link {
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color .3s;

      &:hover {
        border-bottom-color: #fff;
      }
    }

    &_dark {
      .policy-checkbox {
        &__policy-link {
          &:hover {
            border-bottom-color: var(--black-color);
          }
        }
      }
    }
  }
}
