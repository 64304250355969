.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--primary-color);

  svg {
    padding-left: 1px;
  }

  &_light {
    background-color: #fff;

    svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }

  &_without-back{
    background-color: transparent;

    svg {
      path {
        stroke: var(--white-color-60);
      }
    }
  }

  &_orange{
    background-color: transparent;
    border: 1px solid var(--primary-color-19);
    svg {
      path {
        stroke: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .arrow {
    width: 44px;
    height: 44px;
    transition: background-color .4s;

    svg {
      path {
        transition: stroke .4s;
      }
    }

    &:hover {
      background-color: #fff;

      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }

    &_light {
      &:hover {
        background-color: var(--black-color);

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    &_without-back{
      &:hover {
        background-color: transparent;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }

    &_orange{
      transition: border 0.4s;
      &:hover {
        border-color: var(--primary-color);
        background-color: transparent;
      }
    }
  }
}

@include respond-up('medium') {
  .arrow {

  }
}

@include respond('medium') {
  .arrow {
    width: 40px;
    height: 40px;
  }
}

@include respond-down('medium') {
  .arrow {

  }
}

@include respond-down('small') {
  .arrow {
    width: 34px;
    height: 34px;
  }
}