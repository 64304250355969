.cart-order-block {
  background-color: white;

  &:nth-child(n + 2) {
    margin-top: 14px;
  }

  &_contacts {
  }

  &_receiving {
  }

  &_pay {
  }

  &_comment {
  }


  &__head {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  &__number-wrap {
    background-color: var(--gray-background-color);
    border-radius: 0 100px 100px 0;
    margin-right: 25px;
  }

  &__number {
    height: 44px;
    width: 44px;
    flex: 0 0 44px;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    border-radius: 100px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__label.text {
    color: var(--grey-text-color);
  }

  &__title {
  }

  &__comment {
    resize: none;
    height: 96px;
  }

  &__radio-item {
    border-top: 1px solid var(--grey-stroke-color);

    &:last-child {
      border-bottom: 1px solid var(--grey-stroke-color);
    }

    &:has(input:disabled) {
      background: var(--grey-light-color);

      .cart-order-block {
        &__radio {
          input[type="radio"] {
            + label {
              cursor: default;
            }
          }
        }

        &__label-title {
          color: var(--grey-text-color);
        }
      }
    }
  }

  &__input-label {
  }

  &__label-title {
    &.text {
      display: block;
    }

    &:has(.cart-order-block__help-wrap) {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }

  &__label-description.text {
    color: var(--grey-text-color);
    display: block;
    margin-top: 8px;
  }

  &__fields {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__info.text {
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .cart-order-block {
    &__number-wrap {
      padding: 5px 5px 5px 38px;
    }

    &__content {
      padding: 0 38px 50px;
    }

    &__radio-item {
      margin: 0 -38px;
      padding: 18px 38px;
    }

    &__tabs.tabs {
      margin-bottom: 40px;
    }

    &__info {
      margin-top: 25px;
    }
  }
}

@include respond-up('medium') {
  .cart-order-block {
    &__label {
      margin-bottom: 8px;
    }

    &__fields {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include respond('medium') {
  .cart-order-block {
    &__number-wrap {
      padding: 5px 5px 5px 28px;
    }

    &__content {
      padding: 0 28px 35px;
    }

    &__radio-item {
      margin: 0 -28px;
      padding: 18px 28px;
    }

    &__tabs.tabs {
      margin-bottom: 35px;
    }
  }
}

@include respond-down('medium') {
  .cart-order-block {

  }
}

@include respond-down('small') {
  .cart-order-block {
    &__number-wrap {
      padding: 5px 5px 5px 18px;
    }

    &__content {
      padding: 0 18px 30px;
    }

    &__label {
      margin-bottom: 6px;
    }

    &__radio-item {
      margin: 0 -18px;
      padding: 15px 18px;
    }

    &__tabs.tabs {
      margin-bottom: 30px;
    }

    &__fields {
      grid-template-columns: 1fr;
    }
  }
}