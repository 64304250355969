.technology-modal {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .technology-modal {
    width: calc(var(--grid-column6) + (var(--grid-gap) * 2));

    &__picture {
      height: calc((var(--grid-column6) + (var(--grid-gap) * 2)) * (367 / 640));
    }

    &__description {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .technology-modal {
    margin-top: 35px;
  }
}

@include respond('medium') {
  .technology-modal {
    &__picture {
      height: calc((367 / 768) * 100vw);
    }

    &__description {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .technology-modal {

  }
}

@include respond-down('small') {
  .technology-modal {
    background-color: var(--gray-background-color);
    padding: 22px var(--grid-spacer) 30px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &__description {
      margin-top: 22px;
    }
  }
}