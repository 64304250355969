.top-fieldset {
  display: flex;

  &__fields {
    flex: 0 1 100%;
  }

  &__buttons-wrap {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    &_mobile {
      display: none;
    }
  }

  &__button {
    &:disabled {
      background-color: var(--grey-stroke-color);
      border-color: var(--grey-stroke-color);
      pointer-events: none;
    }
  }

  &__question-link {
    padding: 10px;
    margin: 19px -10px -10px;
  }
}

@include respond-up('large') {
  .top-fieldset {
    &__buttons-wrap {
      padding-top: 27px;
    }
  }
}

@include respond-up('medium') {
  .top-fieldset {

  }
}

@include respond('medium') {
  .top-fieldset {

  }
}

@include respond-down('medium') {
  .top-fieldset {

  }
}

@include respond-down('small') {
  .top-fieldset {

  }
}