.product-slider {
  overflow: hidden;

  &__body {
    position: relative;
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: block;
    @extend h2;
  }

  &__arrow-wrap {
    position: absolute;
    right: 0;
  }

  &__item {
    &.swiper-slide {
      height: auto;
    }
  }

  &__arrows {
    &._hidden {
      display: none;
    }
  }

  &__slider {
    &.swiper {
      overflow: unset;
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
  }

  &__show-all {
    &_mobile {
      display: none;
    }
  }
}

@include respond-up('large') {
  .product-slider {
    &__body {
      margin-top: 35px;
    }

    &__arrow-wrap {
      top: -68px;
    }

    &__bottom {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .product-slider {
    margin-right: calc(var(--grid-indent) + var(--grid-spacer));
    margin-left: calc(var(--grid-indent) + var(--grid-spacer));

    &__head {
      .container_spacer {
        padding: 0;
      }
    }
  }
}

@include respond('medium') {
  .product-slider {
    &__body {
      margin-top: 28px;
    }

    &__arrow-wrap {
      top: -60px;
    }

    &__bottom {
      margin-top: 25px;
    }
  }
}

@include respond-down('medium') {
  .product-slider {

  }
}

@include respond-down('small') {
  .product-slider {
    &__head {
      .container_spacer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__body {
      margin-top: 20px;
    }

    &__slider {
      &.swiper {
        width: auto;
      }
    }

    &__item {
      &:first-child {
        margin-left: calc(var(--grid-indent) + var(--grid-spacer));
      }
    }

    &__arrow-wrap {
      display: none;
    }

    &__bottom {
      display: none;
    }

    &__show-all {
      &_mobile {
        display: flex;
      }
    }
  }
}