.modal-form {
  &__fields {
    display: flex;
  }

  &__bottom {
    display: flex;
  }
}

@include respond-up('medium') {
  .modal-form {
    &__form-wrap {
      margin-top: 35px;
    }

    &__field {
      flex: 0 1 100%;

      &:nth-child(2) {
        margin-left: 22px;
      }
    }

    &__bottom {
      margin-top: 35px;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__policy {
      flex: 0 1 100%;
    }

    &__button-wrap {
      flex: 0 0 auto;
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .modal-form {
    &__form-wrap {
      margin-top: 20px;
    }

    &__fields {
      flex-direction: column;
    }

    &__field {
      &:nth-child(2) {
        margin-top: 20px;
      }
    }

    &__bottom {
      margin-top: 25px;
      flex-direction: column;
    }

    &__policy {
      order: 3;
      margin-top: 12px;
    }

    &__button-wrap {
      order: 2;

      .button {
        width: 100%;
      }
    }
  }
}