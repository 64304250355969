.to-cart {
  position: relative;
  display: flex;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-image: url('../images/svg/cart-plus.svg');
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image var(--default-transition-timing);
  }

  &_selected {
    border: 1px solid var(--grey-stroke-color);

    &::before {
      background-color: var(--t);
    }

    .to-cart__icon {
      background-image: url('../images/svg/cart-checkmark.svg');
    }
  }
}

@include respond-up('large') {
  .to-cart {
    &::before {
      transition: background-color var(--default-transition-timing);
    }

    &:hover {
      &::before {
        background-color: var(--black-color);
      }
    }

    &_selected {
      &:hover {
        &::before {
          background-color: var(--grey-stroke-color);
        }
      }
    }
  }
}