.catalog-switcher {
  display: flex;
  align-items: center;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    margin-left: 10px;
  }
}