.delivery-block {
  background: white;

  &__about-credit {
    margin-top: 20px;
  }

  &__credit-list {
    display: flex;
  }

  &__credit-item {
    background: var(--grey-light-color);
  }

  &__credit-number {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: var(--primary-color-15);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__credit-description {
    margin-top: 15px;
  }

  &__table {
    font-family: var(--font);
    letter-spacing: -0.02em;
    font-weight: 400;
    line-height: 135%;

    table {
      border: 1px solid var(--grey-stroke-color);
      width: 100%;
    }

    thead {
      background: var(--black-color);
      color: white;
      font-weight: 500;
    }

    tbody {
      tr {
        border-top: 1px solid var(--grey-stroke-color);

        td {
          &:first-child,
          &:nth-child(3) {
            color: var(--grey-text-color);
          }

          &:nth-child(2),
          &:nth-child(4) {
            font-weight: 500;
          }
        }
      }
    }

    th, td {
      &:not(:last-child) {
        border-right: 1px solid var(--grey-stroke-color);
      }
    }
  }

  &__map {
    position: relative;
  }

  &__map-list {
    background-color: #fff;
    overflow-y: scroll;
  }

  &__map-items {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  &__number {
    position: absolute;

    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--number-size);
    line-height: 125%;
    letter-spacing: -0.02em;

    background: linear-gradient(180deg, #EEEEEE 0%, rgba(238, 238, 238, 0) 88.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__text {
    color: var(--grey-text-color);
  }

  &__top {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: var(--grey-stroke-color);
      height: 1px;
      bottom: 0;
    }
  }

  &__point-wrap {
    display: flex;
    align-items: center;
  }

  &__about-delivery-wrap {
    display: flex;
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__about-delivery-point {
    &:not(:first-child) {
      border-left: 1px solid var(--grey-stroke-color);
    }
  }

  &__delivery-item {
    &:not(:first-child) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: var(--grey-stroke-color);
        height: 1px;
        top: 0;
      }
    }
  }

  &__picture {
    display: flex;
    width: 100px;
    height: 45px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__delivery-companies-list {
    display: flex;
  }

  &__payment {
    margin-top: 20px;
  }

  &__phone {
    white-space: nowrap;
  }

  &__offices-tab {
    display: none;
  }
}

@include respond-up('large') {
  .delivery-block {
    --number-size: 91.9545px;
    padding: 30px 38px 70px 38px;

    &__credit-item {
      padding: 25px 22px 20px 22px;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    &__credit-number {
      margin-top: 45px;
    }

    &__map-list {
      width: 380px;
      max-height: 416px;
    }

    &__map-items {
      max-height: 543px;
    }

    &__number {
      top: -53px;
      right: -38px;
    }

    &__point-wrap {
      transform: translateX(-38px );
    }

    &__top {
      padding-bottom: 30px;
      &:before {
        width: calc(100% + 76px);
        left: -38px;
      }
    }

    &__about-delivery-point {
      padding-top: 20px;

      &:not(:first-child) {
        margin-left: 35px;
        padding-left: 35px;
      }
    }

    &__delivery-item {
      &:not(:first-child) {
        padding-top: 20px;
        margin-top: 70px;
        position: relative;

        &:before {
          width: calc(100% + 76px);
          left: -38px;
        }
      }
    }

    &__delivery-title,
    &__table,
    &__about-delivery-wrap,
    &__delivery-list,
    &__map-wrap {
      margin-top: 20px;
    }

    &__delivery-companies-list,
    &__about-delivery-price {
      margin-top: 40px;
    }

    &__about-delivery,
    &__delivery-payment,
    &__table-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .delivery-block {
    &__credit-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__table {
      font-size: 15px;

      th,
      td {
        padding: 12px 20px;
      }
    }

    &__delivery-companies-list {
      justify-content: space-between;
    }

    &__map-element {
      height: 700px;
    }

    &__map-list {
      position: absolute;
      z-index: 50;
      top: 20px;
      left: 20px;
    }

    &__point-title {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .delivery-block {
    --number-size: 78px;
    padding: 25px 34px 35px 34px;

    &__credit-item {
      padding: 22px 18px 18px;

      &:not(:first-child) {
        margin-left: 14px;
      }
    }

    &__credit-number {
      margin-top: 45px;
    }

    &__map-wrap {
      margin-top: 20px;
    }

    &__number {
      top: -47px;
      right: -34px;
    }

    &__point-wrap {
      transform: translateX(-34px);
    }

    &__top {
      padding-bottom: 25px;
      &:before {
        width: calc(100% + 68px);
        left: -34px;
      }
    }

    &__about-delivery-point {
      padding-top: 20px;

      &:not(:first-child) {
        margin-left: 25px;
        padding-left: 25px;
      }
    }

    &__delivery-item {
      &:not(:first-child) {
        padding-top: 20px;
        margin-top: 35px;

        &:before {
          width: calc(100% + 68px);
          left: -34px;
        }
      }
    }

    &__delivery-title,
    &__table,
    &__about-delivery-wrap,
    &__delivery-list {
      margin-top: 20px;
    }

    &__delivery-companies-list,
    &__about-delivery-price {
      margin-top: 30px;
    }

    &__about-delivery,
    &__table-wrap {
      margin-top: 50px;
    }

    &__delivery-payment {
      margin-top: 40px;
    }

    &__map-list {
      width: 340px;
      max-height: 379px;
    }

    &__map-items {
      max-height: 504px;
    }
  }
}

@include respond-down('medium') {
  .delivery-block {

  }
}

@include respond-down('small') {
  .delivery-block {
    --number-size: 61px;
    padding: 20px 18px 30px 18px;

    &__map-list {
      width: 100%;
      max-height: 348px;
    }

    &__credit-item {
      padding: 20px 18px;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__credit-number {
      margin-top: 25px;
    }

    &__credit-list {
      flex-direction: column;
    }

    &__table {
      font-size: 14px;
      margin: 0 calc(0px - var(--grid-spacer));
      padding: 0 var(--grid-spacer) 1px;
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      table {
        width: auto;
        max-width: var(--grid-column10);

        tr {
          td {
            white-space: nowrap;
          }
        }
      }
      th,
      td {
        padding: 12px 15px;
        min-width: 130px;
        vertical-align: middle;
      }
    }

    &__number {
      top: -37px;
      right: -18px;
    }

    &__point-wrap {
      transform: translateX(-18px);
    }

    &__top {
      padding-bottom: 20px;
      &:before {
        width: calc(100% + 36px);
        left: -18px;
      }
    }

    &__about-delivery-point {
      padding-top: 20px;

      &:not(:first-child) {
        margin-left: 12px;
        padding-left: 12px;
      }
    }

    &__delivery-item {
      &:not(:first-child) {
        padding-top: 20px;
        margin-top: 30px;

        &:before {
          width: calc(100% + 36px);
          left: -18px;
        }
      }
    }

    &__delivery-title {
      margin-top: 15px;
    }


    &__table,
    &__about-delivery-wrap,
    &__delivery-list {
      margin-top: 20px;
    }

    &__delivery-companies-list {
      column-gap: 18px;
      row-gap: 14px;
      flex-wrap: wrap;
    }

    &__delivery-companies-list,
    &__about-delivery-price {
      margin-top: 30px;
    }



    &__about-delivery,
    &__table-wrap,
    &__delivery-payment {
      margin-top: 40px;
    }

    &__map-element {
      height: 420px;
    }
  }
}