.catalog-page-mobile-info {
  display: none;
  align-items: center;
  justify-content: space-between;

  &__question-link {
    padding: 10px;
    margin: -10px;
  }

  &__count {
    color: var(--black-color);
  }

  &__question-icon {
    width: 22px;
    height: 22px;
  }
}

@include respond-down('medium') {
  .catalog-page-mobile-info {
    display: flex;
  }
}