.generation-selection-card{
  display: grid;
  border: 1px solid var(--grey-stroke-color);

  &__info-head{
    border-bottom: 1px solid var(--grey-stroke-color);
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__years{
    display: flex;
    flex-wrap: wrap;
    gap: 6px
  }

  &__year{
    padding: 9px 16px;
    background: var(--grey-light-color);
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__head-icon{
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  &__image-head{
    display: flex;
  }

  &__image-head-title{
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    text-transform: uppercase;
  }

  &__image-head-years{
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
  }

  &__image-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('large') {
  .generation-selection-card{
    transition: box-shadow .3s;
    margin: 0 30px 20px 30px;
    &:hover{
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      .generation-selection-card{
        &__info-head{
          color: var(--primary-color);
        }
        &__head-icon{
          svg{
            path{
              fill: var(--primary-color);
            }
          }
        }
      }
    }
    &__info-head{
      padding: 30px 40px;
      font-size: 22px;
      transition: color .3s;
    }

    &__head-icon{
      svg{
        path{
          transition: fill .3s;
        }
      }
    }

    &__years{
      padding: 30px 40px;
    }

    &__year{
      font-size: 16px;
    }

    &__image-head{
      align-items: center;
      justify-content: space-between;
      padding: 34px 50px 6px 50px;
    }

    &__image-wrap{
      margin-bottom: 20px;
    }

    &__image{
      width: calc(360/1500 * 100vw);
      height: calc(211/1500 * 100vw);
      object-fit: contain;
    }
  }
}

@include respond-up('medium') {
  .generation-selection-card{
    grid-template-columns: 1fr 1fr;
    &__info-block{
      border-right: 1px solid var(--grey-stroke-color);
    }

    &__image-head-title{
      font-size: 16px;
    }

    &__image-head-years{
      font-size: 15px;
    }
  }
}

@include respond('medium') {
  .generation-selection-card{
    margin: 0 30px 16px 30px;
    &__info-head, &__image-head, &__info-bottom{
      padding: 20px;
    }

    &__image{
      width: calc(290/768 * 100vw);
      height: calc(171/768 * 100vw);
      object-fit: contain;
    }
  }
}

@include respond-down('medium') {
  .generation-selection-card{
    &__info-head{
      font-size: 18px;
    }

    &__image-head{
      flex-direction: column;
    }

    &__image-head-title{
      margin-bottom: 5px;
    }
  }
}

@include respond-down('small') {
  .generation-selection-card{
    margin: 0 20px 10px 20px;
    &__info-head, &__image-head, &__info-bottom{
      padding: 15px 20px;
    }
    &__year{
      font-size: 15px;
    }

    &__image-head-title{
      font-size: 15px;
    }

    &__image-head-years{
      font-size: 14px;
    }

    &__image{
      width: calc(268/376 * 100vw);
      height: calc(158/376 * 100vw);
      object-fit: contain;
    }
  }
}