.company-success {
  display: grid;
  grid-column-gap: var(--grid-gap);
  border-bottom: 1px solid var(--grey-stroke-color);

  &__title {
    grid-area: title;
  }

  &__description-left {
    grid-area: left;
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__description-wrap {
    grid-area: right;
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__quote {
    grid-area: quote;
    display: flex;
    align-items: flex-start;
  }

  &__quote-icon {
    display: flex;
    width: max-content;
    height: max-content;
  }

  &__image-wrap {
    position: relative;
    z-index: 25;
    display: flex;
  }

  &__images {
    grid-area: images;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 20;
      background-color: var(--primary-color);
      border-radius: 100%;
    }
  }

  &__description-left,
  &__description-right {
    color: var(--grey-text-color);
  }

  &__picture {
    display: flex;
    overflow: hidden;
    border-radius: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .company-success {
    padding-bottom: 120px;
    grid-template-columns: repeat(3, var(--grid-column4));
    grid-template-areas: 'title images quote'
                         'left images right';

    &__description-left,
    &__description-wrap {
      padding-top: 40px;
      margin-top: 40px;
    }

    &__description-bottom {
      margin-top: 40px;
    }

    &__quote-title {
      margin-left: 27px;
    }

    &__picture {
      width: calc((303 / 1500) * 100vw);
      max-width: 405px;
      height: calc((303 / 1500) * 100vw);
      max-height: 405px;
    }

    &__images {
      &::before {
        top: clamp(54px, calc((73 / 1500) * 100vw), 110px);
        left: clamp(27px, calc((37 / 1500) * 100vw), 55px);
        width: calc((303 / 1500) * 100vw);
        max-width: 405px;
        height: calc((303 / 1500) * 100vw);
        max-height: 405px;
      }
    }
  }
}

@include respond-up('medium') {
  .company-success {
  }
}

@include respond('medium') {
  .company-success {
    grid-template-columns: repeat(2, var(--grid-column3));
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'title images'
    'left images'
    'quote right';

    &__description-left {
      padding-top: 30px;
      margin-top: 30px;
    }

    &__description-wrap,
    &__quote {
      padding-top: 30px;
      margin-top: 40px;
    }

    &__description-bottom {
      margin-top: 30px;
    }

    &__picture {
      width: 303px;
      height: 303px;
    }

    &__image-wrap {
      justify-content: flex-end;
      padding-right: 34px;
    }

    &__images {
      padding-left: calc((6 / 768) * 100vw);
      height: 373px;

      &::before {
        top: 73px;
        right: 0;
        width: 303px;
        height: 303px;
      }
    }
  }
}

@include respond-down('medium') {
  .company-success {
    padding-bottom: 50px;

    &__quote-title {
      margin-left: 25px;
    }
  }
}

@include respond-down('small') {
  .company-success {
    grid-template-areas: 'title'
    'left'
    'quote'
    'images'
    'right';

    &__description-left {
      padding-top: 20px;
      margin-top: 20px;
    }

    &__quote,
    &__images {
      margin-top: 30px;
    }

    &__description-wrap {
      padding-top: 20px;
      margin-top: 30px;
    }

    &__description-bottom {
      margin-top: 20px;
    }

    &__picture {
      width: calc((225 / 375) * 100vw);
      height: calc((225 / 375) * 100vw);
    }

    &__images {
      height: calc((277 / 375) * 100vw);
      width: var(--grid-column3);

      &::before {
        bottom: 0;
        right: 0;
        width: calc((225 / 375) * 100vw);
        height: calc((225 / 375) * 100vw);
      }
    }
  }
}