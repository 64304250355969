.profile-form {
  background-color: white;

  &__header {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__h2 {

  }

  &__form {
    position: relative;
  }

  &__bottom {

  }

  &__button {

  }

  &__part {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: var(--grey-stroke-color);
    }
  }

  &__info {

  }

  &__info-title {
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__info-subtitle {
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
  }

  &__field {
    &_gender #profile_gender{
      display: flex;
      align-items: center;
      height: var(--default-input-height);
    }
  }
}

@include respond-up('large') {
  .profile-form {
    &__header {
      padding: 28px 38px;
    }

    &__info {
      padding: 0 var(--grid-gap) 0 38px;
    }

    &__part {
      grid-template-columns: repeat(9, 1fr);
      padding: 35px 0 60px;

      &::before {
        left: 38px;
        right: 38px;
      }
    }

    &__info {
      grid-column: 1/4;
    }

    &__fields {
      grid-column: 4/10;
      padding-right: 38px;
    }

    &__bottom {
      padding: 35px 38px;
    }

    &__field {
      &_gender #profile_gender label {
        margin-right: 29px;
      }

      &_address, &_password {
        grid-column: 1/3;
      }

      &_password {

      }

      &_address {
      }
    }
  }
}

@include respond-up('medium') {
  .profile-form {
    &__part {
      display: grid;
      grid-gap: var(--grid-gap);
    }

    &__info-title {
      font-size: 18px;
      margin-bottom: 14px;
    }

    &__info-subtitle {
      font-size: 15px;
    }

    &__fields, &__field_password {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 22px;
    }

    &__field {
      &_address, &_password {
        grid-column: 1/3;
      }
    }
  }
}

@include respond('medium') {
  .profile-form {
    &__header {
      padding: 23px 28px;
    }

    &__part {
      grid-template-columns: repeat(6, 1fr);
      padding: 35px 0;

      &::before {
        left: 0;
        right: 0;
      }
    }

    &__info {
      grid-column: 1/3;
      padding: 0 var(--grid-gap) 0 28px;
    }

    &__fields {
      grid-column: 3/7;
      padding-right: 28px;
    }

    &__bottom {
      padding: 35px 28px;
    }

    &__field {
      &_gender #profile_gender label {
        margin-right: 21px;
      }
    }
  }
}

@include respond-down('medium') {
  .profile-form {

  }
}

@include respond-down('small') {
  .profile-form {
    &__header {
      padding: 15px 18px;
    }

    &__info {
      padding: 25px 0;
    }

    &__info-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__info-subtitle {
      font-size: 14px;
    }

    &__part {
      padding: 0 18px 25px;

      &::before {
        left: 18px;
        right: 18px;
      }
    }

    &__bottom {
      padding: 25px 18px;
    }

    &__button {
      flex: 0 0 100%;
    }

    &__field {
      margin-bottom: 20px;

      &_gender #profile_gender label {
        margin-right: 33px;
      }

      &_password {
        display: grid;
        grid-gap: 20px;
      }
    }

    &__success.form-success {
      align-items: flex-end;
    }
  }
}