.profile-order {

  &__item {
    display: flex;

    &:not(:first-child) {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        background: var(--grey-stroke-color);
      }
    }
  }

  &__item-second-wrap {
    display: flex;
    flex-direction: row;
  }

  &__item-price {
    margin-top: 6px;
  }

  &__info-value {
    margin-left: var(--grid-gap);
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 1px;
      background: var(--grey-stroke-color);
    }
  }

  &__info-item {
    display: flex;
    border-top: 1px solid var(--grey-stroke-color);
    padding: 12px 0;

    &:last-child {
      border-bottom: 1px solid var(--grey-stroke-color);
    }
  }

  &__grey-text {
    color: var(--grey-text-color);
    &.text {
      color: var(--grey-text-color);
    }
  }

  &__header-top {
    display: flex;
  }

  &__table {
    background-color: white;
  }

  &__header {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      background: var(--grey-stroke-color);
    }
  }

  &__status {
    display: flex;
  }

  &__back-link {
    padding: 10px;
    margin: -10px;
  }

  &__picture {
    width: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .profile-order {
    &__picture {
      height: calc((var(--grid-column) + var(--grid-gap) * 1.5) * 111/121);
    }

    &__item {
      &:not(:first-child) {
        &:before {
          left: -38px;
          width: calc(100% + 76px);
        }
      }
    }

    &__total {
      &:before {
        left: -38px;
        width: calc(100% + 76px);
      }
    }

    &__item-second-wrap {
      flex: 0 0 calc(var(--grid-column3) - 38px);
    }

    &__item-info-wrap {
      margin-left: 30px;
    }

    &__info-name {
      flex: 0 0 var(--grid-column3);
    }

    &__info-list {
      margin-top: 50px;
    }

    &__table {
      padding: 28px 38px 35px 38px;
    }

    &__header {
      padding-bottom: 28px;
      &:before {
        left: -38px;
        width: calc(100% + 76px);
      }
    }
  }
}

@include respond-up('medium') {
  .profile-order {

    &__item-info-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__item {
      padding: 20px 0;
      align-items: center;
    }

    &__item-vendor-code {
      margin-top: 20px;
    }

    &__item-first-wrap {
      display: flex;
      flex-direction: column;
    }

    &__item-second-wrap {
      justify-content: space-between;
    }

    &__header-top {
      justify-content: space-between;
    }

    &__total {
      padding-top: 28px;
    }

    &__status {
      margin-top: 8px;
    }

    &__back-link {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      display: flex;
      align-items: center;
      svg {
        width: 7px;
        height: 11px;
        path {
          fill: var(--grey-stroke-color);
        }
      }
    }

    &__link-text {
      margin-left: 6px;
    }
  }
}

@include respond('medium') {
  .profile-order {

    &__picture {
      height: calc(var(--grid-column) * 98/107)
    }

    &__item {
      &:not(:first-child) {
        &:before {
          left: -28px;
          width: calc(100% + 56px);
        }
      }
    }

    &__total {
      &:before {
        left: -28px;
        width: calc(100% + 56px);
      }
    }

    &__item-second-wrap {
      flex: 0 0 calc(var(--grid-column2) - 28px);
    }

    &__item-info-wrap {
      margin-left: 14px;
    }

    &__info-name {
      flex: 0 0 var(--grid-column2);
    }

    &__info-list {
      margin-top: 44px;
    }

    &__table {
      padding: 23px 28px 35px 28px;
    }

    &__header {
      padding-bottom: 23px;
      &:before {
        left: -28px;
        width: calc(100% + 56px);
      }
    }
  }
}

@include respond-down('medium') {
  .profile-order {

  }
}

@include respond-down('small') {
  .profile-order {

    &__picture {
      height: calc(var(--grid-column) * 68/74)
    }

    &__total {
      &:before {
        left: -18px;
        width: calc(100% + 36px);
      }
    }

    &__item {
      padding: 15px 0;

      &:not(:first-child) {
        &:before {
          left: -18px;
          width: calc(100% + 36px);
        }
      }
    }

    &__item-vendor-code {
      margin-top: 10px;
    }

    &__item-second-wrap {
      align-items: center;
      margin-top: 16px;
    }

    &__item-total-price {
      margin-left: 46px;
    }

    &__item-info-wrap {
      margin-left: 10px;
    }

    &__header-top {
      align-items: flex-end;
    }

    &__order-number {
      order: 2;
      margin-left: 15px;
    }

    &__link-text {
      display: none;
    }

    &__total {
      padding-top: 25px;
    }

    &__status {
      margin-top: 12px;
    }

    &__info-name {
      flex: 0 0 calc(var(--grid-column2) - var(--grid-gap));
    }

    &__info-list {
      margin-top: 40px;
    }

    &__table {
      padding: 15px 18px 25px 18px;
    }

    &__header {
      padding-bottom: 15px;
      &:before {
        left: -18px;
        width: calc(100% + 36px);
      }
    }
  }
}