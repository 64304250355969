.product-base-sale{
  background: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &__offer{
    color: var(--black-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 20.25px */
    letter-spacing: -0.3px;
    display: flex;
    align-items: center;
    &:first-child{
      margin-right: 5px;
    }
    &_orange{
      color: var(--primary-color);
    }
  }

  &__icon{
    display: flex;
    position: relative;
    &:hover{
      .product-base-sale{
        &__rules{
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__rules{
    position: absolute;
    transition: opacity .3s;
    opacity: 0;
    pointer-events: none;
    color: var(--black-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.24px;
    background: var(--white-color);
    padding: 8px;
    z-index: 500;
    -webkit-box-shadow: 0px 0px 6px 2px rgba(34, 60, 80, 0.11);
    -moz-box-shadow: 0px 0px 6px 2px rgba(34, 60, 80, 0.11);
    box-shadow: 0px 0px 6px 2px rgba(34, 60, 80, 0.11);
  }
}

@include respond-up('large'){
  .product-base-sale{
    padding: 12px 40px;
  }
}

@include respond-up('medium'){
  .product-base-sale{
    margin-bottom: 10px;

    &__rules{
      width: 211px;
      right: 0;
      top: 20px;
    }
  }
}
@include respond('medium'){
  .product-base-sale{
    padding: 12px 30px;
  }
}

@include respond-down('small'){
  .product-base-sale{
    padding: 10px 20px;
    margin-bottom: 8px;

    &__rules{
      width: 163px;
      right: -30px;
      font-size: 11px;
      top: 20px;
    }
  }
}