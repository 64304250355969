.product-base-tabs-mobile {
  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-stroke-color);
    }

    &._opened {
      .product-base-tabs-mobile__link-icon {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__block {
    height: 0;
    transition: .4s;
    overflow: hidden;
  }

  &__content {
    padding: 15px 18px 25px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 18px;
  }

  &__link-icon {
    svg {
      transition: transform .4s;

      path {
        fill: var(--grey-text-color);
      }
    }
  }
}