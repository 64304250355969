.receiv-block {
  background: white;

  &__top {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: var(--grey-stroke-color);
      height: 1px;
      bottom: 0;
    }
  }

  &__number {
    position: absolute;

    font-family: var(--font);
    font-style: normal;
    font-weight: 700;
    font-size: var(--number-size);
    line-height: 125%;
    letter-spacing: -0.02em;

    background: linear-gradient(180deg, #EEEEEE 0%, rgba(238, 238, 238, 0) 88.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__item-description {
    color: var(--grey-text-color);
  }

  &__item-title-wrap {
    display: flex;
    align-items: center;
  }

  &__list {
    display: grid;
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    border-radius: 100%;
    width: 30px;
    height: 30px;
  }

  &__item-title {
    margin-left: 14px;
  }
}

@include respond-up('large') {
  .receiv-block {
    --number-size: 91.9545px;
    padding: 30px 38px 70px 38px;

    &__top {
      padding-bottom: 30px;
      &:before {
        width: calc(100% + 76px);
        left: -38px;
      }
    }

    &__number {
      top: -53px;
      right: -38px;
    }

    &__list {
      grid-row-gap: 59px;
    }

    &__item {
      &:nth-child(odd) {
        padding-right: 35px;
      }

      &:nth-child(even) {
        padding-left: 35px;
      }
    }
  }
}

@include respond-up('medium') {
  .receiv-block {
    &__list-wrap {
      padding-top: 20px;
    }

    &__item-description {
      margin-top: 37px;
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      border-top: 1px solid var(--grey-stroke-color);
      padding-top: 20px;

      &:nth-child(odd) {
        border-right: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond('medium') {
  .receiv-block {
    --number-size: 78px;
    padding: 25px 34px 35px 34px;

    &__top {
      padding-bottom: 25px;
      &:before {
        width: calc(100% + 68px);
        left: -34px;
      }
    }

    &__number {
      top: -47px;
      right: -34px;
    }

    &__list {
      grid-row-gap: 50px;
    }

    &__item {
      &:nth-child(odd) {
        padding-right: 25px;
      }

      &:nth-child(even) {
        padding-left: 25px;
      }
    }
  }
}

@include respond-down('medium') {
  .receiv-block {

  }
}

@include respond-down('small') {
  .receiv-block {
    --number-size: 61px;
    padding: 20px 18px 30px 18px;

    &__top {
      padding-bottom: 20px;
      &:before {
        width: calc(100% + 36px);
        left: -18px;
      }
    }

    &__number {
      top: -37px;
      right: -18px;
    }

    &__item-description {
      margin-top: 25px;
    }

    &__list {
      grid-row-gap: 25px;
    }

    &__item {
      padding-top: 25px;

      &:not(:first-child) {
        border-top: 1px solid var(--grey-stroke-color);
      }
    }
  }
}