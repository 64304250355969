.sidebar-fieldset {
  --sidebar-margin: min(60px, calc(var(--grid-column) - 11px));
  width: 214px;
  margin-left: var(--sidebar-margin);

  &__heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__main {
    margin-top: 20px;
  }

  &__submit {
    margin-top: 20px;
    width: 100%;
  }

  &__fields-wrap {
    position: relative;
    margin-top: 20px;
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__ninja-button {
    position: absolute;
    z-index: 950;
    top: 0;
    right: calc(100% + 20px);
    transform: translate3d(0, -50%, 0);

    border-radius: 0;
    border: none;
    padding: 7px 15px 10px;

    transition: opacity, background-color, top;
    transition-duration: var(--default-transition-timing);
    transition-timing-function: var(--default-transition-function);

    opacity: 0;
    pointer-events: none;

    &._show {
      opacity: 1;
      pointer-events: auto;
    }

    &:hover {
      background-color: var(--black-color);
      border-color: var(--black-color);

      &::before {
        border-color: var(--t) var(--black-color) var(--t) var(--t);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate3d(0, -50%, 0) rotate(180deg);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 4px 0;
      border-color: var(--t) var(--primary-color) var(--t) var(--t);
      transition: border-color var(--default-transition-timing) var(--default-transition-function);
    }
  }

  &__ninja-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    padding-bottom: 1px;
    border-bottom: 1px dashed #fff;
  }
}

@include respond-down('medium') {
  .sidebar-fieldset {
    display: none;

    &__ninja-button {
      display: none;
    }
  }
}