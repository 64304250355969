.record-service-point {
  &__main {
    display: flex;
    justify-content: space-between;
  }

  &__time-wrap {
    flex: 0 1 100%;
  }

  &__time-list {
    display: grid;
    grid-auto-flow: row;
    margin: -4px;
  }

  &__item {
    padding: 4px;

    &._disabled {
      .record-service-point__item-link {
        border-color: var(--button-border-color);
        color: var(--black-color);
        opacity: 0.4;
        pointer-events: none;
      }
    }

    &._active {
      .record-service-point__item-link {
        background-color: var(--primary-color);
        color: white;
      }
    }
  }

  &__item-link {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    margin-bottom: 0;
    padding: 7px 11px;
    border: 1px solid var(--grey-stroke-color);
    border-radius: 0;
    cursor: pointer;
    transition: var(--default-transition);
  }

  input {
    display: none;

    &:checked + .record-service-point__item-link {
      background-color: var(--primary-color);
      color: white;
    }
  }

  &__calendar {
    flex: 0 1 auto;
  }
}

@include respond-up('large') {
  .record-service-point {
    &__calendar {
      --calendar-max-width: max(calc((var(--grid-column4) + var(--grid-gap)) - 39px), 310px);
      flex: 0 0 var(--calendar-max-width);
    }

    &__time-wrap {
      margin-left: 79px;
    }

    &__time-list {
      grid-template-columns: repeat(auto-fit, minmax(67px, 1fr));
    }

    &__item:not(._disabled) {
      &:hover {
        input + .service-point__item-link {
          background-color: var(--primary-color);
          color: #fff;
        }
      }
    }

    &__item-link {
      transition: border-color .4s;

      &:hover {
        border-color: var(--primary-color);
      }
    }
  }
}

@include respond-up('medium') {
  .record-service-point {
    &__main {
      margin-top: 50px;
    }
  }
}

@include respond('medium') {
  .record-service-point {
    &__time-wrap {
      margin-left: 44px;
    }

    &__time-list {
      grid-template-columns: repeat(auto-fit, minmax(62px, 1fr));
    }
  }
}

@include respond-down('small') {
  .record-service-point {
    &__main {
      flex-direction: column;
    }

    &__time-wrap {
      margin-top: 25px;
    }

    &__main {
      margin-top: 30px;
    }

    &__item-link {
      padding: 7px 14px;
    }

    &__time-list {
      margin: -4.5px;
      grid-template-columns: repeat(auto-fit, minmax(68px, 1fr));
    }

    &__item {
      padding: 4.5px;
    }
  }
}