.contacts-page {
  background-color: #fff;
}

@include respond-up('large') {
  .contacts-page {
    padding-bottom: 100px;

    &__map {
      margin-top: 70px;
    }

    &__bottom {
      margin-top: 100px;
    }
  }
}

@include respond-up('medium') {
  .contacts-page {

  }
}

@include respond('medium') {
  .contacts-page {
    padding-bottom: 90px;

    &__map {
      margin-top: 50px;
    }

    &__bottom {
      margin-top: 70px;
    }
  }
}

@include respond-down('medium') {
  .contacts-page {

  }
}

@include respond-down('small') {
  .contacts-page {
    padding-bottom: 50px;

    &__map {
      margin-top: 40px;
    }

    &__bottom {
      margin-top: 40px;
    }
  }
}