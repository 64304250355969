article {
  :first-child {
    margin-top: 0;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
  }

  & {
    font-size: 14px;
  }

  p, li {
    font-family: var(--font);
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    font-weight: 400;
    line-height: 135%;
    font-size: var(--list-item-font-size);
    span {
      > * {
        font-family: var(--font);
        letter-spacing: -0.02em;
        color: var(--grey-text-color);
        font-weight: 400;
        line-height: 135%;
        font-size: var(--list-item-font-size);
      }
    }
  }

  ul li {
    position: relative;
    padding-left: 20px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: var(--primary-color);
      border-radius: 100%;
    }
  }

  ul {
    margin-top: 20px;
  }

  ol {
    counter-reset: heading;

    li {
      position: relative;
      padding-left: 27px;

      &::before {
        counter-increment: heading;
        content: "" counter(heading) ". ";
        color: var(--primary-color);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  ul,
  ol {
    li {
      //span {
      //  > * {
      //    font-family: var(--font);
      //    letter-spacing: -0.02em;
      //    color: var(--grey-text-color);
      //    font-weight: 400;
      //    line-height: 135%;
      //    font-size: var(--list-item-font-size);
      //  }
      //}
    }
  }

  strong {
    font-style: normal;
    font-weight: 500 !important;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color)!important;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    position: relative;
    padding-left: 28px;

    p {
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: var(--black-color);
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: var(--primary-color);
    }
  }

  table {
    width: 100%;

    thead {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #fff;

      tr {
        color: #fff;
        background-color: var(--black-color);
        border: 1px solid var(--black-color);
      }

      th {
        &:not(last-child) {
          border-right: 1px solid #fff;
        }
      }
    }

    tr {
      border: 1px solid var(--grey-stroke-color);
      &:nth-child(2n+3) {
        background-color: var(--grey-light-color);
      }
    }

    td {
      @extend .text__mini_medium;
      padding: 12px var(--grid-gap) 12px 0;

      &:first-child {
        @extend .text__mini;
      }

      &:not(last-child) {
        border-right: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond-up('large') {
  article {
    section {
      margin-top: 60px;

      &.table {
        margin-top: 30px;
      }
    }

    h2 {
      font-size: 27px;
      margin-top: 60px;
    }

    ol {
      margin-top: 24px;
    }
  }
}

@include respond-up('medium') {
  article {
    --list-item-font-size: 16px;
    strong {
      font-size: 18px!important;
    }

    p {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    blockquote {
      margin-top: 20px;
    }

    p, li {
      font-size: 16px;
    }

    ul li {
      &::before {
        top: 7px;
      }
    }

    blockquote {
      p {
        font-size: 16px;
      }
    }

    table {
      th,
      td {
        padding: 12px;
      }
    }
  }
}

@include respond('medium') {
  article {
    h2 {
      font-size: 22px;
    }

    ol {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  article {
    section {
      margin-top: 40px;

      &.table {
        margin-top: 20px;
      }
    }

    .table-wrap {
      overflow: scroll;
      padding: var(--grid-spacer);
      margin: calc(var(--grid-spacer) * -1);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    h2 {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  article {
    --list-item-font-size: 15px;
    strong {
      font-size: 17px!important;
    }

    h2 {
      font-size: 20px;
    }

    ol {
      margin-top: 18px;
    }

    p {
      &:not(:first-child) {
        margin-top: 18px;
      }
    }

    blockquote {
      margin-top: 18px;
    }

    p, li, blockquote p {
      font-size: 15px;
    }

    ul li {
      &::before {
        top: 6px;
      }
    }

    table {
      th,
      td {
        padding: 10px;
      }
    }
  }
}