.product-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  justify-content: space-between;

  &__action-name{
    display: flex;
    flex-direction: column;
  }

  &__action-name-wrap{
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 5px;
    &:last-child{
      border: none;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__action {
    display: flex;
    position: relative;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  &__features-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__favorite {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__image-link {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__picture {
   position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__top {
    display: flex;
    position: relative;
  }

  &__action-link {
    display: flex;
    position: relative;
  }

  &__action-icon {
    display: flex;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: var(--t) #fff var(--t) var(--t);
      transition: opacity var(--default-transition-timing);
      opacity: 0;
    }

    svg {
      width: 100%;
      height: 100%;

      circle,
      path {
        transition: fill var(--default-transition-timing) var(--default-transition-function);
      }
    }
  }

  &__feature-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__hidden-name {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate3d(8px, -50%, 0);
    margin-left: 0;
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    opacity:0;
    pointer-events: none;
    transition: opacity var(--default-transition-timing);
    z-index: 1000;

    &:before {
      content: '';
      position: absolute;
      right: calc(100% - 1px);
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: var(--t) #fff var(--t) var(--t);
    }

    &_actions{
      padding: 0;
      top: 0;
      left: 32px;
      transform: unset;
      &:before {
        content: unset;
      }
    }
  }

  &__hidden-target {
    &:hover {
      .product-card {
        &__hidden-name {
          opacity: 1;
        }

        &__action-icon {
          &:before {
            opacity: 1;
          }

          svg {
            circle {
              fill: var(--primary-color);
            }
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  &__feature {
    display: flex;
    position: relative;

    .product-card {
      &__hidden-name {
        transform: translate3d(11px, -50%, 0);
      }
    }
  }

  &__link {
    display: flex;
  }

  &__price {
    &_old {
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.02em;
      color: var(--grey-text-color);
      text-decoration: line-through;
    }
  }

  &__buy {
    margin-top: auto;
    display: grid;
  }

  &__kit-price {
    color: var(--grey-text-color);
  }

  &__buy-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__features {
    display: flex;
    column-gap: 6px;
  }

  &__prices {
    display: flex;
    grid-area: price;
  }

  &__to-cart {
    grid-area: buy;
    align-self: center;
    justify-self: flex-end;
  }

  &__bottom-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    grid-area: avail;
  }

  &__available {
    width: fit-content;
    align-self: unset;
  }

  &__delivery {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__delivery-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 11px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__delivery-title {
    color: var(--grey-text-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__installment{
    &.recommend-label{
      background: var(--blue-background-color);
      .recommend-label__title{
        color: black;
      }
    }
  }
}

@include respond-up('large') {
  .product-card {
    &__title {
      transition: color var(--default-transition-timing);
    }

    &__image-link{
      padding-bottom: 8vw;
    }

    &__link {
      &:hover {
        .product-card__title {
          color: var(--primary-color);
        }
      }
    }

    &__action-name-wrap{
      width: 165px;
    }
  }
}

@include respond-up('medium') {
  .product-card {
    padding: 18px;

    &__top {
      padding-top: 30px;
    }

    &__link {
      margin-top: 20px;
    }

    &__buy {
      padding-top: 10px;
      grid-row-gap: 9px;
      grid-template-areas:
              'price price'
              'avail buy';
    }

    &__price {
      padding: 2px 0 4px;

      &_old {
        padding: 0;
        font-size: 13px;
        line-height: 135%;
      }
    }

    &__kit-price {
      margin-top: 7px;
    }

    &__action-icon {
      width: 28px;
      height: 28px;

      &:before {
        left: calc(100% - 1px);
      }
    }

    &__prices {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      column-gap: 10px;
    }
  }
}

@include respond('medium') {
  .product-card {
    &__image-link{
      padding-bottom: 12vw;
    }
  }
}

@include respond-down('medium') {
  .product-card {
    &__action-name-wrap{
      width: 130px;
    }
  }
}

@include respond-down('small') {
  .product-card {
    padding: 10px;

    &__top {
      padding-top: 12px;
      min-height: 140px;
    }

    &__image-link{
      padding-bottom: 19vw;
    }

    &__link {
      margin-top: 15px;
    }

    &__buy {
      padding-top: 7px;
      grid-row-gap: 6px;
      grid-template-areas:
              'price buy'
              'avail avail';
    }

    &__price {
      padding: 5px 0;

      &_old {
        padding: 0;
        font-size: 12px;
        line-height: 120%;
      }
    }

    &__kit-price {
      margin-top: 6px;
    }

    &__action-icon {
      width: 24px;
      height: 24px;

      &:before {
        left: calc(100% + 2px);
      }
    }

    &__prices {
      flex-direction: column-reverse;
    }
  }
}