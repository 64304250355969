.contacts-top {
  &__item {
    grid-column: span 4;
  }

  &__item-title {
    color: var(--grey-text-color);
    margin-bottom: 12px;
  }

  &__link {
    display: flex;
    padding: 10px;
    margin: -10px;
    width: max-content;
  }

  &__opening-list {
    display: grid;
    grid-template-columns: max-content 1fr;
  }
}

@include respond-up('large') {
  .contacts-top {
    grid-row-gap: 36px;
    padding: 40px 0 70px;

    &__link {
      &:hover {
        .contacts-top__link-title {
          color: var(--grey-text-color);
        }
      }
    }

    &__link-title {
      transition: color var(--default-transition-timing);
    }
  }
}

@include respond-up('medium') {
  .contacts-top {
    &__opening-list {
      grid-column-gap: 20px;
    }
  }
}

@include respond('medium') {
  .contacts-top {
    grid-row-gap: 40px;
    padding: 30px 0 50px;

    &__item {
      grid-column: span 3;
    }
  }
}

@include respond-down('medium') {
  .contacts-top {
    &__item {
      order: 1;

      &_socials {
        order: 2;
      }

      &_recall {
        order: 3;
      }
    }
  }
}

@include respond-down('small') {
  .contacts-top {
    grid-row-gap: 28px;
    padding: 25px 0 40px;

    &__opening-list {
      grid-column-gap: 23px;
    }
  }
}