.filter-preloader {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  height: 100%;
  width: 40px;
  display: none;

  &._visible {
    display: block;
  }

  &__preloader {
    position: sticky !important;
    top: 50% !important;
  }
}