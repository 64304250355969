.catalog-base-selection {
  background-color: var(--black-color);

  &__inner {
    background-color: var(--white-color-10);
    display: grid;
  }

  &__list-wrap {
    grid-area: list;
    display: flex;
    align-items: flex-end;
  }

  &__list {
    display: flex;
    width: 100%;
  }

  &__image-wrap {
    display: flex;
    grid-area: image;
  }

  &__title {
    color: #fff;
    grid-area: title;
    display: flex;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .catalog-base-selection {
    padding: 0 0 80px;

    &__inner {
      padding: 0 var(--grid-column) 0 40px;
      grid-template-areas: 'title image'
                           'list image';
    }

    &__image-wrap {
      margin-bottom: 36px;
    }

    &__picture {
      height: calc((270 / 1500) * 100vw);
      width: calc((290 / 1500) * 100vw);
      max-height: 270px;
      max-width: 320px;
    }

    &__title {
      margin-top: 45px;
    }
  }
}

@include respond-up('medium') {
  .catalog-base-selection {
    &__item {
      &:last-child {
        margin-left: 30px;
      }
    }

    &__inner {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 36px;
        background-color: var(--black-color);
        bottom: 0;
        left: 0;
        grid-column: span 2;
      }
    }

    &__image-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .catalog-base-selection {
    padding: 0 0 50px;

    &__inner {
      padding: 0 55px 0 35px;
      grid-template-areas: 'title image'
      'list list';
    }

    &__picture {
      height: calc((216 / 768) * 100vw);
      width: calc((232 / 768) * 100vw);
    }

    &__title {
      margin-top: 33px;
    }
  }
}

@include respond-down('medium') {
  .catalog-base-selection {
    &__item {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .catalog-base-selection {
    padding: 0 0 40px;

    &__list {
      flex-wrap: wrap;
      margin-top: 22px;
    }

    &__item {
      &:last-child {
        margin-top: 10px;
      }
    }

    &__inner {
      padding: 30px 26px 0;
      grid-template-areas: 'title'
      'list'
      'image';
    }

    &__image-wrap {
      justify-content: center;
      margin-top: 29px;
    }

    &__picture {
      height: calc((216 / 375) * 100vw);
      width: calc((232 / 375) * 100vw);
    }
  }
}