.product-base-top-slider {
  position: relative;
  background-color: #fff;

  &__primary-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__primary {
    height: 100%;
    width: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__secondary-slide {
    border: 1px solid var(--grey-stroke-color);

    &._active {
      border-color: var(--grey-text-color);
    }
  }

  &__features {
    position: absolute;
    z-index: 25;
    display: flex;
    align-items: center;
    margin: -5px;
  }

  &__feature {
    margin: 5px;
  }
}

@include respond-up('large') {
  .product-base-top-slider {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;

    &__primary-wrap {
      width: calc(var(--grid-column6) - 135px);
      height: calc((var(--grid-column6) - 135px) * (430 / 449));
      max-height: 552px;
    }

    &__primary {
      &.swiper {
        margin-left: 0;
      }
    }

    &__secondary-wrap {
      margin: -20px 0;
      width: calc((58 / 1500) * 100vw);
      max-width: 84px;
      height: calc((470 / 1500) * 100vw);
      max-height: 593px;
    }

    &__secondary.swiper {
      padding: 20px 0;
      height: 100%;
    }

    &__secondary-slide {
      &.swiper-slide {
        width: calc((58 / 1500) * 100vw);
        height: calc((56 / 1500) * 100vw);
        max-width: 84px;
        max-height: 82px;
      }
    }

    &__features {
      right: 20px;
    }
  }
}

@include respond-up('medium') {
  .product-base-top-slider {
    &__pagination {
      display: none;
    }

    &__features {
      top: 20px;
    }
  }
}

@include respond('medium') {
  .product-base-top-slider {
    padding: 20px 10px 10px;

    &__primary {
      width: 299px;
      height: 287px;
    }

    &__secondary-wrap {
      margin: 10px -10px 0;
      width: var(--grid-column3);
      height: calc((56 / 768) * 100vw);
    }

    &__secondary.swiper {
      padding: 0 10px;
    }

    &__secondary-slide {
      &.swiper-slide {
        width: calc((58 / 768) * 100vw);
        height: calc((56 / 768) * 100vw);
      }
    }

    &__features {
      right: 22px;
    }
  }
}

@include respond-down('small') {
  .product-base-top-slider {
    padding-bottom: 14px;

    &__primary-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__primary.swiper {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * (323 / 338));
    }

    .swiper-pagination {
      position: static;
    }

    &__features {
      top: 12px;
      left: 18px;
    }

    &__secondary-wrap {
      display: none;
    }
  }
}