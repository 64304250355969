/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
        small: (
                from: 0,
                to: 767
        ),
        medium: (
                from: 768,
                to: 1199
        ),
        large: (
                from: 1200,
                to: 1599
        ),
        x-large: (
                from: 1600
        )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Inter", sans-serif;
  --h-font: "Inter", sans-serif;

  --t: transparent;

  --primary-color: #FF5924; /* Orange */
  --primary-color-80:  rgba(255, 89, 36, 0.8); /* Orange 80% */
  --primary-color-19:  rgba(255, 89, 36, 0.15); /* Orange 19% */
  --primary-color-15:  rgba(255, 89, 36, 0.15); /* Orange 15% */
  --black-color: #1D202C; /* Black */
  --grey-light-color: #F4F4F4; /* Gray light */
  --grey-text-color: #79797B; /* Gray text */
  --grey-stroke-color: #DADADF; /* Gray stroke */
  --white-color: white; /* White */
  --white-color-10: rgba(255, 255, 255, 0.1); /* White 10% */
  --white-color-20: rgba(255, 255, 255, 0.2); /* White 20% */
  --white-color-30: rgba(255, 255, 255, 0.3); /* White 30% */
  --white-color-40: rgba(255, 255, 255, 0.4); /* White 40% */
  --white-color-45: rgba(255, 255, 255, 0.45); /* White 45% */
  --white-color-50: rgba(255, 255, 255, 0.5); /* White 50% */
  --white-color-60: rgba(255, 255, 255, 0.6); /* White 60% */
  --green-color: #0FC46D; /* Green */
  --green-color-15: rgba(15, 195, 109, 0.15); /* Green 15% */
  --gray-background-color: #EEEEEE; /* Blue background */
  --grey-stroke-color-30: rgb(238, 238, 238, 0.3);
  --blue-background-color: #DEE5F0; /* Blue background */
  --dark-background-color-50: rgba(3, 0, 0, 0.53);
  --dark-blue-color: #3E4250; /* Dark blue */
  --light-blue-color: #C1CDDF; /* Light blue */
  --yellow-color: #E8C759; /* Yellow */
  --purple-color: #8E62AB; /* Purple color */
  --blue-color: #37AEE2; /* Blue color */


  /// schedule admin
  --gray: #E7E7E7;
  --black: #1F2129;
  --block-background: #FFF;
  --slot-bg: #F5F5F6;
  --white-opacity: rgba(255, 255, 255, 0.7);
  --border: rgba(255, 255, 255, 0.15);
  --green-border: rgba(87, 189, 61, 0.3);
  --green-text: #57BD3D;
  --red: #E12727;
  --red-bg: #FFEDED;
  //////////////

  --primary-color-opacity: rgba(255, 89, 36, 0.85);
  --primary-color-opacity-30: rgba(255, 89, 36, 0.3);
  --alert-color: #b50000;

  --default-bezier: cubic-bezier(.25, .1, .25, 1);
  --default-transition-function: ease-in-out;
  --default-transition-timing: .4s;
  --default-transition: all var(--default-transition-timing) var(--default-transition-function);
}
