.record-for-service-record-info {
  &__list {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__item {
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 12px 0;
    display: flex;
    align-items: flex-start;
  }

  &__item-name {
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .record-for-service-record-info {
    &__item-name {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond-up('medium') {
  .record-for-service-record-info {
    &__item-value {
      flex: 0 1 100%;
    }
  }
}

@include respond('medium') {
  .record-for-service-record-info {
    &__item-name {
      flex: 0 0 calc((212 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .record-for-service-record-info {

  }
}

@include respond-down('small') {
  .record-for-service-record-info {
    &__item-name,
    &__item-value {
      flex: 0 0 50%;
    }
  }
}