.error-page {

  &__wrap{
    flex-direction: column;
    align-items: center;
  }

  &__image{
    display: flex;
    justify-content: center;
  }

  &__description{
    color: var(--grey-text-color);
  }

  &__bottom{
    display: flex;
  }
}

@include respond-up('large') {
  .error-page {
    display: flex;
    justify-content: center;
    &__wrap{
      max-width: 843px;
    }

    &__image-wrap{
      padding-bottom: 35px;
    }

    &__bottom{
      padding-bottom: 143px;
    }

    &__description{
      max-width: 278px;
    }
  }
}

@include respond-up('medium') {
  .error-page {
    &__bottom{
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__image-wrap{
      padding-bottom: 40px;
    }

    &__image-wrap{
      padding-bottom: calc(333/768 * 100vw);
      margin-bottom: 22px;
    }

    &__bottom{
      padding-bottom: 50px;
    }

    &__description{
      max-width: 338px;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    &__image-wrap{
      position: relative;
    }
    &__picture{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &__image{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__wrap{
      padding-bottom: 50px;
    }

    &__image-wrap{
      padding-bottom: 67vw;
    }

    &__description{
      margin-bottom: 22px
    }

    &__bottom{
      flex-direction: column;
    }

    &__link{
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}