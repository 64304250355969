.recommend-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFE6DE;
  border-radius: 30px;
  width: fit-content;
  margin-bottom: 6px;

  &__title {
    color: var(--primary-color);
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 135%;
  }
}

@include respond-up('medium') {
  .recommend-label {
    padding: 2px 8px;

    &__title {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .recommend-label {
    padding: 2px 6px;

    &__title {
      font-size: 12px;
    }
  }
}