.catalog-base-info {
  &__description-wrap {
    overflow: hidden;
    transition: height var(--default-transition-timing) var(--default-transition-function);
  }

  &__description {
    color: var(--grey-text-color);
  }

  &__link {
    width: max-content;
  }

  &__link-icon {
    width: 10px;
    height: 13px;
    transition: transform .4s;

    svg {
      path {
        fill: var(--black-color)
      }
    }
  }

  &__inner {
    &._opened {
      .catalog-base-info {
        &__link-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@include respond-up('large') {
  .catalog-base-info {
    &__description-wrap {
      margin-top: 25px;
      height: 160px;
    }

    &__link-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .catalog-base-info {

  }
}

@include respond('medium') {
  .catalog-base-info {
    &__description-wrap {
      margin-top: 22px;
      height: 300px;
    }

    &__link-wrap {
      margin-top: 28px;
    }
  }
}

@include respond-down('medium') {
  .catalog-base-info {

  }
}

@include respond-down('small') {
  .catalog-base-info {
    &__description-wrap {
      margin-top: 18px;
      height: 532px;
    }

    &__link-wrap {
      margin-top: 22px;
    }
  }
}