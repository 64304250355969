.company-history {
  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-title {
    color: var(--primary-color);
  }

  &__text-description {
    color: var(--grey-text-color);
  }

  &__text-item {
    display: flex;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .company-history {
      &__progress {
        &::before {
          content: '';
          position: absolute;
          top: 61px;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          width: 3px;
        }

        &::before {
          background-color: var(--grey-light-color);
          z-index: 20;
          height: calc(100% - 61px);
        }
      }
    }

    &._active {
      .company-history {
        &__progress-title {
          color: #fff;
          background-color: var(--primary-color);
        }
      }
    }
  }

  &__progress {
    position: relative;
  }

  &__progress-line {
    background-color: var(--primary-color);
    z-index: 25;
    height: 0;
    position: absolute;
    top: 61px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 3px;
  }

  &__progress-title {
    color: var(--primary-color);
    background-color: var(--grey-light-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: color .4s, background-color .4s;
  }
}

@include respond-up('large') {
  .company-history {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);

    &__left {
      display: flex;
      grid-column: span 1;
      position: relative;
    }

    &__left-inner {
      width: 100%;
      height: calc((702 / 1500) * 100vw);
      max-height: 75vh;
      position: sticky;
      top: 140px;
    }

    &__right {
      grid-column: span 1;
    }

    &__text-image-wrap {
      display: none;
    }

    &__images-wrap {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__title {
      padding: 6px 12px;
      background-color: #fff;
      border-radius: 50px;
      position: absolute;
      top: 25px;
      left: 25px;
      z-index: 30;
    }

    &__images {
      position: relative;
      width: 100%;
      height: calc((702 / 1500) * 100vw);
      max-height: 75vh;
    }

    &__image-wrap {
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity var(--default-transition-function) var(--default-transition-timing);

      &._active {
        z-index: 25;
        opacity: 1;
      }

      &:first-child {
        z-index: 23;
        opacity: 1;
      }
    }

    &__text-subtitle {
      margin-top: 20px;
    }

    &__text-description {
      margin-top: 16px;
    }
  }
}

@include respond-up('medium') {
  .company-history {
    &__progress-title {
      width: 51px;
      height: 51px;
    }

    &__text-main {
      padding-bottom: 30px;
      margin-left: 28px;
    }
  }
}

@include respond('medium') {
  .company-history {
    &__right {
      width: var(--grid-column5);
    }

    &__text-image-wrap {
      height: calc((260 / 768) * 100vw);
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .company-history {
    &__left {
      display: none;
    }

    &__text-subtitle,
    &__text-description {
      margin-top: 14px;
    }

    &__text-image-wrap {
      width: 100%;
    }

    &__text-main {
      width: 100%;
    }
  }
}

@include respond-down('small') {
  .company-history {
    &__progress-title {
      width: 41px;
      height: 41px;
    }

    &__text-main {
      padding-bottom: 20px;
      margin-left: 21px;
    }

    &__text-image-wrap {
      height: calc((140 / 375) * 100vw);
      margin-top: 20px;
    }
  }
}