.company-about-card {
  background-color: #fff;

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-align: right;
    letter-spacing: -0.02em;
    color: var(--primary-color);
  }

  &__description {
    color: var(--grey-text-color);
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__title-wrap {
    display: flex;
  }

  &__subtitle-wrap {
    display: flex;
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .company-about-card {
    padding: 25px 30px;

    &__subtitle {
      font-size: 55px;
    }

    &__description {
      padding-top: 20px;
      margin-top: 12px;
    }

    &__subtitle-wrap {
      padding-top: 55px;
    }
  }
}

@include respond-up('medium') {
  .company-about-card {
    &__top {
      position: relative;
    }

    &__title-wrap {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include respond('medium') {
  .company-about-card {
    padding: 22px;

    &__subtitle-wrap {
      padding-top: 65px;
    }

    &__description {
      margin-top: 18px;
    }
  }
}

@include respond-down('medium') {
  .company-about-card {
    &__subtitle {
      font-size: 45px;
    }

    &__description {
      padding-top: 16px;
    }
  }
}

@include respond-down('small') {
  .company-about-card {
    padding: 21px;

    &__subtitle-wrap {
      margin-top: 25px;
    }

    &__description {
      margin-top: 16px;
    }
  }
}