.service-booking {
  position: relative;
  background-color: var(--primary-color);

  &__inner {
    position: relative;
    z-index: 10;
  }

  &__icon {
    display: flex;
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;

    svg path {
      fill: #fff;
    }
  }

  &__title {
    color: #fff;
  }

  &__description {
    color: #fff;
  }

  &__phone{
    @extend h3;
    display: block;
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .service-booking {
    &__inner {
      padding: 25px 25px 110px;
    }

    &__description {
      margin-top: 15px;
    }

    &__button {
      margin-top: 25px;
    }

    &__phone{
      margin-top: 8px;
    }
  }
}

@include respond-up('medium') {
  .service-booking {

  }
}

@include respond('medium') {
  .service-booking {
    &__inner {
      padding: 25px 28px 66px;
    }
  }
}

@include respond-down('medium') {
  .service-booking {
    &__description {
      margin-top: 12px;
    }

    &__button {
      margin-top: 22px;
    }
  }
}

@include respond-down('small') {
  .service-booking {
    &__inner {
      padding: 25px 25px 116px;
    }
  }
}