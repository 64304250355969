.record-details{
  &__top-fields{
    display: grid;
  }

  &__field{
    label{
      @extend .text__mini;
      color: var(--grey-text-color);
    }
  }

  &__radio-item{
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__input-label{
    margin-bottom: unset !important;
  }

  &__in-storage{
    display: grid;
  }
}

@include respond-up('large') {
  .record-details{
    &__top-fields, &__in-storage{
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 22px;
    }

    &__bottom-title{
      padding: 40px 0 20px 0;
    }

    &__field{
      &_order-number{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
      &_save-order-number{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
      &_save-order-name{
        grid-column: 3 / 4;
        grid-row: 1 / 2;
      }
    }
  }
}

@include respond-up('medium') {
  .record-details{
    &__top-fields, &__in-storage{
      grid-column-gap: 22px;
    }

    &__field{
      select{
        margin-top: 8px;
      }
    }

    &__radio-item{
      padding: 18px 0;
    }
  }
}

@include respond('medium') {
  .record-details{
    &__top-fields, &__in-storage{
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
    }
    &__bottom-title{
      padding: 35px 0 20px 0;
    }

    &__field{
      &_order-number{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
      &_save-order-number{
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }
      &_save-order-name{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
    }
  }
}

@include respond-down('small') {
  .record-details{
    &__top-fields{
      grid-row-gap: 20px;
    }

    &__field{
      select{
        margin-top: 6px;
      }
    }

    &__in-storage{
      grid-row-gap: 20px;
    }

    &__bottom-title{
      padding: 25px 0 18px 0;
    }

    &__radio-item{
      padding: 15px 0;
    }
  }
}