.company-about-cards {
  display: grid;
  grid-gap: var(--grid-gap);
}

@include respond-up('large') {
  .company-about-cards {

  }
}

@include respond-up('medium') {
  .company-about-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include respond('medium') {
  .company-about-cards {

  }
}

@include respond-down('medium') {
  .company-about-cards {

  }
}

@include respond-down('small') {
  .company-about-cards {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
}