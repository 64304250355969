.phone-form {
  &__fields {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__field {
    position: relative;
  }

  &__bottom {
    display: flex;
  }
}

@include respond-up('large') {
  .phone-form {

  }
}

@include respond-up('medium') {
  .phone-form {
    &__fields {
      padding-top: 35px;
    }

    &__bottom {
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 35px;
    }

    &__politic {
      margin-right: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .phone-form {

  }
}

@include respond-down('medium') {
  .phone-form {

  }
}

@include respond-down('small') {
  .phone-form {
    &__fields {
      padding-top: 20px;
    }

    &__bottom {
      padding-top: 25px;
      flex-direction: column-reverse;
    }

    &__button.button {
      width: 100%;
      margin-bottom: 12px;
    }
  }
}