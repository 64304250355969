.modal-success {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    white-space: nowrap;
    text-align: center;
    margin-top: 30px;
  }

  &__description {
    text-align: center;
    color: var(--grey-text-color);
    max-width: 252px;
  }

  &__icon {
    svg g path {
      &:first-child {
        fill: var(--primary-color);
      }

      &:last-child {
        fill: #fff;
      }
    }
  }
}

@include respond-up('large') {
  .modal-success {
    padding: 121px 125px 180px;

    &__description {
      margin-top: 18px;
    }
  }
}

@include respond('medium') {
  .modal-success {
    padding: 75px 163px 180px;
  }
}

@include respond-down('medium') {
  .modal-success {
    &__description {
      margin-top: 16px;
    }
  }
}