.hero-slider {
  position: relative;

  &__swiper {
    width: 100%;
  }

  &__item-inner {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__picture {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, var(--t)70%);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__title,
  &__description {
    color: white;
  }

  &__arrows {
    position: absolute;
    z-index: 10;
    bottom: 25px;

    .arrow {
      background-color: var(--t);
      border: 1px solid var(--white-color-20);
      transition: border-color .4s;
    }
  }

  &__pagination {
    position: absolute;
    z-index: 10;

    &.swiper-pagination-bullets.swiper-pagination-horizontal {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: max-content;
    }

    .swiper-pagination-bullet {
      --swiper-pagination-bullet-horizontal-gap: 3px;
      --swiper-pagination-color: white;
      --swiper-pagination-bullet-inactive-color: var(--white-color-45);
      --swiper-pagination-bullet-height: 3px;
      --swiper-pagination-bullet-opacity: 1;
      --swiper-pagination-bullet-inactive-opacity: 1;
      border-radius: 0;
    }
  }
}

@include respond-up('large') {
  .hero-slider {
    &__swiper {
      height: calc(var(--grid-column12) * (400 / 1196));
    }

    &__text-wrapper {
      width: calc(var(--grid-column4) - 50px);
    }

    &__content {
      padding: 60px 50px;
    }

    &__description {
      margin-top: 20px;
    }

    &__button {
      margin-top: 30px;
    }

    &__arrows {
      right: 52px;

      .arrow {
        &:hover {
          border-color: white;

          svg path {
            stroke: white;
          }
        }
      }
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px;
      }

      .swiper-pagination-bullet {
        --swiper-pagination-bullet-width: 42px;
      }
    }
  }
}

@include respond('medium') {
  .hero-slider {
    &__swiper {
      height: calc(var(--grid-column6) * (320 / 700));
    }

    &__text-wrapper {
      width: calc((351 / 768) * 100vw);
    }

    &__content {
      padding: 35px;
    }

    &__arrows {
      right: 25px;
    }
  }
}

@include respond-down('medium') {
  .hero-slider {
    &__description {
      margin-top: 15px;
    }

    &__button {
      margin-top: 20px;
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 25px;
      }

      .swiper-pagination-bullet {
        --swiper-pagination-bullet-width: 36px;
      }
    }
  }
}

@include respond-down('small') {
  .hero-slider {
    &__swiper {
      height: calc(var(--grid-column4) * (400 / 338));
    }

    &__content {
      padding: 35px 20px;
    }

    &__arrows {
      display: none;
    }

    &__picture {
      &::before {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
}