.form-success {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0;
  pointer-events: none;
  transition: {
    property: opacity;
    duration: var(--default-transition-timing);
  };
  padding: var(--grid-spacer); // temporary

  &.success {
    opacity: 1;
    pointer-events: all;
  }

  &_inline {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &_modal {

  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {

  }

  &__title {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__subtitle {
    font-weight: 400;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .form-success {
    &__title {
      font-size: 27px;
      margin: 29px 0 18px;
    }
  }
}

@include respond-up('medium') {
  .form-success {

    &__subtitle {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .form-success {
    &__title {
      font-size: 22px;
    }
  }
}

@include respond-down('medium') {
  .form-success {
    &__title {
      margin: 29px 0 16px;
    }
  }
}

@include respond-down('small') {
  .form-success {
    &__title {
      font-size: 20px;
    }

    &__subtitle {
      font-size: 15px;
    }
  }
}