.product-base-technologies {
  &__link {
    display: flex;

    &:not(last-child) {
      border-bottom: 1px solid var(--grey-stroke-color);
    }
  }

  &__picture-wrap {
    flex: 0 0 auto;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
  }

  &__link-description {
    display: flex;
    color: var(--grey-text-color);
  }

  &__link-icon {
    display: flex;
  }
}

@include respond-up('large') {
  .product-base-technologies {
    &__list {
      margin-top: 35px;
    }

    &__link {
      padding: 30px 50px;
    }

    &__picture-wrap {
      flex: 0 0 calc(var(--grid-column4) - 15px);
      width: calc(var(--grid-column4) - 15px);
      height: calc((var(--grid-column4) - 15px) * (206 / 365));
    }

    &__link-description {
      margin-top: clamp(15px, calc((15 / 1500) * 100vw), 35px);
    }

    &__text-wrap {
      margin-left: 42px;
    }
  }
}

@include respond-up('medium') {
  .product-base-technologies {
    &__list {
      background-color: #fff;
    }

    &__link-icon {
      margin-top: auto;
    }
  }
}

@include respond('medium') {
  .product-base-technologies {
    &__list {
      margin-top: 28px;
    }

    &__link {
      padding: 25px 28px;
    }

    &__text-wrap {
      margin-left: 20px;
    }

    &__picture-wrap {
      flex: 0 0 calc(var(--grid-column3) - 39px);
      width: calc(var(--grid-column3) - 39px);
      height: calc((var(--grid-column3) - 39px) * (172 / 304));
    }

    &__link-description {
      margin-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .product-base-technologies {
    &__link-description {
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .product-base-technologies {
    background-color: #fff;

    &__title {
      padding: 20px 18px 0;
    }

    &__link {
      flex-direction: column;
      padding: 20px 18px;
    }

    &__link-icon {
      margin-top: 22px;
    }

    &__text-wrap {
      margin-top: 15px;
    }

    &__picture-wrap {
      width: var(--grid-column2);
      height: calc(var(--grid-column2) * (105 / 162));
    }
  }
}