.model-page-top-price {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &__top {

  }

  &__prices {
    display: flex;
    align-items: flex-end;
  }

  &__price {
    display: flex;
    align-items: flex-end;
  }

  &__price-pre {
    &.text {
      color: var(--grey-text-color);
    }
  }

  &__button {
    &.button {
      margin-top: 20px;
      width: 100%;
    }

    &_mobile.button {
      display: none;
    }
  }

  &__question-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid var(--grey-stroke-color);
    padding: 13px 23px;

    &_empty{
      flex-direction: column;
    }
  }

  &__question-icon {
    display: flex;
  }

  &__question-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    margin-left: 6px;
  }

  &__empty-text{
    font-size: 12px;
    color: var(--grey-text-color);
    text-align: center;
    margin-bottom: 15px;
  }

  &__empty-help-wrap{
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .model-page-top-price {
    &__top {
      padding: 20px 30px 25px;
    }

    &__price {
      margin-right: 35px;
    }

    &__question-link {
      padding: 17px 23px 18px;
    }
  }
}

@include respond('medium') {
  .model-page-top-price {
    &__top {
      padding: 20px 19px;
    }

    &__prices {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@include respond-down('medium') {
  .model-page-top-price {
    &__price {
      margin-right: 18px;
    }
  }
}

@include respond-down('small') {
  .model-page-top-price {
    &__top {
      padding: 20px 18px;
    }

    &__button {
      &_desktop.button {
        display: none;
      }

      &_mobile.button {
        display: block;
      }
    }
  }
}