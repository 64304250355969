.favorites-page {
  &__grid{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(205px,1fr));
    grid-gap: 14px;
  }

  &__empty{
    background-color: #fff;
    padding: 50px;
    position: relative;
  }

  &__empty-title{
    @extend h3;
    margin-bottom: 15px;
  }

  &__empty-subtitle{
    margin-bottom: 15px;
    color: var(--grey-text-color);
  }

  &__track{
    position: absolute;
    right: 0;
    bottom: -5px;
    transform: scale(-1, 1);
    svg {
      path{
        fill: #F4F4F4;
      }
    }
  }
}

@include respond-up('large') {
  .favorites-page {
    padding-bottom: 120px;
    &__grid{
      padding-bottom: 120px;
    }

    &__track{
      svg {
        width: calc(368/ 1500 * 100vw);
        height: calc(168/ 1500 * 100vw);
      }
    }
  }
}

@include respond-up('medium') {
  .favorites-page {

  }
}

@include respond('medium') {
  .favorites-page {
    padding-bottom: 90px;
    &__grid{
      padding-bottom: 90px;
    }

    &__track{
      svg {
        width: calc(368/ 1199 * 100vw);
        height: calc(168/ 1199 * 100vw);
      }
    }
  }
}

@include respond-down('medium') {
  .favorites-page {

  }
}

@include respond-down('small') {
  .favorites-page {
    padding-bottom: 50px;
    &__grid{
      padding-bottom: 50px;
    }

    &__track{
      svg {
        width: calc(368/ 768 * 100vw);
        height: calc(168/ 768 * 100vw);
      }
    }

    &__empty{
      padding: 30px;
    }
  }
}