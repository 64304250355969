.recall-form {
  background-color: var(--black-color);

  &__inner {
    position: relative;
  }

  &__data {
    z-index: 10;
  }

  &__icon {
    display: flex;
    position: absolute;
    z-index: 5;

    svg {
      width: 100%;
      height: 100%;
    }

    &_top {
      top: 0;
      right: 0;
    }

    &_bottom {
      left: 0;
      bottom: 0;
    }
  }

  &__heading {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      border-bottom: 1px solid #fff;
    }
  }

  &__title,
  &__description {
    color: #fff;
  }

  &__main,
  &__bottom {
    display: flex;
  }

  &__button-wrap {
    display: flex;
    flex: 0 0 auto;
  }

  &__policy {
    flex: 0 1 auto;
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__success-icon {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
  }

  &__success-title {
    color: #fff;
    margin-top: 20px;
  }

  &__success-description {
    text-align: center;
    color: var(--white-color-50);
    margin-top: 12px;
  }
}

@include respond-up('large') {
  .recall-form {
    &__data {
      padding: 30px 75px 88px;
    }

    &__description {
      flex: 0 0 var(--grid-column4);
    }

    &__form-wrap {
      flex: 0 1 100%;
      margin-left: calc(var(--grid-gap) + var(--grid-column));
      max-width: calc(var(--grid-column5) + (var(--grid-gap) * 2));
    }

    &__icon {
      &_top {
        width: calc((270 / 1500) * 100vw);
        max-width: 270px;
        height: calc((173 / 1500) * 100vw);
        max-height: 173px;
      }

      &_bottom {
        width: calc(var(--grid-column5) + var(--grid-gap));
        max-width: 510px;
        height: calc((188 / 1500) * 100vw);
        max-height: 188px;
      }
    }

    &__bottom {
      margin-top: 28px;
    }

    &__policy {
      width: calc((319 / 1500) * 100vw);
      max-width: 410px;
    }

    &__heading {
      padding: 50px 75px 30px;

      &::before {
        left: 75px;
        right: 75px;
      }
    }
  }
}

@include respond-up('medium') {
  .recall-form {
    &__field {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &__main {
      justify-content: space-between;
    }

    &__bottom {
      justify-content: space-between;
    }

    &__success {
      padding-top: 30px;
    }
  }
}

@include respond('medium') {
  .recall-form {
    &__data {
      padding: 30px 30px 69px;
    }

    &__description {
      flex: 0 1 100%;
    }

    &__form-wrap {
      flex: 0 0 calc((360 / 768) * 100vw);
      margin-left: 60px;
    }

    &__icon {
      &_top {
        max-width: 228px;
        max-height: 146px;
      }

      &_bottom {
        max-width: 277px;
        max-height: 113px;
      }
    }

    &__bottom {
      margin-top: 25px;
    }

    &__button-wrap {
      margin-left: 33px;
    }

    &__heading {
      padding: 35px 30px 30px;

      &::before {
        left: 30px;
        right: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .recall-form {

  }
}

@include respond-down('small') {
  .recall-form {
    &__data {
      padding: 20px 25px 68px;
    }

    &__heading {
      padding: 30px 25px 20px;

      &::before {
        left: 25px;
        right: 25px;
      }
    }

    &__field {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__main {
      flex-direction: column;
    }

    &__form-wrap {
      margin-top: 20px;
    }

    &__icon {
      &_top {
        max-width: 166px;
        max-height: 106px;
      }

      &_bottom {
        max-width: 264px;
        max-height: 108px;
      }
    }

    &__bottom {
      margin-top: 20px;
      flex-direction: column;
    }

    &__button-wrap {
      justify-content: flex-end;
      margin-top: 20px;
    }

    &__success {
      padding: 20px 25px;
    }
  }
}