.index-page {
  &__hero {
    padding-top: 20px;
  }

  &__advantages {
    margin-top: 40px;
  }

  &__contacts {
    background-color: white;
  }
}

// В блоке с акциями куча абсолюта, будь осторожен с отступами на всех разрешениях
@include respond-up('large') {
  .index-page {
    margin-top: 131px;

    &__offer-slider {
      padding-top: 40px;
    }

    &__catalog-block {
      padding-top: 60px;
    }

    &__manufactures {
      padding-top: 116px;
    }

    &__ceo,
    &__services,
    &__delivery-pay,
    &__popular-models {
      padding-top: 80px;
    }

    &__actions {
      padding-top: 97px;
      margin-top: 50px;
    }

    &__info {
      padding: 80px 0;
    }

    &__hit-products {
      margin-top: 100px;
    }

    &__action-products {
      margin-top: 80px;
    }

    &__viewed-products {
      margin-top: 100px;
    }

    &__form {
      margin-top: 80px;
    }

    &__contacts {
      margin-top: 80px;
      padding-top: 80px;
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {
    margin-top: 74px;

    &__catalog-block,
    &__manufactures,
    &__ceo,
    &__services,
    &__delivery-pay,
    &__popular-models {
      padding-top: 70px;
    }

    &__action-products {
      padding-top: 139px;
    }

    &__actions {
      margin-top: 52px;
      padding-top: 70px;
    }

    &__form,
    &__viewed-products {
      margin-top: 70px;
    }

    &__info {
      padding: 70px 0;
    }

    &__hit-products {
      padding-top: 70px;
    }

    &__contacts {
      margin-top: 60px;
      padding-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {
    margin-top: 58px;

    &__advantages {
      margin-top: 25px;
    }

    &__catalog-block,
    &__manufactures,
    &__ceo,
    &__services,
    &__delivery-pay,
    &__action-products,
    &__popular-models {
      padding-top: 40px;
    }

    &__ceo {
      .company-ceo__text-wrap {
        padding: 25px 18px 0;
      }
    }

    &__actions {
      margin-top: 30px;
      padding-top: 50px;
    }

    &__form,
    &__viewed-products {
      margin-top: 40px;
    }

    &__info {
      padding: 40px 0;
    }

    &__hit-products {
      padding-top: 50px;
    }

    &__contacts {
      margin-top: 30px;
      padding-top: 30px;
    }
  }
}