.login-page {
  &_ajax {
  }

  &_static {
    .login-page {
      &__inner {
        background-color: white;
        padding: var(--grid-gap);
        border-radius: var(--border);
      }
    }
  }

  &__title.text__small {
    color: var(--grey-text-color);
    text-align: center;
  }

  &__tabs.tabs {
    margin: 20px 0;
    justify-content: center;
  }
}

@include respond-up('large') {
  .login-page {
    min-width: 640px;

    &_static {
      padding-bottom: 150px;
      display: flex;
      justify-content: center;

      .login-page {
        &__inner {
          flex: 0 0 644px;
        }
      }
    }

    &__title.text__small {
      padding-bottom: 35px;
    }
  }
}

@include respond-up('medium') {
  .login-page {
    &__title.text__small {
      padding-bottom: 40px;
    }
  }
}

@include respond('medium') {
  .login-page {

  }
}

@include respond-down('medium') {
  .login-page {

  }
}

@include respond-down('small') {
  .login-page {
    &_static {
      padding-bottom: 70px;
    }
    &__title.text__small {
      padding-bottom: 20px;
    }
  }
}