.catalog-producers {
  &__list {
    display: grid;
    grid-gap: 1px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    height: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__name {
    color: var(--grey-text-color);
    flex: 0 0 auto;
  }
}

@include respond-up('large') {
  .catalog-producers {
    &__list {
      margin-top: 35px;
      grid-template-columns: repeat(5, 1fr);
    }

    &__link {
      padding: 27px 24px 25px;
      transition: box-shadow .5s, border-radius .5s;

      &:hover {
        position: relative;
        box-shadow: 0 7px 40px rgba(50, 59, 86, 0.12);
        border-radius: 3px;
      }
    }

    &__picture {
      width: 121px;
      height: 60px;
    }

    &__name {
      margin-top: 7px;
    }

    .pagination-endless {
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .catalog-producers {

  }
}

@include respond('medium') {
  .catalog-producers {
    &__list {
      margin-top: 28px;
      grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
    }

    &__link {
      padding: 25px 24px;
    }

    &__picture {
      width: 116px;
      height: 60px;
    }

    &__name {
      margin-top: 8px;
    }

    .pagination-endless {
      margin-top: 28px;
    }
  }
}

@include respond-down('medium') {
  .catalog-producers {

  }
}

@include respond-down('small') {
  .catalog-producers {
    &__list {
      margin-top: 20px;
      grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
    }

    &__link {
      padding: 22px 17px 16px;
    }

    &__picture {
      width: 85px;
      height: 48px;
    }

    &__name {
      margin-top: 5px;
    }

    .pagination-endless {
      margin-top: 22px;
    }
  }
}