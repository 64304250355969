
.slot-item {
  border: 1px solid var(--gray);
  border-radius: 3px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: relative;
  cursor: pointer;

  &._has-records {
    border-color: #2651e1;
    background-color: #edf4ff;

    .slot-item {
      &__verbose {
        color: #2651e1;
      }
    }
  }

  &._occupied {
    border-color: var(--red);
    background-color: var(--red-bg);

    .slot-item {
      &__verbose {
        color: var(--red);
      }
    }
  }

  &:hover {
    .slot-item {
      &__post-list {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__verbose {
  }

  &__post-list {
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: -65px;
    top: 100%;
    background-color: var(--black);
    min-width: 205px;
    z-index: 10;

    padding: 8px 0;
    border-radius: 3px;

    &:before {
      content: '';
      position: absolute;
      border: 12px solid transparent;
      border-bottom: 12px solid var(--black);
      top: -17px;
      left: 99px;

    }
  }

  &__post-item {
    padding: 6px 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    border-bottom: 1px solid var(--border);
    position: relative;

    &:first-child {
      border-top: 1px solid var(--border);
    }

    &:hover {
      .slot-item {
        &__record {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &__post-name {
    color: var(--white-opacity);
    font-size: 13px;
  }

  &__freedom {
    border: 1px solid var(--green-border);
    color: var(--green-text);
    line-height: calc(14 / 12);
    font-size: 12px;
    border-radius: 3px;
    padding: 0 4px;
  }

  &__record-name {
    font-size: 12px;
    color: red;
  }

  &__record {
    top: -9px;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    position: absolute;
    left: 100%;
    min-width: 254px;
  }

  &__record-list {
    background-color: var(--black);
    border-radius: 3px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    margin-left: 5px;

    &:before {
      content: '';
      position: absolute;
      border: 12px solid transparent;
      border-right: 12px solid var(--black);
      top: 10px;
      left: -17px;
    }
  }

  &__record-list-item {
    display: flex;
    justify-content: space-between;
    padding: 6px 15px;
    font-size: 13px;

    border-bottom: 1px solid var(--border);

    &:first-child {
      border-top: 1px solid var(--border);
    }
  }

  &__record-list-item-key {
    flex: 0 0 40%;
    color: var(--white-opacity);
  }

  &__record-list-item-value {
    flex: 0 0 60%;
    color: #fff;
  }
}
