.info-cards {
  background: #fff;

  &__item {
     display: flex;
  }

  &__title {
    color: var(--primary-color);
  }

  &__description {
    margin-top: 10px;

    &.text {
      color: var(--grey-text-color);
    }
  }

  &__icon-wrap {
    height: fit-content;

    &.round-index {
      background: var(--primary-color-15);
    }

    &_gray{
      //.info-cards__icon-wrap.round-index
      &.info-cards__icon-wrap.round-index {
            background: var(--grey-stroke-color);
      }


      .round-index__title{
        background: var(--black-color);
      }
    }

  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: var(--primary-color);

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@include respond-up('large') {
  .info-cards {
    margin-top: 28px;
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--grid-gap);
    }

    &__item {
      grid-column: span 1;
      column-gap: 16px;
    }

    &__description {
      width: calc(var(--grid-column5) - 30px);
    }
  }
}

@include respond-up('medium') {
  .info-cards {
    &__item {
      padding: 20px 0 30px;
    }
  }
}

@include respond('medium') {
  .info-cards {
    margin-top: 14px;

    &__description {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('medium') {
  .info-cards {
    &__list {
      display: flex;
      flex-direction: column;
    }

    &__item {
      column-gap: 14px;

      &:not(:first-child) {
        border-top: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond-down('small') {
  .info-cards {
    margin-top: 20px;

    &__item {
      padding: 20px 18px 20px 0;
    }
  }
}