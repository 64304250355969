.payment-help {
  position: relative;

  &:hover {
    .payment-help {
      &__text-wrap {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text-wrap {
    position: absolute;
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--default-transition-timing) var(--default-transition-function);
  }

  &__triangle {
    border-style: solid;
    display: flex;
    height: fit-content;
    width: fit-content;
    position: relative;
  }

  &__text {
    box-shadow: 0 0 20px 0 #2D29281A;
    background: #fff;
    padding: 14px;
    font-weight: 400;
    width: 196px;
  }
}

@include respond-up('medium') {
  .payment-help {
    &__text-wrap {
      left: 100%;
      top: -14px;
    }

    &__triangle {
      border-width: 7px 7px 7px 0;
      border-color: transparent #fff transparent transparent;
      top: 14px;
    }
  }
}

@include respond-down('small') {
  .payment-help {
    &__text-wrap {
      top: 100%;
      left: -28px;
      flex-direction: column;
    }

    &__triangle {
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent #fff transparent;
      left: 28px;
    }
  }
}