.index-advantages {
  &__list {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__item {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
    background-color: white;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background-color: var(--primary-color);
  }

  &__image {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .index-advantages {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }

    &__item {
      padding: 25px;
    }

    &__title {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .index-advantages {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding: 20px 25px;
    }

    &__title {
      margin-top: 25px;
    }
  }
}

@include respond-down('small') {
  .index-advantages {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      flex-direction: row;
      align-items: center;
      padding: 16px;
    }

    &__icon {
      flex: 0 0 auto;
    }

    &__title {
      flex: 0 1 100%;
      margin-left: 15px;
    }
  }
}