.link {
  &_availability {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--primary-color);
    padding: 10px 0 2px;
    margin: -10px 0 0;
    border-bottom: 1px dashed var(--primary-color-80);
  }

  &_more {
    padding: 10px;
    margin: -10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    color: var(--black-color);
    display: flex;
    align-items: baseline;

    svg {
      path {
        stroke: var(--black-color);
        fill: var(--black-color)
      }
    }
  }

  &_next,
  &_slider-next,
  &_prev,
  &_slider-prev,
  &_white {
    width: var(--next-size);
    height: var(--next-size);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &_slider-next,
  &_slider-prev {
    border: 1px solid var(--primary-color-19);
  }

  &_next,
  &_prev {
    background-color: var(--primary-color);

    svg {
      path {
        stroke: var(--white-color);
      }
    }
  }

  &_prev, &_slider-prev {
    svg {
      transform: rotate(180deg);
    }
  }

  &_white{
    background-color: transparent;
    border: 1px solid var(--white-color-60);
    svg{
      path{
        stroke: var(--white-color);
        fill: var(--white-color);
      }
    }
  }
}

@include respond-up('large') {
  .link {
    transition: background-color var(--default-transition-timing) var(--default-transition-function), border-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);

    svg {
      path {
        transition: stroke var(--default-transition-timing) var(--default-transition-function);
      }
    }

    &_next,
    &_prev {
      --next-size: 44px;

      &:hover {
        background-color: var(--white-color);

        svg {
          path {
            stroke: var(--black-color);
          }
        }
      }
    }

    &_slider-next,
    &_slider-prev {
      --next-size: 49px;

      &:hover {
        border-color: var(--primary-color);
      }
    }

    &_white {
      --next-size: 49px;

      &:hover{
        border-color: #fff;
      }
    }
  }
}

@include respond('medium') {
  .link {
    &_next,
    &_prev{
      --next-size: 40px;
    }
  }
}

@include respond-down('medium') {
  .link {
    &_slider-next,
    &_slider-prev,
    &_white {
      --next-size: 41px;
    }
  }
}

@include respond-down('small') {
  .link {
    &_next,
    &_prev {
      --next-size: 34px;
    }
  }
}