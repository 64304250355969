.manufacturers {
  &__title{
    @extend h2
  }

  &__item{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__content{
    position: relative;
  }

  &__arrows{
    .arrow{
      position: absolute;
      z-index: 100;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    }
    .arrows{
      &__left{
        left: -22px;
        top: calc(50% - 22px);
      }
      &__right{
        right: -22px;
        top: calc(50% - 22px);
      }
    }
  }

  &__link{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__item{
    border-right: 1px solid var(--grey-stroke-color);
  }

  &__name{
    color: #949494;
  }

  &__image-wrap{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 0 auto;
  }
}

@include respond-up('large') {
  .manufacturers {
    &__title-wrapper{
      display: flex;
      align-items: center;
      padding-bottom: 37px;
    }

    &__body{
      margin: 0 calc(var(--grid-spacer) + var(--grid-indent));
    }

    &__title{
      margin-right: 100px;
    }

    &__slider{
      height: 167px;
    }
  }
}

@include respond-up('medium') {
  .manufacturers {

  }
}

@include respond('medium') {
  .manufacturers {
    &__tabs-container{
      margin-top: 20px;
      margin-bottom: 24px;
    }
  }
}

@include respond-down('medium') {
  .manufacturers {
    &__arrows{
      display: none;
    }

    &__slider{
      height: 167px;
    }

    &__item{
      &.swiper-slide{
        width: 162px;
        &:first-child{
          margin-left: var(--grid-spacer);
        }
      }
    }
  }
}

@include respond-down('small') {
  .manufacturers {
    &__tabs-container{
      margin-top: 14px;
      margin-bottom: 20px;
    }
  }
}