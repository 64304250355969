.product-base-technologies-tab {
    &__tech-list {
        display: flex;
        flex-direction: column;    
    }

    &__tech-item {
        &:not(:last-child) {
            border-bottom: 1px solid var(--grey-stroke-color);
        }
    }
}

@include respond-up('large') {
    .product-base-technologies-tab {
        &__tech-list {
            row-gap: 30px;
        }

        &__tech-item {
            &:not(:last-child) {
                padding-bottom: 30px;
            }
        }

        &__tech-description {
            margin-top: 15px;
        }
    }
}

@include respond('medium') {
    .product-base-technologies-tab {
        &__tech-description {
            margin-top: 14px;
        }
    }
}

@include respond-down('medium') {
    .product-base-technologies-tab {
        &__tech-list {
            row-gap: 20px;
        }

        &__tech-item {
            &:not(:last-child) {
                padding-bottom: 20px;
            }
        }
    }
}

@include respond-down('small') {
    .product-base-technologies-tab {
        &__tech-description {
            margin-top: 13px;
        }
    }
}