.modal-form-heading {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__description {
    color: var(--grey-text-color);
    text-align: center;
  }
}

@include respond-up('large') {
  .modal-form-heading {
    &__description {
      margin-top: 18px;
    }
  }
}

@include respond-up('medium') {
  .modal-form-heading {

  }
}

@include respond('medium') {
  .modal-form-heading {
    &__description {
      margin-top: 16px;
    }
  }
}

@include respond-down('medium') {
  .modal-form-heading {

  }
}

@include respond-down('small') {
  .modal-form-heading {
    &__description {
      margin-top: 12px;
    }
  }
}