.size-selection{
  background: #FFFFFF;

  &__title-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title{
    display: block;
  }

  &__image-container{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__list{
    display: flex;
    flex-direction: column;
  }

  &__pair{
    display: grid;
    border-bottom: 1px solid var(--grey-stroke-color);
    &_head{
      background-color: var(--grey-light-color);
    }
  }

  &__link{
    color: var(--primary-color);
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.32px;
    &._disabled{
      pointer-events: none;
      cursor: none;
      color: var(--grey-text-color);
    }
  }

  &__cell-text{
    line-height: 135%;
    letter-spacing: -0.32px;
    color: var(--black-color);
  }

  &__indexes{
    font-size: 13px;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.26px;
    color: var(--grey-text-color);
    padding: 3px 8px;
    border: 1px solid var(--grey-stroke-color);
    border-radius: 2px;
  }

  &__axis-icon, &__winter-icon{
    display: flex;
  }

  &__cell{
    position: relative;
  }

  &__oem{
    position: absolute;
    cursor: help;
    left: 0;
    top: 0;
    border-radius: 0 0 4px 0;
    background: var(--blue-background-color);
    padding: 2px 6px;
    font-size: 15px;
    font-weight: 500;
    line-height: 120%;
    &:hover{
      .size-selection{
        &__hover-plate{
          &_oem{
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  &__info-wrap{
    padding-bottom: 30px;
  }

  &__info-item{
    display: flex;
    align-items: center;
  }

  &__info-title{
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.32px;
    color: var(--grey-text-color);
  }

  &__info-value{
    /* Основной текст медиум */
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.32px;
    color: var(--black-color);
  }

  &__axis-icon{
    position: relative;
    cursor: help;
    &:hover{
      .size-selection{
        &__hover-plate{
          &_axis{
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
  }

  &__hover-plate{
    position: absolute;
    padding: 14px;
    pointer-events: none;
    opacity: 0;
    box-shadow: 0 0 20px 0 #2D29281A;
    background: #FFFFFF;
    transition: opacity .3s;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.24px;
    color: var(--grey-text-color);
    white-space: nowrap;
    z-index: 200;
    &_oem{
      left: -68px;
      top: -57px;
      width: 135px;
    }

    &_axis{
      right: -121px;
      top: -9px;
      width: 108px;
    }

    &_hint{
      left: 60px;
      bottom: 50px;
      white-space: normal;
      width: 303px;
    }
  }

  &__hint{
    width: 14px;
    height: 14px;
    background: #C7C7C8;
    border-radius: 100%;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700;
    font-size: 10px;
    display: inline-block;
    margin-left: 6px;
    cursor: help;
    &:hover{
      .size-selection{
        &__hover-plate{
          &_hint{
            opacity: 1;
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .size-selection{
    margin-bottom: 120px;
    padding: 26px 30px 50px 30px;

    &__image-container{
      margin-right: 30px;
    }

    &__pair{
      grid-template-columns: repeat(4, 1fr);
    }

    &__cell{
      &:first-child{
        padding-left: 40px;
      }
      &_head{
        display: flex;
        align-items: center;
      }
    }

    &__info-title{
      margin-right: 20px;
    }

    &__axis-icon{
      margin-right: 30px;
    }

    &__info-image-container{
      width: 309px;
      height: 181px;
      margin-top: -10px;
    }

    &__info-image{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@include respond-up('medium') {
  .size-selection{
    &__title{
    }

    &__image-container{
      width: 100px;
      height: 72px;
    }

    &__link, &__cell-text{
      font-size: 16px;
    }

    &__cell-wrap{
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      &_double{
        height: 25.5px;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }

    &__cell{
      padding: 20px 0 20px 20px;
    }

    &__link{
      margin-right: 16px;
    }

    &__indexes{
      margin-right: 8px;
    }

    &__info-title, &__info-value{
      font-size: 16px;
    }

    &__info-wrap{
      display: flex;
      justify-content: space-between;
    }

    &__info-item{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }

    &__scroll-icon{
      display: none;
    }
  }
}

@include respond('medium') {
  .size-selection{
    margin-bottom: 70px;
    padding: 30px 20px;

    &__wrap{
      padding-top: 35px;
    }

    &__image-container{
      margin-right: 25px;
    }

    &__info-image-container{
      width: 265px;
      height: 155px;
    }

    &__info-title{
      margin-right: 16px;
    }

    &__hover-plate{
      &_hint{
        bottom: 68px;
        left: -157px;
        white-space: normal;
        width: 303px;
      }
    }
  }
}

@include respond-down('medium') {
  .size-selection{
    padding-bottom: 30px;
    &__cell-text{
      font-size: 14px;
    }
    &__axis-icon{
      margin-right: 9px;
    }
  }
}

@include respond-down('small') {
  .size-selection{
    margin-bottom: 50px;
    padding: 20px 15px;

    &__list{
      overflow-x: scroll;

      &::-webkit-scrollbar{
        display: none;
      }
    }
    &__title-wrap{
      flex-direction: column;
      align-items: flex-start;
    }

    &__wrap{
      padding-top: 30px;
    }

    &__image-container{
      width: 75px;
      height: 54px;
      margin-bottom: 8px;
    }

    &__info-image-container{
      display: flex;
      justify-content: center;
      margin-top: 25px;
    }

    &__info-image{
      width: 308px;
      height: 181px;
      object-fit: contain;
    }

    &__link, &__cell-text{
      font-size: 15px;
    }

    &__info-title, &__info-value{
      font-size: 15px;
    }

    &__info-wrap{
      position: relative;
    }

    &__info-item{
      justify-content: space-between;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }

    &__cell{
      padding: 32px 15px 15px 15px;
      &_head{
        padding: 15px;
      }
    }

    &__pair{
      display: grid;
    }

    &__cell-wrap{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
      margin-bottom: 12px;
      &_double{
        margin-bottom: 42px;
      }
    }

    &__link{
      flex: 1 1 100%;
    }

    &__hover-plate{
      &_hint{
        z-index: 1000;
        top: 71px;
        left: -177px;
        white-space: normal;
        width: 303px;
        height: fit-content;
      }
    }

    &__scroll-icon{
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}

@media (max-width: 1450px) {
  .size-selection{
    &__pair{
      grid-template-columns: 270px 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 900px) {
  .size-selection{
    &__pair{
      grid-template-columns: 230px 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 767px) {
  .size-selection{
    &__pair{
      grid-template-columns: 150px 1fr 1fr 1fr;
      min-width: 530px;
    }
  }
}