.user-profile-record-for-service {
  &__header {
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__back {
    display: flex;

    svg path {
      stroke: #1F2129;
    }
  }

  &__box {
    background-color: #fff;

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-stroke-color);
    }
  }

  .record-service-point__title {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      border-bottom: 1px solid var(--grey-stroke-color);
    }
  }

  &__confirm {
    display: flex;
  }

  .record-for-service-success {
    &__heading,
    &__bottom {
      display: none;
    }
  }
}

@include respond-up('large') {
  .user-profile-record-for-service {
    &__header {
      padding: 28px 38px;
    }

    &__box,
    &__confirm {
      padding: 35px 38px;
    }

    .record-service-point__title {
      &::before {
        left: -38px;
        right: -38px;
      }
    }

    &__policy {
      width: var(--grid-column7);
    }
  }
}

@include respond-up('medium') {
  .user-profile-record-for-service {
    &__back {
      padding: 18px;
      margin: -18px 0 -18px -18px;
    }

    &__content {
      margin-top: 18px;
    }

    .record-service-point__title {
      padding-top: 35px;
      margin-top: 35px;
    }

    &__confirm {
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .user-profile-record-for-service {
    &__header {
      padding: 23px 28px;
    }

    &__box,
    &__confirm {
      padding: 35px 28px;
    }

    .record-service-point__title {
      &::before {
        left: -28px;
        right: -28px;
      }
    }

    &__policy {
      width: var(--grid-column4);
    }

  }
}

@include respond-down('medium') {
  .user-profile-record-for-service {

  }
}

@include respond-down('small') {
  .user-profile-record-for-service {
    &__header {
      padding: 15px 18px;
    }

    &__back {
      padding: 15px;
      margin: -15px 0 -15px -15px;
    }

    &__box {
      padding: 25px 18px;

      &_confirm {
        padding: 0;
      }
    }

    &__content {
      margin-top: 20px;
    }

    .record-service-point__title {
      padding-top: 25px;
      margin-top: 25px;

      &::before {
        left: -18px;
        right: -18px;
      }
    }

    &__confirm {
      padding: 25px 18px;
      flex-direction: column-reverse;
    }

    &__submit {
      &.button {
        width: 100%;
      }
    }

    &__policy {
      margin-top: 12px;
    }
  }
}