
.service-center {
  height: 100%;

  &__name {
    border-bottom: 1px solid var(--gray);
    font-size: 15px;
    line-height: calc(19 / 15);
    font-weight: 600;
    padding: 14px 22px;
    display: grid;
    align-items: center;
    color: #1F2129;
    min-height: 69px;
  }

  &__slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 22px 14px;
  }

  &__slot {
    flex: 0 0 48%;
    margin-bottom: 6px;
    transition: var(--default-transition);

    &:hover {
      background-color: var(--slot-bg);
    }
  }
}
