.record-for-service {
  &__box {
    background-color: #fff;
  }

  &__confirm {
    display: flex;
  }

  &__inner {
    display: grid;
  }

  &__info {
    background-color: #fff;
  }

  &__info-title {
    color: var(--black-color);
  }

  &__question-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px;
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__question-icon {
    display: flex;
  }

  &__question-title {
    margin-left: 6px;
    color: var(--grey-text-color);
  }

  &__heading {
    padding-top: 20px;
  }
}

@include respond-up('large') {
  .record-for-service {
    padding-bottom: 120px;

    &__inner {
      grid-template-columns: var(--grid-column9) var(--grid-column3);
      grid-column-gap: var(--grid-gap);
    }

    &__box {
      grid-column: 1/2;
    }

    &__confirm {
      grid-column: 1/2;
      margin-top: 21px;
    }

    &__info-wrap {
      grid-column: 2/3;
      grid-row: 1/5;
    }

    &__info {
      position: sticky;
      top: 140px;
    }

    &__content {
      padding: 20px 38px 50px;
    }

    &__policy {
      width: var(--grid-column7);
      margin-right: 10px;
    }

    &__info-title {
      padding: 25px 22px;
    }

    &__question-link {
      &:hover {
        .record-for-service__question-title {
          color: var(--primary-color);
        }
      }
    }

    &__question-title {
      transition: color .4s;
    }
  }
}

@include respond-up('medium') {
  .record-for-service {
    &__inner {
      grid-row-gap: 14px;
    }

    &__confirm {
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .record-for-service {
    padding-bottom: 90px;

    &__content {
      padding: 20px 28px 35px;
    }

    &__policy {
      width: var(--grid-column4);
    }

    &__confirm {
      margin-top: 14px;
    }

    &__info-title {
      padding: 20px 28px;
    }
  }
}

@include respond-down('medium') {
  .record-for-service {
    &__inner {
      grid-template-columns: 1fr;
    }
  }
}

@include respond-down('small') {
  .record-for-service {
    padding-bottom: 50px;

    &__inner {
      grid-row-gap: 8px;
    }

    &__content {
      padding: 20px 18px 30px;
    }

    &__info-wrap {
      order: 4;
    }

    &__confirm {
      order: 5;
      flex-direction: column-reverse;
      margin-top: 17px;
    }

    &__submit {
      width: 100%;
    }

    &__policy {
      margin-top: 12px;
    }

    &__info-title {
      padding: 20px 18px;
    }
  }
}