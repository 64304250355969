.header-drop-menu {
  &__arrow-icon{
    svg{
      transition: transform var(--default-transition-timing) var(--default-transition-function);
      path{
        fill: var(--black-color);
      }
    }
  }

  &__link-icon{
    display: flex;
  }

  &__link-name{
    @extend h4
  }

  &__phone-link{
    @extend h3
  }
}

@include respond-up('large') {
  .header-drop-menu {
    display: none;
  }
}

@include respond('medium') {
  .header-drop-menu {
    height: calc(100vh - 74px);
    top: 74px;
    &__menu-container{
      margin: 40px 0 30px 0;
    }

    &__link{
      height: 60px;
    }

    &__link-icon{
      margin-right: 14px;
    }

    &__lk-button{
      height: 50px;
      width: 342px;
      margin-bottom: 25px;
    }

    &__address{
      margin-bottom: 15px;
    }
  }
}

@include respond-down('medium') {
  .header-drop-menu {
    overflow-y: scroll;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      timing-function: var(--default-transition-function);
    }
    transform: translateY(-100%);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--white-color);
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    &__menu-container{
      border-top: 1px solid var(--grey-stroke-color);
      border-bottom: 1px solid var(--grey-stroke-color);
    }

    &__element{
      border-bottom: 1px solid var(--grey-stroke-color);
      &:first-child{
        border-top: none;
      }

      &:last-child{
        border-bottom: none;
      }

      &._opened {
        .header-drop-menu {
          &__arrow-icon {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &__accordion-block{
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: var(--default-transition-timing);
        timing-function: var(--default-transition-function);
      }
    }

    &__block-wrap{
      display: flex;
      flex-direction: column;
    }

    &__link{
      display: flex;
      align-items: center;
      &_with-arrow{
        justify-content: space-between;
      }
    }

    &__link-name-wrap{
      display: flex;
      align-items: center;
    }

    &__child-link{
      color: var(--grey-text-color);
      padding: 4px 0;
    }

    &__block-wrap{
      padding-bottom: 20px;
    }

    &__lk-button{
      color: var(--black-color);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--grey-light-color);
      border: none;
    }

    &__address{
      max-width: 173px;
      &:before{
        content: '';
        position: static;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-color: var(--primary-color);
        border-radius: 100%;
      }
    }

    &__phone{
      margin-bottom: 10px;
    }

    &__phone-link, &__email-link{
      padding: 10px 0;
    }

    &__email{
      margin-bottom: 15px;
      margin-top: 20px;
      padding-bottom: 100px;
    }

    &__contact-wrap {
      display: flex;
      flex-direction: column;
    }

    &__phone-wrap {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .header-drop-menu {
    height: calc(100vh - 58px);
    top: 57px;
    &__menu-container{
      margin: 25px 0 20px 0;
    }

    &__link{
      height: 56px;
    }

    &__link-icon{
      margin-right: 12px;
    }

    &__lk-button{
      height: 46px;
      width: 100%;
      margin-bottom: 20px;
    }

    &__address{
      margin-bottom: 10px;
    }
  }
}