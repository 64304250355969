
    .popup-fade-enter-active, .popup-fade-leave-active {
        transition: opacity .5s;
    }
    .popup-fade-enter, .popup-fade-leave-to {
        opacity: 0;
    }

    .popup {
        position: fixed;
        z-index: 9000;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(22, 26, 23, 0.8);
        overflow-y: scroll;
        //padding-top: 90px;
        //padding-bottom: 90px;
    }

    .popup__layout {
        display: flex;
        position: relative;
        z-index: 11000;
        cursor: default;
        padding: 80px 0;
        margin: 0 auto;
        min-height: 100%;
        align-items: center;
        justify-content: center;
    }

    .popup__window {
        background: #FFFFFF;
        border-radius: 2px;
        padding: 40px;
        position: relative;
    }

    .popup__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        position: absolute;
        right: 0;
        top: 0;

      svg {
        path {
          fill: #1D202C;
        }
      }
    }
