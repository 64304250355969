.text {
  color: var(--black-color);
  font-family: var(--font);
  letter-spacing: -0.02em;

  &__orange {
    color: var(--primary-color);
  }

  &__green {
    color: var(--green-color);
  }

  &__grey {
    color: var(--grey-text-color);
  }

  &__date {
    position: relative;
    padding-left: 17px;
    color: var(--grey-text-color);

    &:before {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: var(--primary-color);
      left: 0;
      top: 50%;
      transform: translateY(calc(-50% - 1px));
    }
  }

  &__link {
    position: relative;
    padding-bottom: 2px;
    display: inline-block;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      background-color: var(--black-color);
      bottom: 0;
      transform-origin: right;
      transition: transform .3s ease-out;
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      background-color: var(--black-color);
      bottom: 0;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .3s ease-out;
    }
  }

  &__big {
    //styleName: Большой абзац;
    font-weight: 500;
    line-height: 135%;

    &_semi-bold {
      font-weight: 600;
    }
  }

  &__date,
  &__main,
  &__small,
  &__mini {
    font-weight: 400;
    line-height: 135%;

    &_medium {
      font-weight: 500;
    }

    &_semi-bold {
      font-weight: 600;
    }
  }

  &__thirteen {
    //styleName: 13 медиум;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  }

  &__twelve {
    //styleName: 12;
    font-size: 12px;
    line-height: 14px;
  }

  &__14-12 {
    line-height: 135%;
    font-weight: 400;
  }


  &__16-13 {
    line-height: 125%;
    font-weight: 600;
  }
}

@include respond-up("medium") {
  .text {
    &__link {
      &:hover {
        &::after {
          transition: transform .4s var(--default-bezier) .33s;
          transform: scaleX(1);
        }

        &::before {
          transition: transform .4s var(--default-bezier);
          transform: scaleX(0);
        }
      }
    }

    &__big {
      //styleName: Большой абзац;
      font-size: 18px;
    }

    &__main {
      //styleName: Основной текст;
      font-size: 16px;
    }

    &__small {
      //styleName: Мелкий текст;
      font-size: 15px;
    }

    &__date,
    &__mini {
      //styleName: Мини пункты;
      font-size: 14px;
    }

    &__14-12 {
      font-size: 14px;
    }

    &__16-13 {
      font-size: 16px;
    }
  }
}

@include respond-down("small") {
  .text {
    &__big {
      //styleName: Большой абзац Mob;
      font-size: 17px;
    }

    &__main {
      //styleName: Основной текст Mob;
      font-size: 15px;
    }

    &__small {
      //styleName: Мелкий текст Mob;
      font-size: 14px;
    }

    &__date,
    &__mini {
      //styleName: Мини пункты Mob;
      font-size: 13px;
    }

    &__14-12 {
      font-size: 12px;
    }

    &__16-13 {
      font-size: 13px;
    }
  }
}
