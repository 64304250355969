.catalog-block {
  display: grid;

  &__tyres, &__wheels, &__oils{
    display: flex;
    position: relative;
  }

  &__links{
    background-color: #fff;
  }

  &__oils{
    background: linear-gradient(90.00deg, rgb(114, 124, 147),rgb(81, 89, 116) 61.892%,rgb(83, 92, 118) 100%);
  }

  &__wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title{
    color: var(--white-color);
    &_big{
      @extend h2;
    }

    &_small{
      @extend h3;
    }
  }

  &__links{
    display: flex;
    flex-direction: column;
  }

  &__link-wrap{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-stroke-color);
    &:last-child{
      border: none;
    }
  }

  &__link-name{
    display: flex;
    align-items: center;
    @extend h4;
  }

  &__link-icon{
    display: flex;
  }

  &__available{
    display: flex;
    align-items: center;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.22);
    color: var(--white-color);
    font-weight: 600;
  }

  &__bottom-part{
    display: flex;
    justify-content: space-between;
    z-index: 100;
  }

  &__image-wrap, &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__picture{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image{
    object-fit: contain;
  }

  &__linl-arrow-icon {
    display: flex;
    align-items: center;
  }

  &__link-arrow-wrap {
    width: 8px;
    height: 14px;
    display: flex;
    justify-content: center;
    svg {
      path{
        fill: var(--t);
      }
    }
  }
}

@include respond-up('large') {
  .catalog-block {
    grid-template-rows: auto auto;
    grid-gap: 28px;
    &__tyres{
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    &__links{
      grid-column: 2 / 2;
      grid-row: 1 / 2;
    }

    &__wheels{
      grid-column: 1 / 2;
      grid-row: 2 / 2;
    }

    &__oils{
      grid-column: 2 / 2;
      grid-row: 2 / 2
    }
    &__wrap{
      &:hover{
        .catalog-block{
          &__link{
            border-color: #fff;
          }

          &__available{
            background: var(--white-color-50);
          }
        }
      }
    }

    &__link-wrap{
      padding: 29px 35px;
      &:hover{
        .catalog-block{
          &__link-name{
            color: var(--primary-color);
          }
        }
      }
    }

    &__link-name{
      transition: color .3s;
    }

    &__link-icon{
      margin-right: 21px;
    }

    &__available{
      padding: 12px 20px;
      font-size: 15px;
      transition: background-color .5s;
    }

    &__image{
      &_wheels{
        height: 6.7vw;
        max-height: 123px;
        position: absolute;
        right: 15%;
      }
      &_oils{
        //height: 6.7vw;
        //max-height: 123px;
        position: absolute;
        right: 15%;
      }
    }

    &__title{
      &_small{
        padding-bottom: 80px;
      }
    }

    &__link {
      width: 44px;
      height: 44px;
    }
  }
}

@include respond-up('medium') {
  .catalog-block {
    &__tyres{
      background: linear-gradient(209.72deg, #1D202C 34.72%, #586170 93.86%);
    }

    &__wheels{
      background: linear-gradient(96.14deg, #FF5924 23.72%, #E8410D 66.27%);
    }

    &__wrap{
      padding: 25px;
    }

    &__available{
      font-size: 15px;
    }
  }
}

@include respond('medium') {
  .catalog-block {
    grid-template-rows: auto auto;
    grid-gap: 14px;
    &__tyres{
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    &__links{
      grid-column: 2 / 2;
      grid-row: 1 / 2;
    }
    &__wheels{
      grid-column: 1 / 2;
      grid-row: 2 / 2;
    }

    &__oils{
      grid-column: 2 / 2;
      grid-row: 2 / 2;
    }
    &__available{
      padding: 10px 18px;
      font-size: 15px;
    }

    &__link-wrap{
      padding: 27px 25px;
    }

    &__link-icon{
      margin-right: 15px;
    }

    &__title{
      &_small{
        padding-bottom: 100px;
      }
    }

    &__image{
      &_wheels{
        max-height: 119px;
        position: absolute;
        right: 15%;
      }
      &_oils{
        max-height: 155px;
        position: absolute;
        right: 15%;
      }

    }

    &__link {
      width: 40px;
      height: 40px;
    }
  }
}

@include respond-down('medium') {
  .catalog-block {

  }
}

@include respond-down('small') {
  .catalog-block {
    &__tyres{
      background: linear-gradient(208.44deg, #1D202C 39.16%, #4B5261 91.37%);
    }

    &__wheels{
      background: linear-gradient(118deg, #FF5924 47.58%, #E34614 84.41%);
      margin-bottom: 12px;
    }

    &__links{
      margin-bottom: 12px;
    }

    &__available{
      display: flex;
      align-items: center;
      padding: 8px 14px;
      font-size: 13px;
    }

    &__wrap{
      padding: 25px;
    }

    &__link-wrap{
      padding: 20px 20px;
    }

    &__link-icon{
      margin-right: 21px;
    }

    &__title{
      padding-bottom: 110px;
    }

    &__image{
      position: absolute;
      right: calc(30/768 * 100vw);
      &_tyres{
        max-height: 113px;
      }
      &_wheels{
        max-height: 92px;
      }
      &_oils{
        max-height: 142px;
      }
    }

    &__link {
      width: 34px;
      height: 34px;
    }
  }
}