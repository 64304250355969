.date-picker {
  .vuejs3-datepicker {
    &__calendar-actionarea {
      padding: 0;
    }

    &__calendar-topbar {
      display: none;
    }

    &__calendar {
      border-radius: 0;
      margin: 0;
      box-shadow: none;
      border: 1px solid var(--border-gray-color);
      width: 100%;
      background-color: var(--grey-light-color);
    }

    header {
      display: flex;
      padding: 10px 18px;
      border: 1px solid var(--grey-stroke-color);
      background-color: #fff;
    }

    span.prev, span.next {
      position: relative;
      border: 1px solid rgba(255, 89, 36, 0.19);
      border-radius: 100% !important;
      flex: 0 0 auto;

      &:hover {
        background: var(--t) !important;
      }

      &:after {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 9px;
        height: 13px;
        z-index: 10;
        background: {
          repeat: no-repeat;
          position: center;
        };
      }
    }

    span.prev {
      &:before {
        background-image: url('/static/images/svg/arrow-left.svg');
      }
    }

    span.next {
      &:before {
        background-image: url('/static/images/svg/arrow-right.svg');
      }
    }

    header span:nth-child(2),
    .day__month_btn,
    .month__year_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: var(--black-color);
      transition: color .4s;

      &.up {
        &:hover {
          background: var(--t);
        }
      }
    }

    .cell {
      &.month,
      &.year {
        transition: color .4s;
        background-color: var(--t) !important;
        border: none !important;

        &.selected {
          color: var(--primary-color);
        }
      }

      &.day {
        position: relative;
        z-index: 5;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: -0.02em;
        padding: 0;
        color: var(--black-color);
        background-color: var(--t) !important;
        transition: color .4s;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(calc(-50% + .5px), calc(-50% + 1px), 0);
          border-radius: 100px;
          z-index: -1;
          height: 36px;
          width: 36px;
          background-color: transparent;
          transition: background-color .4s;
        }

        &.selected {
          color: white;

          &:after {
            background-color: var(--primary-color);
          }
        }
      }

      &:not(.blank):not(.disabled).day:hover {
        border: 1px solid var(--t);
      }

      &:not(.blank):not(.disabled):not(.selected).day:hover {
        &:after {
          background-color: white;
        }
      }

      &.day-header {
        position: relative;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        font-style: normal;
        color: var(--black-color);
        border: none;
        margin-bottom: 24px;
      }
    }

    header + div {
      position: relative;
      border: 1px solid var(--grey-stroke-color);
      border-top: unset;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        border-bottom: 1px solid var(--grey-stroke-color);
      }
    }
  }
}

@include respond-up('large') {
  .date-picker {
    .vuejs3-datepicker {
      .cell.day-header {
        padding: 3px 5px;
        height: 46px;
      }

      header + div {
        padding: 0 30px 24px;

        &:after {
          top: 46px;
        }
      }

      .day__month_btn,
      .month__year_btn {
        &:hover {
          color: var(--primary-color);
        }
      }

      span.prev, span.next {
        transition: border-color .4s;

        &:hover {
          border-color: var(--primary-color);
        }
      }

      .cell {
        &.month,
        &.year {
          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .date-picker {
    .vuejs3-datepicker {
      span.prev, span.next {
        width: 49px;
        height: 49px;
      }
    }
  }
}

@include respond('medium') {
  .date-picker {
    .vuejs3-datepicker {
      header + div {
        padding: 0 20px 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .date-picker {
    .vuejs3-datepicker {
      .cell.day-header {
        padding: 5px;
        height: 51px;
      }

      header + div {
        &:after {
          top: 52px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .date-picker {
    .vuejs3-datepicker {
      header {
        padding: 12px 16px;

        + div {
          padding: 0 12px 14px;
        }
      }

      header span:nth-child(2),
      .day__month_btn,
      .month__year_btn {
        line-height: 120%;
      }

      &.day-header {
        font-weight: 500;
      }
    }
  }
}