.model-page-type-size-item {
  position: relative;

  &__link {
    display: flex;
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &:first-child {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__price {
    white-space: nowrap;
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__kit-price {
    color: var(--grey-text-color);
  }

  &__buttons {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 25;
  }

  &__available-link {
    display: flex;
    width: max-content;
  }
}

@include respond-up('large') {
  .model-page-type-size-item {
    &__link {
      padding: 23px 50px;
    }

    &__kit-price {
      margin-left: 25px;
    }

    &__to-cart {
      margin-left: 31px;
    }

    &__title {
      flex: 0 0 calc(var(--grid-column3) - 22px);
    }

    &__prices {
      flex: 0 0 calc(var(--grid-column3) + var(--grid-gap));
      margin-left: var(--grid-column);
    }

    &__available {
      flex: 0 0 var(--grid-column2);
      margin-left: calc((68 / 1500) * 100vw);
    }

    &__buttons {
      right: 50px;
    }
  }
}

@include respond-up('medium') {
  .model-page-type-size-item {
    &__link {
      align-items: center;
    }

    &__buttons {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }
}

@include respond('medium') {
  .model-page-type-size-item {
    &__link {
      padding: 20px 28px;
    }

    &__title {
      flex: 0 0 calc((166 / 768) * 100vw);
    }

    &__prices {
      align-items: flex-start;
      flex-direction: column;
      flex: 0 0 calc((166 / 768) * 100vw);
      margin-left: calc((30 / 768) * 100vw);
    }

    &__available {
      flex: 0 0 calc((166 / 768) * 100vw);
      margin-left: calc((30 / 768) * 100vw);
    }

    &__to-cart {
      margin-left: 25px;
    }

    &__buttons {
      right: 28px;
    }
  }
}

@include respond-down('medium') {
  .model-page-type-size-item {

  }
}

@include respond-down('small') {
  .model-page-type-size-item {
    &__link {
      padding: 16px 18px 20px;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 132px;
    }

    &__prices {
      margin-top: 20px;
    }

    &__available {
      margin-top: 8px;
    }

    &__buttons {
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;
      top: 16px;
      right: 18px;
    }

    &__kit-price {
      margin-left: 12px;
    }

    &__to-cart {
      margin-top: 37px;
    }
  }
}