.guarantee {
  &__bottom-element-icon {
    background: rgba(255, 89, 36, 0.15);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__element {
    display: flex;
  }

  &__element-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__element-icon {
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__element {
    position: relative;

    &:not(:last-child) {
      &:before {
        position: absolute;
        content: '';
        background: var(--grey-stroke-color);
      }
    }
  }

  &__term-number {
    font-family: var(--font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--green-color);
    width: 34px;
    height: 34px;
    border-radius: 17px;
    border: 2px solid var(--green-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -17px;
    left: 0;
    background: white;
  }

  &__bottom-element {
    background: white;
    position: relative;
  }

  &__picture {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: absolute;
    bottom: -9px;
  }

  &__image {
    width: 100%;
    //height: 100%;
    height: auto;
    object-fit: contain;
  }

  &__bottom-list {
    display: flex;
  }

  &__elements_list {
    display: flex;
  }

  &__terms {
    &_wrapper {
      grid-row-gap: 0px;
    }

    &_right {
      display: flex;
    }
  }

  &__term {
    display: flex;
    border-top: 2px solid var(--grey-stroke-color);
    position: relative;

    &:nth-child(3) {
      &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        height: 2px;
        background: var(--green-color);
      }
    }

    &:nth-child(4) {
      border-top: 2px solid var(--green-color);
    }
  }

  &__top {
    background: white;
  }

  &__right-terms-wrap {
    display: flex;
    flex-direction: column;
  }

  &__file-wrap {
    display: flex;
  }

  &__file-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey-stroke-color);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: -6px;
      bottom: -6px;
      width: 10px;
      height: 10px;
      border-left: 1px solid var(--grey-stroke-color);
      transform: rotate(45deg);
      background: white;
    }
  }

  &__file-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 49px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__changeable-wrap {
    display: flex;
  }
}

@include respond-up("large") {
  .guarantee {
    &__element-icon {
      width: 72px;
      height: 72px;
    }

    &__element {
      &:not(:last-child) {
        &:before {
          height: 1px;
          width: calc(var(--grid-column3) - 72px);
          top: 36px;
          right: calc(0px - var(--grid-gap) / 2);
        }
      }
    }

    &__elements_list-wrapper {
      margin-top: 34px;
    }

    &__changeable-title {
      margin-top: 12px;
    }

    &__picture {
      max-width: 473px;
      width: var(--grid-column5);
      height: calc(382 / 473 * var(--grid-column5));
      right: calc(var(--grid-spacer-and-indent));
    }

    &__bottom-element-description {
      margin-top: 55px;
    }

    &__bottom-list {
      margin-top: 40px;
      flex-direction: row;
    }

    &__bottom-element {
      flex: 0 0 33%;
      padding: 40px 30px;
    }

    &__bottom-title {
      width: var(--grid-column5);
    }

    &__bottom-description {
      width: var(--grid-column6);
    }

    &__bottom-title-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__element {
      flex: 0 0 var(--grid-column3);
    }

    &__element-title {
      margin-top: 30px;
    }

    &__elements {
      margin-top: 115px;
    }

    &__changeable {
      padding-top: 15px;
    }

    &__term {
      padding-top: 30px;
      padding-bottom: 40px;

      &:nth-child(3) {
        &:before {
          width: var(--grid-column4);
        }
      }
    }

    &__term-description {
      flex: 0 1 var(--grid-column4);
    }

    &__term-title {
      flex: 0 1 var(--grid-column3);
    }

    &__terms {
      padding-top: 40px;

      &_wrapper {
        margin-top: 35px;
        border-top: 1px solid var(--grey-stroke-color);
      }

      &_left {
        grid-column: 1/8;
        position: relative;

        &:before {
          position: absolute;
          content: '';
          height: 100%;
          top: 0;
          bottom: 0;
          right: calc(0px - var(--grid-gap) - var(--grid-column1) / 2);
          width: 1px;
          background: var(--grey-stroke-color);
        }
      }

      &_right {
        grid-column: 9/13;
        flex-direction: column;
      }
    }

    &__top {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    &__bottom {
      padding-top: 100px;
      padding-bottom: 140px;
    }

    &__text {
      grid-column: 1/6;
    }

    &__description {
      padding-top: 25px;
    }

    &__file-wrap {
      flex-direction: column;
      margin-top: 46px;
    }

    &__file-link {
      margin-top: 27px;
    }

    &__changeable-wrap {
      flex-direction: column;
    }
  }
}

@include respond-up("medium") {
  .guarantee {
    &__bottom-element-icon {
      height: 52px;
      width: 82px;
      right: 0;
      top: 25px;
      border-radius: 26px 0 0 26px;
      padding: 15px;
    }

    &__element {
      flex-direction: column;
    }

    &__elements_list {
      flex-direction: row;
      justify-content: space-between;
    }

    &__element-description {
      padding-top: 15px;
    }

    &__term {
      justify-content: space-between;
    }

    &__file-link {
      padding: 18px 20px;
    }
  }
}

@include respond("medium") {
  .guarantee {
    &__element-icon {
      width: 72px;
      height: 72px;
    }

    &__element {
      &:not(:last-child) {
        &:before {
          height: 1px;
          width: calc(var(--grid-column2) - 72px - var(--grid-gap));
          top: 36px;
          right: 0;
        }
      }
    }

    &__picture {
      width: 351px;
      height: 283px;
      left: 397px;
    }

    &__bottom-element-description {
      margin-top: 45px;
    }

    &__bottom-list {
      margin-top: 35px;
      flex-direction: column;
    }

    &__bottom-element {
      padding: 40px 30px 30px;
    }

    &__elements_list {
      width: var(--grid-column8);
    }

    &__elements_list-wrapper {
      margin: 29px calc(0px - var(--grid-spacer)) 0;
      padding: 0 var(--grid-spacer);
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__element {
      flex: 0 0 var(--grid-column2);
    }

    &__element-title {
      margin-top: 25px;
    }

    &__term-description {
      flex: 0 1 calc(var(--grid-column3) + var(--grid-gap) + var(--grid-column1) / 2);
    }

    &__term-title {
      flex: 0 1 calc(var(--grid-column2) + var(--grid-gap));
    }

    &__terms {
      &_wrapper {
        margin-top: 45px;
      }

      &_left,
      &_right {
        grid-column: 1/7;
      }

      &_right {
        position: relative;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__changeable-icon {
      position: absolute;
      left: 0;
      top: -46px;
    }

    &__text {
      grid-column: 1/4;
    }

    &__elements {
      margin-top: 90px;
    }

    &__term {
      padding-top: 28px;
      padding-bottom: 46px;

      &:nth-child(3) {
        &:before {
          width: calc(var(--grid-column3) + var(--grid-gap));
        }
      }
    }

    &__top {
      padding-top: 70px;
      padding-bottom: 70px;
      overflow: hidden;
    }

    &__bottom {
      padding-top: 70px;
      padding-bottom: 90px;
    }

    &__file-wrap {
      margin-top: 55px;
    }

    &__file-text {
      width: calc(var(--grid-column2) + var(--grid-gap) + 33px);
      margin-right: 24px;
    }

    &__file-link {
      width: calc(var(--grid-column3) + var(--grid-gap) + 22px);
    }

    &__changeable-title {
      width: calc(var(--grid-column2) + var(--grid-gap));
      margin-right: 57px;
    }

    &__changeable {
      width: calc(var(--grid-column4) - 57px);
    }
  }
}

@include respond-down("medium") {
  .guarantee {
    &__bottom-element {
      &:not(:first-child) {
        border-top: 1px solid var(--grey-stroke-color);
      }
    }

    &__bottom-description {
      padding-top: 20px;
    }

    &__description {
      padding-top: 20px;
    }

    &__terms {
      &_right {
        margin-top: 40px;
      }
    }
  }
}

@include respond-down("small") {
  .guarantee {
    &__bottom-element-icon {
      height: 52px;
      width: 52px;
      right: 20px;
      top: 20px;
      border-radius: 26px;
      padding: 14px;
    }

    &__element-icon {
      width: 56px;
      height: 56px;
    }

    &__element-text-wrapper {
      flex: 0 0 calc(var(--grid-column4) - 2 * var(--grid-gap) - 56px);
    }

    &__element {
      flex-direction: row;
      justify-content: space-between;

      &:not(:last-child) {
        &:before {
          width: 1px;
          height: calc(100% - 46px);
          top: 66px;
          left: 28px;
          bottom: -20px;
        }
      }
    }

    &__elements_list-wrapper {
      margin-top: 20px;
    }

    &__changeable-title {
      margin-top: 12px;
    }

    &__picture {
      width: 302px;
      height: 244px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__bottom-element-description {
      margin-top: 45px;
    }

    &__bottom-list {
      margin-top: 20px;
      flex-direction: column;
    }

    &__bottom-element {
      padding: 36px 20px 30px;
    }

    &__element {
      width: 100%;

      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__elements_list {
      flex-direction: column;
      margin-top: 20px;
    }

    &__element-description {
      padding-top: 12px;
    }

    &__term-description {
      padding-top: 15px;
    }

    &__text {
      grid-column: 1/5;
    }

    &__elements {
      margin-top: 50px;
    }

    &__changeable {
      padding-top: 15px;
    }

    &__term {
      padding-top: 28px;
      padding-bottom: 36px;

      &:nth-child(3) {
        &:before {
          width: calc(var(--grid-column2) + var(--grid-gap));
        }
      }
    }

    &__terms {
      &_wrapper {
        margin-top: 35px;
      }

      &_left,
      &_right {
        grid-column: 1/5;
      }

      &_right {
        flex-direction: column;
      }
    }

    &__term {
      flex-direction: column;
    }

    &__top {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    &__bottom {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    &__file-wrap {
      margin-top: 35px;
      flex-direction: column;
    }

    &__file-link {
      margin-top: 20px;
      padding: 16px 20px 16px 15px;
    }

    &__changeable-wrap {
      flex-direction: column;
    }
  }
}