.catalog-page {
  &__pagination-data {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 25;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--white-color-60);
      opacity: 0;
      transition: opacity .4s var(--default-transition-function);
      pointer-events: none;
    }

    &._blur {
      &::before {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__list {
    display: grid;
    grid-gap: 14px;

    &_bottom {
      margin-top: 14px;
    }
  }

  &__main {
  }

  &__content-wrap {
    display: flex;
  }

  &__content {
    position: relative;
    width: 100%;
  }

  &__total-wrap {
    display: flex;
    align-items: center;
  }

  &__count {
    color: var(--black-color);
  }

  &__total-title,
  &__total {
    color: var(--grey-text-color);
  }

  &__content-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__no-result {
    background-color: #fff;
    display: flex;

    &_car {
      justify-content: center;
    }
  }

  &__no-result-icon {
    display: flex;
  }

  &__no-result-title {
    display: flex;
    align-items: center;
    color: var(--black-color);
    margin-left: 18px;
  }

  &__no-result-link {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;

    &::before {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      border-bottom: 1px solid var(--black-color);
      transform-origin: left center;
      transform: scaleX(1);
      transition: transform .3s;
    }
  }
}

@include respond-up('large') {
  .catalog-page {
    &__inner {
      padding-bottom: 150px;
    }

    &__list {
      grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
      //grid-template-columns: repeat(4, 1fr);
    }

    &__main {
      margin-top: 30px;
    }

    &__chosen {
      margin-bottom: 50px;
    }

    &__content {
      flex: 0 1 100%;
    }

    &__list-wrapper {
      margin-top: 30px;
      &_model{
        margin: 0;
      }
    }

    &__no-result {
      padding: 18px 45px;
    }

    &__pagination-wrap {
      margin-top: 40px;
    }

    &__no-result-link {
      &:hover {
        &::before {
          transform-origin: center right;
          transform: scaleX(0);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .catalog-page {
    &__no-result-form {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .catalog-page {
    &__inner {
      padding-bottom: 90px;
    }

    &__list {
      grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    }

    &__main {
      margin-top: 20px;
    }

    &__list-wrapper {
      margin-top: 35px;
      &_model{
        margin: 0;
      }
    }

    &__no-result {
      padding: 18px 30px;
    }

    &__pagination-wrap {
      margin-top: 35px;
    }
  }
}

@include respond-down('medium') {
  .catalog-page {
    &__total-wrap {
      display: none;
    }

    &__chosen {
      margin-top: 16px;
    }

    &_moto {
      .catalog-filter-top {
        &__heading {
          display: none;
        }

        &__mobile-main {
          border-top: none;
        }
      }
    }

    &__sort-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .catalog-page {
    &__inner {
      padding-bottom: 50px;
    }

    &__list {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }

    &__main {
      margin-top: 16px;
    }

    &__list-wrapper {
      margin-top: 22px;
      &_model {
        margin: 0;
      }
    }

    &__no-result {
      padding: 18px 20px;
    }

    &__no-result-form {
      margin-top: 20px;
    }

    &__pagination-wrap {
      margin-top: 30px;
    }

    &__no-result-title {
      flex-wrap: wrap;
    }
  }
}

// На лучший вариант времени нет - smells like shit
// Жесткое разрешение для микро баннера и формы в каталоге
// Если товаров меньше чем 18 - формы не показываем

// По пять в колонку
@media (min-width: 1658px) {
  .catalog-page{
    &__item{
      &_form{
        grid-column: 1 / 6;
        grid-row: 5 / 6;
      }
      &_banner{
        grid-row: 2 / 3;
        grid-column: 5 / 6;
      }
    }
  }
}
// По четыре в колонку
@media (max-width: 1658px) {
  .catalog-page{
    &__item{
      &_form{
        grid-row: 6 / 7;
        grid-column: 1 / 5;
      }
      &_banner{
        grid-row: 3 / 4;
        grid-column: 4 / 5;
      }
    }
  }
}
// По три в колонку
@media (max-width: 1438px) {
  .catalog-page{
    &__item{
      &_form{
        grid-column: 1 / 4;
        grid-row: 8 / 9;
      }
      &_banner{
        grid-row: 4 / 5;
        grid-column: 3 / 4;
      }
    }
  }
}

// По четыре в колонку
@media (max-width: 1199px) {
  .catalog-page{
    &__item{
      &_form{
        grid-row: 6 / 7;
        grid-column: 1 / 5;
      }
      &_banner{
        grid-row: 3 / 4;
        grid-column: 4 / 5;
      }
    }
  }
}
// По три в колонку
@media (max-width: 1005px) {
  .catalog-page{
    &__item{
      &_form{
        grid-column: 1 / 4;
        grid-row: 8 / 9;
      }
      &_banner{
        grid-row: 4 / 5;
        grid-column: 3 / 4;
      }
    }
  }
}
// По четыре в колонку
@media (max-width: 767px) {
  .catalog-page{
    &__item{
      &_form{
        grid-row: 6 / 7;
        grid-column: 1 / 5;
      }
      &_banner{
        grid-row: 3 / 4;
        grid-column: 4 / 5;
      }
    }
  }
}

// По три в колонку
@media (max-width: 719px) {
  .catalog-page{
    &__item{
      &_form{
        grid-column: 1 / 4;
        grid-row: 8 / 9;
      }
      &_banner{
        grid-row: 4 / 5;
        grid-column: 3 / 4;
      }
    }
  }
}

// По две в колонку
@media (max-width: 545px) {
  .catalog-page{
    &__item{
      &_form{
        grid-row: 11 / 12;
        grid-column: 1 / 3;
      }
      &_banner{
        grid-row: 6 / 7;
        grid-column: 2 / 2;
      }
    }
  }
}

// По одной в колонку
@media (max-width: 371px) {
  .catalog-page{
    &__item{
      &_form{
        grid-row: 21 / 22;
        grid-column: 1 / 2;
      }

      &_banner{
        grid-row: 12 / 13;
        grid-column: 1 / 2;
      }
    }
  }
}