.tabs {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  &__link {
    position: relative;
  }

  li {
    a {
      display: block;
    }
  }

  &_choice {
    font-size: var(--tabs-font-size-first);
    background: var(--color-background);
    margin: -3px;

    .tabs {
      &__item {
        padding: 3px;

        &:not(:first-child) {
          //margin-left: 6px;
        }

        &._active {
          .tabs {
            &__link {
              background: var(--black-color);
              color: var(--white-color);
            }
          }
        }
      }

      &__link {
        padding: 8px 14px;
        display: flex;
        align-items: center;
        background: var(--grey-light-color);
        color: var(--black-color);
        transition: background-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);
        white-space: nowrap;
      }
    }
  }

  &_choice-orange {
    margin: -5px;

    .tabs {
      &__item {
        margin: 5px;

        &._active {
          .tabs {
            &__link {
              background: var(--primary-color);
              color: var(--white-color);
            }
          }
        }
      }

      &__link {
        padding: 6px 10px;
        display: flex;
        align-items: center;
        background: var(--grey-light-color);
        color: var(--black-color);
        transition: background-color, color;
        transition-duration: var(--default-transition-timing);
        transition-timing-function: var(--default-transition-function);
        white-space: nowrap;
      }
    }
  }

  &_product-card {
    .tabs {
      &__link {
        display: flex;
        align-items: center;
        background: var(--white-color);
        color: var(--black-color);
        transition: background-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: var(--t);
          transition: background-color var(--default-transition-timing) var(--default-transition-function);
        }
      }

      &__item {
        &:not(:first-child) {
          margin-left: 1px;
        }

        &._active {
          .tabs {
            &__link {
              color: var(--black-color);
              position: relative;

              &:before {
                background-color: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }

  &_contacts {
    .tabs {
      width: 100%;

      &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white-color);
        color: var(--grey-text-color);
        transition: background-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);

        &:before {
          content: '';
          position: absolute;
          z-index: 10;
          height: 2px;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: var(--t);
          transition: background-color var(--default-transition-timing) var(--default-transition-function);
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 5;
          bottom: 0;
          left: 0;
          width: 100%;
          border-bottom: 1px solid var(--grey-stroke-color);
        }
      }

      &__item {
        flex: 0 0 50%;

        &:not(:first-child) {
          //margin-left: 1px;
        }

        &._active {
          .tabs {
            &__link {
              color: var(--primary-color);
              position: relative;

              &:before {
                background-color: var(--primary-color);
              }
            }
          }
        }
      }
    }
  }

  &_delivery {
    display: flex;

    .tabs {
      &__link {
        display: flex;
        align-items: center;
        background: var(--white-color);
        transition: background-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);

        &:before {
          content: '';
          position: absolute;
          height: 8px;
          width: 8px;
          border-radius: 4px;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-color: var(--t);
          transition: background-color var(--default-transition-timing) var(--default-transition-function);
        }

        span {
          color: var(--black-color);
          transition: transform var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);
        }
      }

      &__item {
        &._active {
          .tabs {
            &__link {
              background-color: var(--primary-color);

              span {
                color: var(--white-color) !important;
              }
            }
          }
        }
      }
    }
  }

  &_lk {
    .tabs {
      &__link {
        padding: 8px 14px;
        text-align: center;
        border-radius: 50px;
        background: var(--grey-light-color);
        transition: background-color var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);

        span {
          color: var(--black-color);
          transition: transform var(--default-transition-timing) var(--default-transition-function), color var(--default-transition-timing) var(--default-transition-function);
        }
      }

      &__item {
        &:not(:first-child) {
          margin-left: 6px;
        }

        &._active {
          .tabs {
            &__link {
              background-color: var(--primary-color);
              position: relative;

              span {
                color: var(--white-color) !important;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up("large") {
  .tabs {
    &_choice {
      .tabs {
        &__item {
          &:hover {
            .tabs {
              &__link {
                background: var(--black-color);
                color: var(--white-color);
              }
            }
          }
        }
      }
    }

    &_choice-orange {
      .tabs {
        &__item {
          &:hover {
            .tabs {
              &__link {
                background: var(--primary-color);
                color: var(--white-color);
              }
            }
          }
        }
      }
    }

    &_product-card {
      .tabs {
        &__link {
          padding: 17px 30px;
        }

        &__item {
          &:hover {
            .tabs {
              &__link {
                color: var(--primary-color);
              }
            }
          }
        }
      }
    }

    &_contacts {
      .tabs {
        &__link {
          padding: 18px;
        }

        &__item {
          &:hover {
            .tabs {
              &__link {
                color: var(--primary-color);
              }
            }
          }
        }
      }
    }

    &_delivery {
      flex-direction: column;

      .tabs {
        &__link {
          padding: 17px 20px;
        }

        &__item {
          &:not(:first-child) {
            margin-top: 1px;
          }

          &:hover {
            .tabs {
              &__link {
                span {
                  color: var(--primary-color);
                }
              }
            }
          }

          &._active {
            .tabs {
              &__link {
                position: relative;

                span {
                  transform: translateX(20px);
                }

                &:before {
                  background-color: var(--white-color);
                }
              }
            }
          }
        }
      }
    }

    &_lk {
      .tabs {
        &__link {
          min-width: 121px;
        }

        &__item {
          &:hover {
            .tabs {
              &__link {
                span {
                  color: var(--primary-color);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up("medium") {
  .tabs {
    &_choice {
      .tabs {
        &__link {
          font-size: 15px;
        }
      }
    }
  }
}

@include respond("medium") {
  .tabs {
    &_delivery {
      .tabs {
        &__link {
          padding: 13px 20px;
        }
      }
    }
  }
}

@include respond-down("medium") {
  .tabs {
    &_product-card {
      .tabs {
        &__link {
          padding: 14px 20px;
        }
      }
    }

    &_contacts {
      .tabs {
        &__link {
          padding: 15px;
        }
      }
    }

    &_delivery {
      .tabs {
        &__link {
          span {
            white-space: nowrap;
          }
        }

        &__item {
          //flex: 0 0 calc(50% - 0.5px);

          &:not(:first-child) {
            margin-left: 1px;
          }
        }
      }
    }

    &_lk {
      .tabs {
        &__link {
          min-width: 115px;
        }
      }
    }
  }
}

@include respond-down("small") {
  .tabs {
    &_choice {
      .tabs {
        &__link {
          font-size: 14px;
        }
      }
    }

    &_choice-orange {
      margin: -4px;

      .tabs {
        &__item {
          margin: 4px;
        }
      }
    }

    &_delivery {
      .tabs {
        &__link {
          padding: 14px 20px;
        }
      }
    }
  }
}
