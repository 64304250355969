.modification-selection{
  background: #FFFFFF;

  &__title-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title{
    display: block;
  }

  &__image-container{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__section-name{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    margin-bottom: 12px;
    display: block;
  }

  &__section-list{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 40px;
  }

  &__modification{
    display: flex;
    flex-direction: column;
    background: var(--grey-light-color);
    border: 1px solid rgba(255, 89, 36, 0.15);
  }

  &__modification-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__modification-type{
    padding: 2px 10px;
    background: var(--primary-color);
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 133%;
    border-radius: 4px;
  }

  &__horse-power{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: var(--black-color);
  }

  &__modification-name{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    text-align: center;
    color: var(--black-color);
  }
}

@include respond-up('large') {
  .modification-selection{
    margin-bottom: 120px;
    padding-bottom: 52px;

    &__title-wrap, &__subtitle{
      margin-left: 32px;
    }

    &__wrap{
      padding-top: 45px;
    }

    &__image-container{
      margin-right: 30px;
    }

    &__list{
      padding-left: 30px;
    }

    &__modification{
      padding: 12px;
      transition: border .2s;
      min-width: 218px;
      width: fit-content;
      &:hover{
        border: 1px solid var(--primary-color);
      }
    }
  }
}

@include respond-up('medium') {
  .modification-selection{
    &__title{
    }

    &__image-container{
      width: 100px;
      height: 72px;
    }

    &__subtitle{
      margin-bottom: 28px;
    }

    &__list{
      padding-top: 30px;
    }

    &__modification-head{
      margin-bottom: 14px;
    }
  }
}

@include respond('medium') {
  .modification-selection{
    margin-bottom: 70px;

    &__title-wrap, &__subtitle{
      margin-left: 20px;
    }

    &__wrap{
      padding-top: 35px;
    }

    &__image-container{
      margin-right: 25px;
    }

    &__list{
      padding-left: 20px;
    }
  }
}

@include respond-down('medium') {
  .modification-selection{
    padding-bottom: 30px;
    &__modification{
      padding: 12px 10px;
      min-width: 147px;
    }
  }
}

@include respond-down('small') {
  .modification-selection{
    margin-bottom: 50px;

    &__title-wrap, &__subtitle{
      flex-direction: column;
      margin-left: 15px;
      align-items: flex-start;
    }

    &__wrap{
      padding-top: 30px;
    }

    &__image-container{
      width: 75px;
      height: 54px;
      margin-bottom: 8px;
    }

    &__subtitle{
      margin-bottom: 21px;
    }

    &__list{
      padding-top: 20px;
      padding-left: 15px;
    }

    &__modification-head{
      margin-bottom: 12px;
    }
  }
}