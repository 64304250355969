.header-bottom {
  background-color: var(--white-color);
  height: 66px;
  border-bottom: 1px solid var(--grey-stroke-color);

  &__container{
    height: 100%;
  }

  &__user-item-icon{
    position: relative;
    display: flex;
  }

  &__user-item-badge{
    position: absolute;
    width: 17px;
    height: 16px;
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo{
    display: flex;
  }

  &__favorites{
    svg path {
      &:first-child {
        fill: var(--black-color);
      }

      &:last-child {
        display: none;
      }
    }
  }

  &__search-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    border: 1px solid var(--grey-stroke-color);
    padding-left: 12px;
    padding-right: 12px;

    .header-bottom{
      &__user-item-text{
        transition: color var(--default-transition-timing) var(--default-transition-function);
        line-height: 135%;
        letter-spacing: -0.02em;
        color: var(--grey-text-color);
      }

      &__user-item-icon{
        svg{
          path{
            transition: stroke var(--default-transition-timing) var(--default-transition-function);
            stroke: var(--primary-color);
          }
          circle{
            transition: stroke var(--default-transition-timing) var(--default-transition-function);
            stroke: var(--primary-color);
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .header-bottom {
    &__container, &__menu, &__menu-link, &__user-items, &__user-item{
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__logo{
      display: none;
      margin-right: 28px;
      svg{
        width: 176px;
        height: 51px;
      }
    }

    &__menu-link{
      padding: 0 12px;
    }

    &__user-items{
      justify-content: flex-end;
      display: flex;
      flex: 1 1 auto;
    }

    &__user-item{
      flex-direction: column;
      justify-content: center;
      padding: 0 12px;
      &:hover{
        .header-bottom{
          &__user-item-icon{
            svg{
              path{
                fill: var(--primary-color);
              }
            }
          }

          &__user-item-text{
            color: var(--primary-color);
          }
        }
      }
    }

    &__user-item-icon{
      svg{
        path{
          transition: fill var(--default-transition-timing) var(--default-transition-function);
        }
      }
    }

    &__user-item-badge{
      right: -9px;
      top: -7px;
    }

    &__user-item-text{
      transition: color var(--default-transition-timing) var(--default-transition-function);
    }

    &__menu-link{
      position: relative;
      color: var(--black-color);
      transition: color var(--default-transition-timing) var(--default-transition-function);
      &:hover{
        color: var(--primary-color);
      }

      &_with-child{
        cursor: pointer;
        &:hover{
          .header-bottom{
            &__submenu{
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }

    &__submenu{
      position: absolute;
      top: 60px;
      left: -80px;
      pointer-events: none;
      opacity: 0;
      transition: opacity var(--default-transition-timing) var(--default-transition-function);
      cursor: default;
    }

    &__submenu-wrap{
      position: relative;
      background-color: var(--white-color);
      width: 232px;
      filter: drop-shadow(0px 7px 30px rgba(107, 104, 96, 0.25));
      padding: 20px;
      display: flex;
      flex-direction: column;
      &:before{
        content: '';
        position: absolute;
        top: -5px;
        right: 110px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 6px 7px;
        border-color: transparent transparent #fff transparent;
      }
    }

    &__submenu-item-wrap{
      padding: 4px 0;
      &:last-child{
        padding-bottom: 0;
      }

      &:first-child{
        padding-top: 0;
      }
    }

    &__submenu-link{
      color: var(--grey-text-color);
      transition: color var(--default-transition-timing) var(--default-transition-function);
      padding: 4px 0;

      &:hover{
        color: var(--primary-color)
      }
    }

    &__mobile-menu-link{
      display: none;
    }

    &__contact{
      display: none;
    }

    &__cart-price{
      display: none;
    }

    &__search-button {
      width: clamp(245px, calc(245/1500 * 100vw), 400px);
      margin-right: 13px;
    }
  }
}

@include respond-up('medium') {
  .header-bottom {
    &__search-button {
      padding-top: 9px;
      padding-bottom: 9px;

      .header-bottom {
        &__user-item-text {
          font-size: 14px;
        }
      }
    }
  }
}

@include respond('medium') {
  .header-bottom {
    height: 74px;

    &__mobile-menu-link{
      margin-right: 30px;
    }

    &__logo{
      margin-right: 25px;

      &_mob {
        display: none;
      }

      svg{
        width: 159px;
        height: 46px;
      }
    }

    &__recall-link{
      padding-left: 10px;
    }

    &__user-item{
      display: flex;
      align-items: center;
    }

    &__cart-price{
      margin-left: 11px;
    }

    &__contact {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }

    &__favorites{
      padding-right: 16px;
    }

    &__search-button{
      margin-right: 26px;
    }

  }
}

@include respond-down('medium') {
  .header-bottom {
    z-index: 100;

    &__recall, &__delimiter, &__phone{
      display: none;
    }

    &__recall_mobile-icon{
      width: 28px;
      height: 28px;
      background-color: var(--primary-color-15);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background var(--default-transition-timing) var(--default-transition-function);

      svg path {
        transition: fill var(--default-transition-timing) var(--default-transition-function);
      }
    }

    &__phone-link{
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 10px;
      margin: 0 -10px;
      transition: opacity var(--default-transition-timing) var(--default-transition-function);

      &._opened {
        opacity: 1;
        pointer-events: auto;
        .header-bottom {
          &__recall_mobile-icon {
            background: var(--primary-color);

            svg path {
              fill: #fff;
            }
          }
        }
      }
    }

    &__container{
      display: flex;
      align-items: center;
    }

    &__user-item{
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__menu{
      display: none;
    }

    &__lk-button{
      display: none;
    }

    &__user-item-text{
      display: none;
    }

    &__mobile-menu-link{
      display: flex;
      align-items: center;
      position: relative;

      &._menu-opened {
        .header-bottom {
          &__hamburger-line{
            &_top{
              transform: translateY(6px) rotate(45deg);
            }

            &_middle{
              opacity: 0;
            }

            &_bottom{
              transform: translateY(-6px) rotate(-45deg);
            }
          }
        }
      }
    }

    &__hamburger{
      height: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__hamburger-line{
      border: 1px solid var(--black-color);
      width: 24px;
      &_top, &_bottom{
        transition: {
          property: transform;
          duration: var(--default-transition-timing);
          timing-function: var(--default-transition-function);
        };
      }

      &_middle{
        transition: opacity var(--default-transition-timing) var(--default-transition-function);
      }
    }

    &__contact{
      display: none;
    }

    &__user-items{
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }

    //&__cart{
    //  padding-left: 13px;
    //}

    &__user-item-badge{
      right: -6px;
      top: -7px;
    }

    &__search-button {
      height: fit-content;
      flex: auto;
      .header-bottom {
        &__user-item-text {
          display: flex;
        }
      }
    }
  }
}

@include respond-down('small') {
  .header-bottom {
    height: 58px;

    &__logo{
      margin-right: 22px;

      &_tab {
        display: none;
      }

      svg{
        width: 26px;
        height: 32px;
      }
    }

    &__mobile-menu-link{
      margin-right: 20px;
    }

    &__phone-link{
      padding: 0;
    }

    &__favorites{
      padding-right: 12px;
    }

    &__search-button{
      padding-right: 14px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-right: 11px;

      .header-bottom {
        &__user-item-text {
          font-size: 13px;
        }
      }
    }

    &__cart-price{
      display: none;
    }

    &__recall{
      &_mobile{
        margin-right: 40px;
      }
    }
  }
}

@media(max-width: 374px){
  .header-bottom__phone-link{
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1370px) {
  .header-bottom {
    &__search-button {
      width: calc(160/1200 * 100vw);
      margin-right: 10px;
    }

    &__user-item {
      padding: 0 10px;
    }

    &__menu-link {
      padding: 0 8px;
      &.text__small {
        font-size: 14px;
      }
    }

    &__main-menu {
      .header-main-menu {
        &__button {
          margin-right: 18px;
        }
      }
    }
  }
}