.service-card {
  display: flex;
  flex-direction: column;
  background: linear-gradient(129.17deg, #727C93 40.48%, #515974 87.91%, #535C76 114.15%);
  height: 100%;
  width: 100%;

  &__icon-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    color: white;
    align-self: flex-start;
    margin-top: auto;
  }

  &__arrow-wrap {
    width: 8px;
    height: 14px;
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .service-card {
    padding: 30px 30px 35px 40px;

    &:hover {
      .service-card {
        &__icon {
          background-color: var(--white-color);

          svg {
            path {
              stroke: var(--black-color);
            }
          }
        }
      }
    }

    &__picture {
      width: calc((232 / 1500) * 100vw);
      height: calc((196 / 1500) * 100vw);
      max-width: 283px;
      max-height: 240px;
    }

    &__image-wrap {
      margin: 8px 0 24px;
    }
  }
}

@include respond('medium') {
  .service-card {
    padding: 22px 22px 25px 25px;

    &__picture {
      width: calc((218 / 768) * 100vw);
      height: calc((184 / 768) * 100vw);
    }

    &__image-wrap {
      margin: 17px 0 31px;
    }
  }
}

@include respond-down('small') {
  .service-card {
    padding: 20px;

    &__picture {
      width: calc((218 / 375) * 100vw);
      height: calc((184 / 375) * 100vw);
    }

    &__image-wrap {
      margin-bottom: 6px;
    }
  }
}