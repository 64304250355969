.filter-openable {
  border-bottom: 1px solid var(--grey-stroke-color);

  &__link {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 15px 0;
  }

  &__link-icon {
    display: flex;
    margin-left: auto;
    padding-top: 7px;

    svg {
      transition: transform .3s;
    }
  }

  &__content {
    height: 0;
    overflow: hidden;
    transition: height .5s;
  }

  &__help {
    display: flex;
    padding: 4px 6px 0;
    //margin: -6px 0 -5px;

    &:hover {
      svg circle {
        fill: var(--primary-color);
      }

      .filter-openable {
        &__help-description,
        &__help-icon::before {
          opacity: 1;
        }
      }
    }
  }

  &__help-icon {
    position: relative;
    display: flex;

    svg circle {
      transition: fill .3s;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 50;
      bottom: calc(100% + 5px);
      left: 2px;
      background-color: #fff;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
    }
  }

  &__help-description {
    position: absolute;
    z-index: 25;
    bottom: 43px;
    left: 0;
    right: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));
    padding: 14px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
  }

  &._opened {
    .filter-openable {
      &__link-icon {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}