.checkbox-block-list-openable {
  &__value {
    display: flex;
    background-color: #fff;
  }

  input {
    display: none;

    &:checked + .checkbox-block-list-openable__item-label {
      background-color: var(--black-color);
      color: #fff;
    }
  }

  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--grey-stroke-color);
    border-bottom: 1px solid var(--grey-stroke-color);
  }
}

@include respond-up('large') {
  .checkbox-block-list-openable {
    &__values-wrap {
      padding-bottom: 20px;
    }

    &__values {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border-left: 1px solid var(--grey-stroke-color);
    }

    &__value {
      &:nth-child(-n+4) {
        label {
          border-top: 1px solid var(--grey-stroke-color);
        }
      }
    }

    label {
      padding: 6px 8px;
    }
  }
}

@include respond-up('medium') {
  .checkbox-block-list-openable {

  }
}

@include respond('medium') {
  .checkbox-block-list-openable {

  }
}

@include respond-down('medium') {
  .checkbox-block-list-openable {
    &__values {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@include respond-down('small') {
  .checkbox-block-list-openable {

  }
}