.catalog-base-categories {
  display: grid;
  grid-gap: var(--grid-gap);
}

@include respond-up('large') {
  .catalog-base-categories {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include respond-up('medium') {
  .catalog-base-categories {
    padding-top: 50px;
  }
}

@include respond('medium') {
  .catalog-base-categories {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include respond-down('medium') {
  .catalog-base-categories {

  }
}

@include respond-down('small') {
  .catalog-base-categories {
    grid-template-columns: 1fr;
    padding-top: 40px;
  }
}