.article-card {
  display: flex;

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    display: block;
  }

  &__description {
    color: var(--grey-text-color);
  }

  &__link {
    display: flex;
    overflow: hidden;
  }

  &__link-inner {
    display: flex;
    align-items: center;
  }

  &__link-title {
    padding-top: 1px;
  }

  &__date {
    display: block;
    color: var(--grey-text-color);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      background-color: var(--primary-color);
      border-radius: 100%;
    }
  }

  &__icon {
    display: flex;
    height: max-content;

    svg path {
      fill: var(--black-color);
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__main {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  &__link-title {
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .article-card {
    &__image-wrap {
      flex: 0 0 var(--grid-column5);
    }

    &__main {
      padding: 35px 40px;
    }

    &__bottom {
      width: var(--grid-column4);
    }

    &__description {
      margin-top: 20px;
    }

    &__image {
      transition: transform .5s;
    }

    &:hover {
      .article-card {
        &__link-inner {
          animation: text-out-in .5s forwards;
        }

        &__image {
          transform: scale(1.05);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .article-card {
    &__main {
      flex: 0 1 100%;
    }

    &__date {
      padding-left: 17px;

      &::before {
        width: 9px;
        height: 9px;
      }
    }

    &__bottom {
      margin-top: auto;
    }

    &__description {
      margin-bottom: 30px;
    }

    &__link-title {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .article-card {
    &__image-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__main {
      padding: 14px 17px;
    }
  }
}

@include respond-down('medium') {
  .article-card {
    &__description {
      margin-top: 12px;
    }
  }
}

@include respond-down('small') {
  .article-card {
    flex-direction: column;

    &__image-wrap {
      width: 100%;
      height: calc((187 / 375) * 100vw);
    }

    &__main {
      padding: 20px 18px;
    }

    &__date {
      padding-left: 14px;

      &::before {
        width: 8px;
        height: 8px;
      }
    }

    &__bottom {
      margin-top: 30px;
    }

    &__link-title {
      font-size: 13px;
    }
  }
}

@keyframes text-out-in {
  from {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -100%, 0);
  }
  51% {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}