
.admin-services {
  &__list {
    display: flex;
    margin: 0 -9px;
  }

  &__item {
    padding: 0 9px;

    &._active {
      .admin-services {
        &__item-link {
          color: #787B83;
          background-color: white;
        }
      }
    }
  }

  &__item-link {
    display: block;
    color: #fff;
    font-weight: 400;
    line-height: 133%;
    font-size: 15px;
    padding: 10px 14px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 3px;
  }
}
