.share {
  display: flex;

  &__title {
    color: var(--grey-text-color);
    margin-right: 22px;
  }

  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
    margin: 0;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  .ya-share2__icon {
    background-size: 18px 18px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .ya-share2__item_service {
    &_vkontakte {
      .ya-share2__icon {
        background-image: url('../images/svg/socials/vk.svg') !important;
      }
    }

    &_odnoklassniki {
      .ya-share2__icon {
        background-image: url('../images/svg/socials/ok.svg') !important;
      }
    }

    &_twitter {
      .ya-share2__icon {
        background-image: url('../images/svg/socials/twitter.svg') !important;
      }
    }
  }
}

@include respond-up('large') {
  .share {
    .ya-share2__icon {
      transition: background-image .3s;
      will-change: background-image;
    }


    .ya-share2__item_service {
      &_vkontakte {
        .ya-share2__link {
          &:hover {
            .ya-share2__icon {
              background-image: url('../images/svg/socials/vk-orange.svg') !important;
            }
          }
        }
      }

      &_odnoklassniki {
        .ya-share2__link {
          &:hover {
            .ya-share2__icon {
              background-image: url('../images/svg/socials/ok-orange.svg') !important;
            }
          }
        }
      }

      &_twitter {
        .ya-share2__link {
          &:hover {
            .ya-share2__icon {
              background-image: url('../images/svg/socials/twitter-orange.svg') !important;
            }
          }
        }
      }
    }
  }
}