.model-page {
  &__top-price {
    grid-column: span 1;
  }

  &__tabs {
    &_mobile {
      display: none;
    }
  }
}

@include respond-up('large') {
  .model-page {
    &__same-models{
      padding-bottom: 120px;
    }
  }
}

@include respond-up('medium') {
  .model-page {
    &__top-price {
      grid-column: 2/3;
      grid-row: 1;
      height: max-content;
    }
  }
}

@include respond('medium') {
  .model-page {
    &__same-models{
      padding-bottom: 90px;
    }
    .product-base {
      &__top-main {
        grid-template-rows: 209px auto auto;
      }

      &__top-slider {
        grid-row: 1/3;
      }

      &__additional {
        grid-row: 2/3;
      }
    }
  }
}

@include respond-down('medium') {
  .model-page{
    &__top-params{
      &.eav-model{
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .model-page {
    &__top-price {
      grid-row: 2;
    }

    &__tabs {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    .product-base {
      &__top-slider {
        grid-row: 1;
      }

      &__additional {
        grid-row: 3;
      }
    }

    &__same-models{
      padding-bottom: 50px;
    }
  }
}