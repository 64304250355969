.cart-order-fast {
  &__title {
    text-align: center;
  }

  &__subtitle.text {
    color: var(--grey-text-color);
    text-align: center;
  }

  &__fields {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__field {

  }

  &__button-wrap {
    display: flex;
  }

  &__politic {
    display: flex;
    font-size: 12px;

    &.text {
      color: var(--grey-text-color);
    }

    .cart-order-fast__politic-link {
      color: var(--black-color);
    }
  }
}

@include respond-up('large') {
  .cart-order-fast {

  }
}

@include respond-up('medium') {
  .cart-order-fast {
    &__subtitle {
      margin: 18px 0 35px;
    }

    &__fields {
      grid-template-columns: 1fr 1fr;
    }

    &__button-wrap {
      margin-top: 35px;
      justify-content: space-between;
    }

    &__politic {
      margin-right: 20px;
    }
  }
}

@include respond('medium') {
  .cart-order-fast {

  }
}

@include respond-down('medium') {
  .cart-order-fast {

  }
}

@include respond-down('small') {
  .cart-order-fast {
    &__subtitle {
      margin: 12px 0 20px;
    }

    &__button-wrap {
      margin-top: 25px;
      flex-direction: column-reverse;
    }

    &__button {
      width: 100%;
    }

    &__politic {
      margin-top: 12px;
    }
  }
}