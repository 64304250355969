.pagination {
  display: flex;
  align-items: center;

  &__button {
    flex: 0 0 auto;
  }

  &__list-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 100%;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--black-color);

    &_first,
    &_page,
    &_last {
      margin: 0 3px;
    }

    &_active {
      .pagination__link {
        &_first,
        &_page,
        &_last {
          background-color: #fff;
        }
      }
    }

    &_dots {
      width: 28px;
      height: 28px;
      margin: 0 3px 0 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    &_previous,
    &_next {
      padding: 13px;
    }

    &_previous {
      svg {
        transform: rotate(180deg);
      }
    }

    &_first,
    &_page,
    &_last {
      border-radius: 100%;
      width: 28px;
      height: 28px;
    }
  }
}

@include respond-up('large') {
  .pagination {
    &__list {
      --pagination-list-margin-left: max(-9vw, -155px);
      margin-left: var(--pagination-list-margin-left);
    }
  }
}

@include respond-up('medium') {
  .pagination {

  }
}

@include respond('medium') {
  .pagination {
    &__list-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond-down('medium') {
  .pagination {

  }
}

@include respond-down('small') {
  .pagination {
    flex-direction: column;

    &__list-wrap {
      margin-top: 25px;
    }

    &__link {
      &_previous,
      &_next {
        padding: 10px;
      }
    }
  }
}