.header-main-menu{

}

@include respond-up('large') {
  .header-main-menu{
    &__button{
      margin-right: 35px;
      cursor: pointer;
      background: var(--primary-color);
      border-radius: 8px;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &._open{
        .header-main-menu{
          &__hamburger-line{
            &_top{
              transform: translateY(4px) rotate(45deg);
            }

            &_middle{
              opacity: 0;
            }

            &_bottom{
              transform: translateY(-6px) rotate(-45deg);
            }
          }
        }
      }
    }

    &__menu-link{
      margin-right: 12px;
    }

    &__name{
      color: #FFFFFF;
    }

    &__drop-menu-wrap{
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s;
      position: absolute;
      left: 0;
      top: 40px;
      padding-top: 14px;
      &._open{
        pointer-events: all;
        opacity: 1;
        z-index: 1001;
      }
    }

    &__drop-menu{
      width: 340px;
      height: 475px;
      background: #FFFFFF;
      position: relative;
    }

    &__parent{

      display: flex;
      align-items: center;
      padding: 16px 0 16px 25px;
      border-bottom: 1px solid var(--grey-stroke-color);
      cursor: pointer;
      &:hover{
        .header-main-menu{
          &__parent-name{
            color: var(--primary-color);
          }

          &__child-wrap{
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    &__parent-icon{
      margin-right: 13px;
      display: flex;
    }

    &__parent-name{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: -0.02em;
      color: var(--black-color);
      transition: color .3s;
    }

    &__child-wrap{
      transition: opacity .3s;
      border-left: 1px solid var(--grey-stroke-color);
      position: absolute;
      width: 340px;
      height: 475px;
      background: #FFFFFF;
      right: -340px;
      top: 0;
      padding: 20px 0 0 26px;
      display: flex;
      flex-direction: column;
      z-index: 1002;
      opacity: 0;
      pointer-events: none;
    }

    &__child-object, &__child-link{
      padding: 10px 0;
    }

    &__child-object{
      cursor: pointer;
    }

    &__child-link{
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: var(--black-color);
      transition: color .3s;
      &:hover{
        color: var(--primary-color);
      }
    }

    &__hamburger{
      height: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__hamburger-line{
      border: 1px solid var(--white-color);
      width: 20px;
      &_top, &_bottom{
        transition: {
          property: transform;
          duration: var(--default-transition-timing);
          timing-function: var(--default-transition-function);
        };
      }

      &_middle{
        transition: opacity var(--default-transition-timing) var(--default-transition-function);
      }
    }
  }
}

@include respond-down('medium') {
  .header-main-menu{
    display: none;
  }
}