.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
}

@include respond-up('large') {
  .header {
    transition: transform var(--default-transition-timing) var(--default-transition-function);
    &_transform{
      transform: translateY(-66px);
    }
  }
}

@include respond-down('medium') {
  .header{
    &._menu-opened{
      .header-bottom{
        &__hamburger-line{
          &_top{
            transform: translateY(6px) rotate(45deg);
          }

          &_middle{
            opacity: 0;
          }

          &_bottom{
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }

      .header-drop-menu{
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
      }

      .header-bottom__phone-link {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}