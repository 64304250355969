.generation-selection{
  background: #FFFFFF;

  &__title-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title{
    display: block;
  }

  &__image-container{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .generation-selection{
    margin-bottom: 120px;
    padding-bottom: 52px;

    &__title-wrap, &__subtitle{
      margin-left: 32px;
    }

    &__wrap{
      padding-top: 45px;
    }

    &__image-container{
      margin-right: 30px;
    }
  }
}

@include respond-up('medium') {
  .generation-selection{
    &__title{
    }

    &__image-container{
      width: 100px;
      height: 72px;
    }

    &__subtitle{
      margin-bottom: 28px;
    }
  }
}

@include respond('medium') {
  .generation-selection{
    margin-bottom: 70px;

    &__title-wrap, &__subtitle{
      margin-left: 20px;
    }

    &__wrap{
      padding-top: 35px;
    }

    &__image-container{
      margin-right: 25px;
    }
  }
}

@include respond-down('medium') {
  .generation-selection{
    padding-bottom: 30px;
  }
}

@include respond-down('small') {
  .generation-selection{
    margin-bottom: 50px;

    &__title-wrap, &__subtitle{
      flex-direction: column;
      margin-left: 15px;
      align-items: flex-start;
    }

    &__wrap{
      padding-top: 30px;
    }

    &__image-container{
      width: 75px;
      height: 54px;
      margin-bottom: 8px;
    }

    &__subtitle{
      margin-bottom: 21px;
    }
  }
}