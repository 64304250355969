.services {
  &__item-arrow {
    align-self: flex-end;
  }
}

@include respond-up('large') {
  .services {
    &__wrap {
      padding-bottom: 120px;
    }

    &__item {
      grid-column: span 4;

      &:first-child {
        grid-column: 1/7;
      }

      &:nth-child(2) {
        grid-column: 7/13;
      }

      &:first-child,
      &:nth-child(2) {
        .service-card {
          &__image-wrap {
            margin: 66px 0 81px;
          }

          &__picture {
            width: calc((290 / 1500) * 100vw);
            height: calc((270 / 1500) * 100vw);
            max-width: 392px;
            max-height: 365px;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .services {
    &__wrap {
      padding-bottom: 90px;
    }

    &__item {
      grid-column: span 3;
    }
  }
}

@include respond-down('small') {
  .services {
    &__wrap {
      padding-bottom: 50px;
    }

    &__list {
      grid-row-gap: 8px;
    }

    &__item {
      grid-column: span 4;
    }
  }
}