.checkbox-list {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey-stroke-color);
  padding: 11px 0;

  &__label {
    @extend .text__small_medium;
    color: var(--black-color);
    padding-bottom: 11px;
  }

  input {
    display: none;

    &:checked + label {
      .checkbox-list {
        &__label-icon {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  &__item-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 0;
  }

  &__label-icon {
    display: flex;
    background-color: #fff;
    border: 1px solid var(--grey-stroke-color);

    svg {
      transition: opacity .3s;
      opacity: 0;
    }
  }

  &__label-title {
    color: var(--black-color);
    margin-left: 8px;
  }
}

@include respond-up('large') {
  .checkbox-list {

  }
}

@include respond-up('medium') {
  .checkbox-list {

  }
}

@include respond('medium') {
  .checkbox-list {

  }
}

@include respond-down('medium') {
  .checkbox-list {

  }
}

@include respond-down('small') {
  .checkbox-list {

  }
}