
.record-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 28px;
  grid-column-gap: 22px;
  margin-top: 30px;

  &__button {
    padding: 12px 25px;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    line-height: 120%;
    pointer-events: auto;

    &_remove {
      background: transparent;
      color: #798297;
      position: relative;
      text-align: left;


      &:before,
      &:after {
        content: '';
        width: 2px;
        height: 16px;
        background: #798297;
        top: 46%;
        position: absolute;
        transform-origin: center;
      }

      &:before {
        transform: rotate(45deg) translate3d(0, -50%, 0);
        left: 0;
      }

      &:after {
        transform: rotate(-45deg) translate3d(0, -50%, 0);
        left: 11px;
      }
    }

    &_save {
      grid-column: 2/3;
      background: #E12727;
      color: white;
    }
  }
}

.form-input {
  position: relative;
  margin-top: 22px;

  &_service,
  &_comment {
    grid-column: 1/3;
  }

  &_name {

  }

  &_phone {

  }

  &_number {

  }

  &__label {

  }

  &__input {

  }

  input, textarea, select {
    font-family: 'Roboto', serif;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    width: 100%;
    padding: 12px;
  }

  select {
    option {
      font-weight: 400;
      font-size: 15px;
      line-height: 133%;
      color: #949494;
    }
  }

  input {
    height: 44px;
  }

  textarea {
    height: 90px;
  }

  label {
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: #949494;
    position: absolute;
    top: -22px;
    left: 0;
  }
}
