.header-top {
  height: 65px;
  background-color: var(--white-color);
}

@include respond-up('x-large') {
  .header-top {
    &__schedule-text {
      column-gap: 20px;
    }

    &__schedule {
      margin-left: calc(100/1600 * 100vw);
    }
  }
}

@include respond-up('large') {
  .header-top {
    &__container, &__wrap, &__recall, &__phone, &__phone-link, &__recall-link{
      height: 100%;
    }

    &__logo{
      display: flex;
      grid-column: 1 / 3;
      a{
        display: flex;
        align-items: center;
      }
      svg{
        width: 158px;
        height: 56px;
      }
    }
    &__top-info{
      grid-column: 3 / 10;
      display: flex;
    }

    &__menu-wrap{
      grid-column: 10 / 13;
    }

    &__phone{
      color: var(--black-color);
    }

    &__phone-block{
      display: flex;
      flex-direction: column;
    }

    &__phone-label{
      color: var(--grey-text-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%; /* 16.2px */
      letter-spacing: -0.24px;
      margin-bottom: 2px;
    }

    &__phone-link{
      display: flex;
      align-items: center;
      color: var(--black-color);
      transition: color var(--default-transition-timing) var(--default-transition-function);
      white-space: nowrap;
      &:hover{
        color: var(--primary-color);
      }
    }

    &__delimiter {
      color: var(--grey-stroke-color);
      font-size: 25px;
      padding: 0 35px;
    }

    &__recall-link {
      color: var(--black-color);
      display: flex;
      align-items: center;
      padding-left: 16px;
      transition: color var(--default-transition-timing) var(--default-transition-function);
      &:hover{
        color: var(--primary-color);
      }
    }

    &__schedule{
      color: var(--black-color);
      display: flex;
      align-items: center;
    }

    &__schedule-text{
     display: flex;
    }

    &__schedule-icon{
      display: flex;
      margin-right: 10px;
    }

    &__contact{
      display: flex;
      align-items: center;
    }

    &__menu-wrap{
      display: flex;
      justify-self: end;
    }

    &__menu-item{
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:first-child{
        margin-right: 35px;
      }

      &:hover{
        .header-top{
          &__submenu{
            opacity: 1;
            pointer-events: all;
          }
          &__menu-item-icon{
            svg{
              path{
                fill: var(--primary-color);
              }
            }
          }
        }
      }
    }

    &__menu-item-name{
      color: var(--black-color);
      margin-right: 6px;
    }

    &__menu-item-icon{
      svg{
        path{
          transition: fill var(--default-transition-timing) var(--default-transition-function);
          fill: var(--black-color);
        }
      }
    }

    &__submenu{
      position: absolute;
      top: 57px;
      right: -2px;
      pointer-events: none;
      opacity: 0;
      transition: opacity var(--default-transition-timing) var(--default-transition-function);
      cursor: default;
      z-index: 10;
    }

    &__submenu-wrap{
      position: relative;
      background-color: var(--white-color);
      width: 232px;
      filter: drop-shadow(0px 7px 30px rgba(107, 104, 96, 0.25));
      padding: 20px;
      display: flex;
      flex-direction: column;
      &:before{
        content: '';
        position: absolute;
        top: -5px;
        right: 45px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 6px 7px;
        border-color: transparent transparent #fff transparent;
      }
    }

    &__submenu-link{
      padding: 4px 0;
      color: var(--grey-text-color);
      transition: color var(--default-transition-timing) var(--default-transition-function);
      &:last-child{
        padding-bottom: 0;
      }

      &:first-child{
        padding-top: 0;
      }

      &:hover{
        color: var(--primary-color)
      }
    }

    &__schedule-block {
      display: flex;
      align-items: center;

      span {
        flex: 0 0 auto;
      }
    }
  }
}

@include respond('large') {
  .header-top {
    &__schedule {
      margin-left: calc(100/1260 * 100vw);
    }
  }
}

@include respond-down('medium') {
  .header-top {
    display: none;
  }
}

@media (max-width: 1430px) {
  .header-top{
    &__schedule-text {
      flex-direction: column;
    }
  }
}