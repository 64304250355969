.model-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background-color: #fff;

  &__action-name{
    display: flex;
    flex-direction: column;
  }

  &__action-name-wrap{
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 5px;
    width: 165px;
    &:last-child{
      border: none;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__action {
    display: flex;
    position: relative;

    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  &__action-link {
    display: flex;
    position: relative;
  }

  &__action-icon {
    display: flex;

    svg {
      width: 100%;
      height: 100%;

      circle,
      path {
        transition: fill var(--default-transition-timing) var(--default-transition-function);
      }
    }
  }

  &__price {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
  }

  &__price-from {
    color: var(--grey-text-color);
  }

  &__price-title {
    margin-left: 4px;
  }

  &__image-link {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__link {
    display: flex;
  }

  &__top {
    display: flex;
    position: relative;
  }

  &__features-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__hidden-name {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate3d(8px, -50%, 0);
    margin-left: 0;
    padding: 5px;
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(45, 41, 40, 0.1));

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: var(--black-color);
    opacity:0;
    pointer-events: none;
    transition: opacity var(--default-transition-timing);
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      right: calc(100% - 1px);
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: var(--t) #fff var(--t) var(--t);
    }

    &_actions{
      padding: 0;
      top: 32px;
      &:before {
        top: 25%;
      }
    }
  }

  &__hidden-target {
    &:hover {
      .model-card {
        &__hidden-name {
          opacity: 1;
        }

        &__action-icon {
          svg {
            circle {
              fill: var(--primary-color);
            }
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  &__feature {
    display: flex;
    position: relative;

    .model-card {
      &__hidden-name {
        transform: translate3d(11px, -50%, 0);
      }
    }
  }
  &__feature-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__features {
    display: flex;
    column-gap: 6px;
  }
}

@include respond-up('large') {
  .model-card {
    &__title {
      transition: color var(--default-transition-timing);
    }

    &__image-link{
      padding-bottom: 8vw;
    }

    &__link {
      &:hover {
        .model-card__title {
          color: var(--primary-color);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .model-card {
    padding: 18px 18px 24px;

    &__link {
      margin: 20px 0;
    }

    &__top {
      padding-top: 30px;
    }

    &__action-icon {
      width: 28px;
      height: 28px;
    }
  }
}

@include respond('medium') {
  .model-card {
    &__image-link{
      padding-bottom: 12vw;
    }
  }
}

@include respond-down('medium') {
  .model-card {

  }
}

@include respond-down('small') {
  .model-card {
    padding: 10px 10px 20px;

    &__link {
      margin: 15px 0 18px;
    }

    &__image-link{
      padding-bottom: 19vw;
    }

    &__top {
      padding-top: 12px;
      min-height: 169px;
    }

    &__action-icon {
      width: 24px;
      height: 24px;
    }
  }
}