.product-page-group-offers {
  background-color: #fff;

  &__name {
    color: var(--grey-text-color);
  }

  &__available {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--primary-color);
    border-bottom: 1px dashed var(--primary-color);
    padding-bottom: 2px;
    white-space: nowrap;
  }

  &__item {
    border-top: 1px solid var(--grey-stroke-color);
    display: flex;
    flex-wrap: wrap;
  }

  &__quantity-wrap {
    display: flex;
    align-items: center;
  }

  &__to-cart {
    margin-left: 22px;
  }

  &__info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    margin-top: 4px;
  }
}

@include respond-up('large') {
  .product-page-group-offers {
    &__heading {
      padding: 20px 30px;
    }

    &__item {
      padding: 15px 30px;
    }

    &__price-wrap {
      flex: 0 0 190px;
    }

    &__info-wrap {
      flex: 0 1 311px;
    }

    &__quantity-wrap {
      width: calc(var(--grid-column2 - 16px));
    }
  }
}

@media (max-width: 1453px) {
  .product-page-group-offers {
    &__info-wrap {
      flex: 0 1 100%;
    }
  }
}


@include respond-up('medium') {
  .product-page-group-offers {
    &__item {
      align-items: center;
      justify-content: space-between;
    }

    &__info-wrap {
    }
  }
}

@include respond('medium') {
  .product-page-group-offers {
    &__heading {
      padding: 18px 30px 17px;
    }

    &__item {
      padding: 15px 28px;
    }

    &__to-cart {
      margin-left: 41px;
    }

    &__price-wrap {
      flex: 0 0 calc(var(--grid-column2) - 28px);
    }

    &__info-wrap {
      flex: 0 1 calc(var(--grid-column4) - 93px);
    }
  }
}

@include respond-down('medium') {
  .product-page-group-offers {

  }
}

@include respond-down('small') {
  .product-page-group-offers {
    &__item {
      flex-direction: column;
      padding: 14px 18px;
    }

    &__heading {
      padding: 18px;
    }
  }
}