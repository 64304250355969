.corp-clients {

  &__top-item {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    overflow: hidden;
  }

  &__top-item-title-count {
    position: absolute;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -0.02em;
    background: linear-gradient(180deg, #EEEEEE 0%, rgba(238, 238, 238, 0) 88.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__advantages-list {
    display: flex;
    flex-direction: column;
    background: #fff;
  }

  &__advantages-number {
    position: relative;
    font-weight: 400;
    line-height: 80%;
    letter-spacing: -0.02em;
    color: var(--black-color);

    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      background: var(--primary-color);
    }
  }

  &__top-item-image-wrap{
    position: relative;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .corp-clients {
    padding: 80px 0 120px 0;

    &__top-item {
      padding: 30px 30px 30px 0;
      grid-column: span 4;
    }

    &__top-item-title {
      padding-left: 30px;
    }

    &__top-item-title-count {
      top: -19px;
      right: -12px;
      min-width: 88px;
      font-size: 65px;
    }

    &__top-item-image-wrap {
      margin-top: 28px;
      padding-bottom: calc(189/350 * 100%);
    }

    &__top-item-description {
      margin-top: 28px;
      padding-left: 30px;
    }

    &__advantages-block {
      margin-top: 120px;
    }

    &__advantages-block-list {
      margin-top: 35px;
    }

    &__advantages-list {
      padding: 35px 30px;
      grid-column: span 3;
    }

    &__advantages-number {
      font-size: 40px;

      &:before {
        left: -30px;
      }
    }

    &__advantages-text {
      margin-top: 35px;
    }

    &__form {
      margin-top: 120px;
    }

  }
}

@include respond('medium') {
  .corp-clients {
    padding: 70px 0 90px 0;

    &__top-item {
      padding: 30px 22px 25px 0;
      grid-column: span 3;
    }

    &__top-item-title {
      padding-left: 22px;
    }

    &__top-item-title-count {
      top: -19px;
      right: -12px;
      min-width: 88px;
      font-size: 65px;
    }

    &__top-item-image-wrap {
      margin-top: 25px;
      padding-bottom: calc(170/321 * 100%);
    }

    &__top-item-description {
      margin-top: 22px;
      padding-left: 22px;
    }

    &__advantages-block {
      margin-top: 70px;
    }

    &__advantages-block-list {
      margin-top: 28px;
    }

    &__advantages-list {
      padding: 30px 22px;
      grid-column: span 2;
    }

    &__advantages-number {
      font-size: 40px;

      &:before {
        left: -22px;
      }
    }

    &__advantages-text {
      margin-top: 30px;
    }

    &__form {
      margin-top: 70px;
    }
  }
}

@include respond-down('small') {
  .corp-clients {
    padding: 27px 0 50px 0;

    &__top-item {
      padding: 25px 20px 25px 0;
      grid-column: span 4;
    }

    &__top-item-title {
      padding-left: 20px;
    }

    &__top-item-title-count {
      top: -19px;
      right: -10px;
      min-width: 75px;
      font-size: 56px;
    }

    &__top-item-image-wrap {
      margin-top: 20px;
      padding-bottom: calc(170/318 * 100%);

    }

    &__top-item-description {
      margin-top: 20px;
      padding-left: 20px;
    }

    &__advantages-block {
      margin-top: 50px;
    }

    &__advantages-block-list {
      margin-top: 20px;
    }

    &__advantages-list {
      padding: 22px 20px 20px;
      grid-column: span 4;
    }

    &__advantages-number {
      font-size: 30px;

      &:before {
        left: -20px;
      }
    }

    &__advantages-text {
      margin-top: 20px;
    }

    &__form {
      margin-top: 50px;
    }
  }
}