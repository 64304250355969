
    .markup-errors {
      color: red;
      list-style: none;
      margin: 3px 0 5px;
      padding: 0;

      &:empty {
        margin-bottom: 0;
      }
    }
