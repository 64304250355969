.service-page {
  &__main {
    background-color: #fff;
  }

  &__images-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--grid-gap);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__bottom-list {
    display: grid;
  }
}

@include respond-up('large') {
  .service-page {
    &__main-inner {
      padding: 80px 0 100px;
      display: grid;
      grid-template-columns: var(--grid-column8) var(--grid-column3);
      grid-column-gap: calc(var(--grid-column) + (var(--grid-gap) * 2));
    }

    &__sticky {
      position: sticky;
      top: 140px;
    }

    &__picture {
      &_single {
        height: calc((438 / 1500) * 100vw);
        max-height: 550px;
      }

      &_first {
        height: calc((393 / 1500) * 100vw);
        max-height: 498px;
      }

      &_second {
        height: calc((254 / 1500) * 100vw);
        max-height: 323px;
      }
    }

    &__bottom-inner {
      padding: 90px 0;
    }

    &__bottom-list {
      grid-template-columns: repeat(3, var(--grid-column4));
      margin-top: 35px;
    }
  }
}

@include respond-up('medium') {
  .service-page {
    &__bottom-list {
      grid-gap: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .service-page {
    &__main-inner {
      padding: 70px 0;
    }

    &__sticky-wrap {
      margin-top: 50px;
    }

    &__picture {
      &_single {
        height: calc((389 / 768) * 100vw);
      }

      &_first {
        height: calc((355 / 768) * 100vw);
      }

      &_second {
        height: calc((229 / 768) * 100vw);
      }
    }

    &__bottom-inner {
      padding: 70px 0 90px;
    }

    &__bottom-list {
      grid-template-columns: repeat(2, var(--grid-column3));
      margin-top: 28px;
    }
  }
}

@include respond-down('medium') {
  .service-page {
    &__main-inner {
      display: flex;
      flex-direction: column;
    }

    &__bottom-item {
      &:nth-child(n+3) {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .service-page {
    &__main-inner {
      padding: 40px 0;
    }

    &__sticky-wrap {
      margin-top: 40px;
    }

    &__picture {
      &_single {
        height: calc((188 / 375) * 100vw);
      }

      &_first {
        height: calc((168 / 375) * 100vw);
      }

      &_second {
        height: calc((108 / 375) * 100vw);
      }
    }

    &__bottom-inner {
      padding: 40px 0 50px;
    }

    &__bottom-list {
      grid-template-columns: 1fr;
      grid-row-gap: 8px;
      margin-top: 22px;
    }
  }
}