.cart-order-map {
  position: relative;
}

@include respond-up('large') {
  .cart-order-map {
    padding-top: 40px;

    &__map {
      height: 366px;
    }
  }
}

@include respond-up('medium') {
  .cart-order-map {

  }
}

@include respond('medium') {
  .cart-order-map {
    padding-top: 35px;

    &__map {
      height: 290px;
    }
  }
}

@include respond-down('medium') {
  .cart-order-map {

  }
}

@include respond-down('small') {
  .cart-order-map {
    padding-top: 20px;

    &__map {
      height: 290px;
    }
  }
}