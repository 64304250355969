.to-favorite {
  display: flex;

  svg path {
    transition: fill, opacity;
    transition-duration: var(--default-transition-timing);

    &:last-child {
      opacity: 0;
    }
  }

  &:hover {
    svg path {
      &:first-child {
        fill: var(--primary-color);
      }
    }
  }

  &_selected {
    svg path {
      &:last-child {
        opacity: 1;
      }
    }
  }
}