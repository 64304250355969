.report-form{
  height: auto;
  padding-bottom: 30px;
  &__form{
    display: flex;
    padding-top: 30px;
    border-top: 1px solid lightgray;
  }

  &__form-all{
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid lightgray;
  }

  &__title{
    padding-bottom: 20px;
  }

  &__field{
    display: flex;
    align-items: center;
    margin-right: 15px;
    input[type='radio'] + label {
      margin-right: 10px;
      margin-left: 5px;
    }
  }
}