.price-competitor-main {
  width: max-content;
  height: max-content;
  position: relative;

  &__number{
    position: absolute;
    left: -65px;
    margin-top: 67px;
  }
  &__heading-title {
    position: sticky;
    z-index: 50;
    top: 0;
    left: 0;
    width: 200px;
    height: 64px;
    border: 1px solid var(--black-color);
    background-color: var(--gray-background-color);
    padding: 10px;
  }

  &__heading-columns {
    position: sticky;
    z-index: 25;
    top: 0;
    display: flex;
    height: 64px;
    margin-top: -64px;
    padding-left: 200px;
    background-color: var(--gray-background-color);
  }

  &__heading-column {
    width: 150px;
    border: 1px solid var(--black-color);
    border-left: unset;
    padding: 10px;
    font-size: 14px;
  }

  &__heading-rows {
    position: sticky;
    z-index: 25;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: var(--gray-background-color);
  }

  &__heading-row {
    width: 100%;
    height: 245px;
    border: 1px solid var(--black-color);
    border-top: unset;
    padding: 10px;
  }

  &__data {
    position: absolute;
    top: 64px;
    left: 200px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    height: 245px;
    border-bottom: 1px solid var(--black-color);
  }

  &__item-column {
    width: 150px;
    height: 100%;
    border-right: 1px solid var(--black-color);
    display: flex;
    flex-direction: column;

    &._green {
      background-color: var(--green-color);
    }

    &._red {
      background-color: var(--primary-color-opacity-30);
    }

    &._yellow {
      background-color: var(--yellow-color);
    }
  }

  &__tyre-link {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;

    &:hover {
      .price-competitor-main {
        &__tyre-link-price,
        &__tyre-link-date,
        &__tyre-link-title {
          text-decoration: underline;
        }
      }
    }
  }

  &__stats-link {
    flex: 0 0 auto;
    padding: 0 10px 10px;
    color: var(--primary-color);

    &:hover {
      text-decoration: underline;
    }
  }

  &__checked-wrap {
    display: flex;
    flex-direction: column;
  }

  &__checked,
  &__match {
    display: flex;
  }

  &__form {
    margin-top: 10px;

    input {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &._error {
        border: 1px solid red;
      }
    }
  }

  &__price,
  &__source-type {
    display: flex;
  }

  &__price-date {
    display: flex;
    flex-direction: column;
  }

  &__source-type,
  &__price-date,
  &__submit {
    margin-top: 10px;
  }

  &__tracked {
    display: flex;
    align-items: center;
    margin-top: 10px;

    input {
      width: max-content;
    }
  }

  &__price-form {
    display: flex;
    align-items: flex-end;
  }

  &__price-input-wrap {
    flex: 0 1 100%;

    input {
      height: 34px;
      border: none;
      padding: 7px 10px;
      color: var(--black-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
      letter-spacing: -0.28px;
    }
  }

  &__price-buttons {
    display: flex;
    margin-left: 2px;
    flex: 0 0 70px;
  }

  &__price-button {
    background-color: unset;
    border-radius: 2px;
    padding: 0;
    width: 34px;
    height: 34px;
    border: none;

    &:last-child {
      margin-left: 2px;
    }

    svg {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-down('medium') {
  .price-competitor-main {
    &__tyre-link {
      padding: 5px;
    }
  }
}