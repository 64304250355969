.model-selection{
  background: #FFFFFF;

  &__title-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title{
    display: block;
  }

  &__list{
    display: grid;
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--grey-stroke-color);
    border-right: 1px solid var(--grey-stroke-color);
    background: white;
  }

  &__image-container{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__item-name{
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: var(--black-color);
  }
}

@include respond-up('large') {
  .model-selection{
    margin-bottom: 120px;

    &__title-wrap, &__subtitle{
      margin-left: 32px;
    }

    &__wrap{
      padding-top: 45px;
    }

    &__image-container{
      margin-right: 30px;
    }

    &__list{
      grid-template-columns: repeat(4, 1fr);
    }

    &__item{
      padding: 11px 30px;
      transition-property: filter, transform, border;
      transition-duration: .2s;
      backface-visibility: hidden;
      &:nth-child(4n+4) {
        border-right: unset;
      }

      &:hover{
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
        transform: scale(1.01);
        border-bottom: 1px solid transparent;
        border-right: 1px solid transparent;
        .model-selection{
          &__item-name{
            color: var(--primary-color);
          }
        }
      }
    }

    &__item-name{
      transition: color .2s;
    }
  }
}

@include respond-up('medium') {
  .model-selection{
    &__title{
    }

    &__image-container{
      width: 100px;
      height: 72px;
    }

    &__subtitle{
      margin-bottom: 28px;
    }
  }
}

@include respond('medium') {
  .model-selection{
    margin-bottom: 70px;

    &__title-wrap, &__subtitle{
      margin-left: 20px;
    }

    &__wrap{
      padding-top: 35px;
    }

    &__image-container{
      margin-right: 25px;
    }

    &__title{
    }

    &__list{
      grid-template-columns: repeat(3, 1fr);
    }

    &__item{
      padding: 11px 20px;
      &:nth-child(3n+3) {
        border-right: unset;
      }
    }
  }
}

@include respond-down('medium') {
  .model-selection{

  }
}

@include respond-down('small') {
  .model-selection{
    margin-bottom: 50px;

    &__title-wrap, &__subtitle{
      flex-direction: column;
      margin-left: 15px;
      align-items: flex-start;
    }

    &__wrap{
      padding-top: 30px;
    }

    &__image-container{
      width: 75px;
      height: 54px;
      margin-bottom: 8px;
    }

    &__subtitle{
      margin-bottom: 21px;
    }

    &__list{
      grid-template-columns: repeat(2, 1fr);
    }

    &__item{
      padding: 11px 15px;
      &:nth-child(2n+2) {
        border-right: unset;
      }
    }
  }
}