
.service-centers {
  margin-right: 110px;

  &__list {
    display: flex;
  }

  &__item {
    flex: 0 0 238px;
    border-right: 1px solid var(--gray);
    background-color: var(--block-background);

    &:last-child {
      border-right: none;
    }
  }
}
