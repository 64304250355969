.product-page-top-price {
  background-color: #fff;

  &__heading {
    position: relative;
  }

  &__favorite {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__prices-wrap {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 8px;
  }

  &__prices {
    display: flex;
    align-items: baseline;
  }

  &__old-price {
    text-decoration: line-through;
    margin-left: 10px;
  }

  &__old-price,
  &__kit-price {
    color: var(--grey-text-color);
  }

  &__available-wrap {
    display: grid;
    grid-row-gap: 8px;

    &:not(:has(> .product-page-top-price__heading-title)) {
      .product-page-top-price {
        &__available {
          height: fit-content;
          align-self: center;
        }
      }
    }
  }

  &__available {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--primary-color);
    border-bottom: 1px dashed var(--primary-color);
    padding-bottom: 2px;
    grid-area: avail;
    width: fit-content;
  }

  &__heading-title {
    grid-area: title;
  }

  &__quantity {
    grid-area: quantity;
  }

  &__buttons-wrap {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    flex: 0 0 49%;

    &:last-child {
      &.button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      background-color: rgba(138, 164, 151, 0.08);
    }

    &:last-child {
      margin-top: 10px;
    }
  }

  &__info-item-icon {
    display: flex;
  }

  &__info-item-title {
    margin-left: 8px;

    &.text {
      color: var(--grey-text-color);
    }
  }
}

@include respond-up('large') {
  .product-page-top-price {
    padding: 25px 30px 20px;

    &__prices {
      display: flex;
      margin-right: 35px;
    }

    &__info-item {
      &:first-child {
        padding: 10px 23px;
      }
    }

    &__info {
      margin-top: 15px;
    }

    &__available-wrap {
      margin-top: 24px;
      width: fit-content;
      grid-column-gap: 74px;
      grid-template-areas:
                  'avail quantity'
                  'avail quantity';

      &:has(> .product-page-top-price__heading-title) {
        grid-template-areas:
                  'avail quantity'
                  'title quantity';
      }
    }

    &__quantity {
      padding-top: 9px;
      padding-bottom: 4px;
    }
  }
}

@include respond-up('medium') {
  .product-page-top-price {
    &__button {
      &.button {
        padding: 14px 14px 13px;
      }
    }
  }
}

@include respond('medium') {
  .product-page-top-price {
    padding: 20px 19px;

    &__info-item {
      &:first-child {
        padding: 8px 18px;
      }
    }

    &__info {
      margin-top: 20px;
    }

    &__prices-wrap {
      align-items: flex-start;
      flex-direction: column;
    }

    &__kit-price {
      margin-top: 8px;
    }
  }
}

@include respond-down('medium') {
  .product-page-top-price {
    &__prices {
      margin-right: 18px;
    }

    &__button-title-half {
      &:first-child {
        display: none;
      }
    }

    &__available-wrap {
      margin-top: 20px;
      grid-template-areas:
                  'avail quantity'
                  'title title';
    }

    &__available {
      height: fit-content;
      align-self: center;
    }
  }
}

@include respond-down('small') {
  .product-page-top-price {
    padding: 20px 18px;

    &__info-item {
      &:first-child {
        padding: 8px 25px;
      }
    }

    &__info {
      margin-top: 18px;
    }
  }
}