.switcher {
  position: relative;
  display: flex;
  background: #fff;
  border: 1px solid var(--grey-stroke-color);
  border-radius: 100px;
  width: 37px;
  height: 20px;

  &__inner {
    position: absolute;
    z-index: 5;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    background-color: var(--grey-stroke-color);
    border-radius: 100%;
    transition: transform .3s, background-color .3s;
  }

  &._active {
    .switcher__inner {
      transform: translate3d(17px, 0, 0);
      background-color: var(--primary-color);
    }
  }
}