.record-for-service-success {
  background-color: #fff;

  &__heading {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__list {
    border-top: 1px solid var(--grey-stroke-color);
  }

  &__item {
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 12px 0;
    display: flex;
    align-items: flex-start;
  }

  &__item-name {
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .record-for-service-success {
    width: var(--grid-column9);

    &__heading {
      padding: 28px 38px;
    }

    &__main {
      padding: 35px 38px;
    }

    &__item-name {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond-up('medium') {
  .record-for-service-success {
    &__bottom {
      margin-top: 50px;
    }

    &__buttons {
      margin-top: 24px;
    }

    &__item-value {
      flex: 0 1 100%;
    }

    &__secondary {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .record-for-service-success {
    &__heading {
      padding: 23px 28px;
    }

    &__main {
      padding: 35px 28px;
    }

    &__item-name {
      flex: 0 0 calc((212 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .record-for-service-success {

  }
}

@include respond-down('small') {
  .record-for-service-success {
    &__heading {
      padding: 15px 18px;
    }

    &__main {
      padding: 25px 18px;
    }

    &__bottom {
      margin-top: 35px;
    }

    &__buttons {
      margin-top: 20px;
    }

    &__primary {
      width: 100%;
    }

    &__secondary {
      width: 100%;
      margin-top: 10px;
    }

    &__item-name,
    &__item-value {
      flex: 0 0 50%;
    }
  }
}