.index-offer-slider {
  &__image-wrap{
    position: relative;
  }

  &__picture, &__title-wrap, &__arrows{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  &__arrows{
    display: flex;
    justify-content: space-between;
    z-index: 10;
  }
}

@include respond-up('large') {
  .index-offer-slider {
    &__image-wrap{
      padding-bottom: 84px;
    }
  }
}

@include respond('medium') {
  .index-offer-slider {
    &__image-wrap{
      padding-bottom: 62px;
    }
  }
}

@include respond-down('small') {
  .index-offer-slider {
    &__image-wrap{
      padding-bottom: 90px;
    }

    &__title{
      max-width: 264px;
      text-align: center;
    }
  }
}