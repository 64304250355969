.fast-buy {
  &__form {

  }

  &__product {
    height: 151px;
    border-top: 1px solid var(--grey-stroke-color);
    border-bottom: 1px solid var(--grey-stroke-color);
    display: flex;
  }

  &__fields {
    display: grid;
    grid-gap: var(--grid-gap);
  }

  &__field {
    position: relative;
  }

  &__button-wrap {
    display: flex;
  }

  &__image-wrap {
    display: flex;
  }

  &__info-wrap {
    flex: 1 1 auto;
    display: flex;
  }

  &__info {
    flex: 1 1 auto;
  }

  &__panel-wrap {
    flex: 0 0 auto;
    display: flex;
  }

  &__panel {
    flex: 0 0 auto;
    display: flex;
  }

  &__price-wrap {
    display: flex;
    justify-content: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
  }

  &__image {
    flex: 0 0 100%;
    width: 100%;
    object-fit: contain;
    object-position: center top;
  }

  &__price{
    font-weight: 600;

    &_total {
      height: 34px;
      display: flex;
      align-items: center;
    }
  }

  &__item-price {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  &__measure {
    color: var(--grey-text-color);
    margin-left: 4px;
  }

  &__panel-button {
    flex: 0 0 34px;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-stroke-color);
  }

  &__panel-input {
    flex: 0 0 36px;
    width: 36px;
    height: 34px;
    border: unset;
    background: unset;
    text-align: center;
  }

  &__price-number {
    margin-right: 3px;
  }
}

@include respond-up('large') {
  .fast-buy {
    &__product {
    }

    &__image-wrap {
      flex: 0 0 121px;
    }

    &__price-wrap {
      flex: 0 0 193px;
      width: 193px;
    }

    &__info {
      padding: 0 30px;
    }
  }
}

@include respond-up('medium') {
  .fast-buy {
    &__product {
      margin: 35px 0 30px;
      padding: 20px 0;
    }


    &__fields {
      grid-template-columns: 1fr 1fr;
    }

    &__button-wrap {
      margin-top: 35px;
      justify-content: space-between;
    }

    &__price, &__price-wrap {
      font-size: 18px;
    }
  }
}

@include respond('medium') {
  .fast-buy {
    &__product {
      grid-template-columns: 107px 1fr auto 181px;
    }

    &__image-wrap {
      flex: 0 0 107px;
    }

    &__price-wrap {
      flex: 0 0 181px;
      width: 181px;
    }

    &__info {
      padding: 0 14px;
    }
  }
}

@include respond-down('medium') {
  .fast-buy {

  }
}

@include respond-down('small') {
  .fast-buy {
    &__product {
      margin: 20px 0 30px;
      padding: 18px 0 15px;
      flex-wrap: wrap;
    }

    &__fields {
      grid-template-columns: 1fr;
    }

    &__button-wrap {
      margin-top: 25px;
      flex-direction: column-reverse;
    }

    &__info-wrap {
      flex: 0 0 100%;
    }

    &__panel-wrap {
      flex: 0 0 100%;
    }

    &__politic {
      margin-top: 12px;
    }

    &__price-wrap {
      flex: 1 1 auto;
      justify-content: center;
    }

    &__price {
      font-size: 16px;

      &_total {
        flex: 1 1 auto;
        justify-content: center;
      }
    }

    &__info {
      padding-left: 10px;
    }
  }
}