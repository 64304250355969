.product-base {
  &__top-main {
    display: grid;
  }

  &__top-slider,
  &__top-service,
  &__top-price,
  &__top-additional {
    background-color: #fff;
  }

  &__top-heading-title {
    color: var(--grey-text-color);
  }

  &__top-slider,
  &__top-actions {
    grid-column: span 1;
  }

  &__tabs {
    background-color: #fff;
  }
}

@include respond-up('large') {
  .product-base {
    &__top-main {
      grid-column-gap: var(--grid-gap);
      grid-template-rows: repeat(3, auto);
    }

    &__top-heading {
      grid-column: span 2;
    }

    &__top-slider {
      grid-row: 1/3;
    }

    &__top-actions {
      grid-row: 3/4;
      border-top: 1px solid var(--grey-stroke-color);
    }

    &__tabs {
      margin-top: 70px;
    }

    &__additional {
      grid-row: 2/4;
      margin-top: 14px;
    }

    &__technologies {
      margin-top: 70px;
    }
  }
}

@include respond-up('medium') {
  .product-base {
    padding-bottom: 80px;
    &__top-main {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 18px;
    }
  }
}

@include respond('medium') {
  .product-base {
    &__top-main {
      grid-gap: var(--grid-gap);
    }

    &__top-actions {
      grid-column: span 2;
    }

    &__tabs {
      margin-top: 58px;
    }

    &__technologies {
      margin-top: 54px;
    }
  }
}

@include respond-down('medium') {
  .product-base {

  }
}

@include respond-down('small') {
  .product-base {
    padding-bottom: 40px;

    &__top-heading {
      padding-bottom: 10px;
    }

    &__top-main {
      grid-template-columns: 1fr;
      grid-row-gap: 8px;
    }

    &__tabs {
      margin-top: 8px;
    }

    &__technologies {
      margin-top: 8px;
    }
  }
}