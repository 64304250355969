.action-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__bottom {
    border-top: 1px solid var(--grey-stroke-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
  }

  &__title {
    display: block;
    flex: 0 1 100%;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-wrap {
    width: 100%;
    flex: 0 0 auto;
  }

  &__icon {
    display: flex;
    height: max-content;
  }

  &__date {
    color: var(--grey-text-color);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0);
      background-color: var(--primary-color);
      border-radius: 100%;
    }
  }
}

@include respond-up('large') {
  .action-card {
    &__title {
      padding: 20px 25px 40px;
    }

    &__bottom {
      padding: 21px 25px 21px 29px;
    }

    &__image-wrap {
      height: calc((238 / 1500) * 100vw);
      max-height: 298px;
    }

    &__image {
      transition: transform .5s;
    }

    &__icon {
      svg path {
        transition: stroke .5s;
      }
    }

    &:hover {
      .action-card {
        &__image {
          transform: scale(1.05);
        }

        &__icon {
          svg path {
            stroke: var(--black-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .action-card {
    &__date {
      padding-left: 17px;

      &::before {
        width: 9px;
        height: 9px;
      }
    }
  }
}

@include respond('medium') {
  .action-card {
    &__title {
      padding: 20px 22px 30px;
    }

    &__bottom {
      padding: 20px 22px 18px;
    }

    &__image-wrap {
      height: calc((215 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .action-card {

  }
}

@include respond-down('small') {
  .action-card {
    &__title {
      padding: 20px 18px 30px;
    }

    &__bottom {
      padding: 20px 18px;
    }

    &__image-wrap {
      height: calc((211 / 375) * 100vw);
    }

    &__date {
      padding-left: 14px;

      &::before {
        width: 8px;
        height: 8px;
      }
    }

    &__icon {
      width: 18px;
      height: 19px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}