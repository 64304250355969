.company-page {
  &__gray-bg {
    background-color: var(--gray-background-color);
  }

  &__bottom {
    background-color: #fff;
  }
}

@include respond-up('large') {
  .company-page {
    &__gray-bg {
      padding: 80px 0 100px;
    }

    &__gray-bg-item {
      &:not(:first-child) {
        margin-top: 120px;
      }
    }

    &__bottom {
      padding: 100px 0 120px;
    }

    &__history {
      margin-top: 70px;
    }

    &__ceo {
      margin-top: 120px;
    }
  }
}

@include respond-up('medium') {
  .company-page {

  }
}

@include respond('medium') {
  .company-page {
    &__gray-bg {
      padding: 70px 0;
    }

    &__gray-bg-item {
      &:not(:first-child) {
        margin-top: 70px;
      }
    }

    &__bottom {
      padding: 70px 0 90px;
    }

    &__history {
      margin-top: 40px;
    }

    &__ceo {
      margin-top: 90px;
    }
  }
}

@include respond-down('medium') {
  .company-page {

  }
}

@include respond-down('small') {
  .company-page {
    &__gray-bg {
      padding: 40px 0 50px;
    }

    &__gray-bg-item {
      margin-top: 50px;

      &:first-child {
        margin-top: 40px;
      }
    }

    &__bottom {
      padding: 40px 0 50px;
    }

    &__history {
      margin-top: 25px;
    }

    &__ceo {
      margin-top: 50px;
    }
  }
}