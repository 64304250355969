.catalog-banner-card {
  position: relative;
  height: 100%;
  width: 100%;

  &__text-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 100;
  }

  &__title {
    color: #fff;
  }

  &__subtitle {
    color: var(--white-color-60);
    margin-top: 12px;
  }


  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .catalog-banner-card {

  }
}

@include respond-up('medium') {
  .catalog-banner-card {
    &__text-wrap {
      padding: 25px 20px;
    }
  }
}

@include respond('medium') {
  .catalog-banner-card {

  }
}

@include respond-down('medium') {
  .catalog-banner-card {

  }
}

@include respond-down('small') {
  .catalog-banner-card {
    &__text-wrap {
      padding: 18px 15px;
    }
  }
}