.filter-clear-button {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: var(--primary-color);
}

@include respond-up('large') {
  .filter-clear-button {
    transition: color .4s;

    &:hover {
      color: var(--black-color);
    }
  }
}

@include respond-down('medium') {
  .filter-clear-button {
    background-color: var(--primary-color-15);
    padding: 4px 12px;
    border-radius: 50px;
  }
}