.articles-page {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@include respond-up('large') {
  .articles-page {
    padding-bottom: 120px;

    &__list {
      grid-row-gap: 25px;
    }
  }
}

@include respond-up('medium') {
  .articles-page {

  }
}

@include respond('medium') {
  .articles-page {
    padding-bottom: 90px;

    &__list {
      grid-row-gap: 18px;
    }
  }
}

@include respond-down('medium') {
  .articles-page {

  }
}

@include respond-down('small') {
  .articles-page {
    padding-bottom: 50px;

    &__list {
      grid-row-gap: 15px;
    }
  }
}