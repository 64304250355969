.hero {
  position: relative;
  overflow: hidden;

  &__text-wrap {
    position: relative;
    z-index: 25;
  }

  &__image-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__image-wrap {
    position: absolute;
    z-index: 20;
    bottom: 0;
    pointer-events: none;

    &_dynamic {
      right: 0;
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_dark {
    background-color: var(--black-color);

    .hero {
      &__title,
      &__description {
        color: #fff;
      }
    }
  }

  &_light-bg,
  &_blue-bg,
  &_light-blue-bg {
    .hero__inner {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: 15;
      }
    }
  }

  &_light-bg {
    .hero__inner {
      &::before {
        width: 1255px;
        height: 1323px;
        background: radial-gradient(50% 50% at 50% 50%, #CEE0F5 0%, rgba(206, 224, 245, 0) 100%);
        opacity: 0.6;
      }
    }
  }

  &_blue-bg {
    .hero__inner {
      &::before {
        background: radial-gradient(50% 50% at 50% 50%, #0473CF 0%, rgba(4, 115, 207, 0) 100%);
        opacity: 0.6;
      }
    }
  }

  &_light-blue-bg {
    .hero__inner {
      &::before {
        background: radial-gradient(50% 50% at 50% 50%, #A7B7C9 0%, rgba(167, 183, 201, 0) 100%);
        transform: rotate(-19.5deg);
      }
    }
  }
}

@include respond-up('large') {
  .hero {
    --min-hero-height: min(calc((404 / 1500) * 100vw), 511px);
    margin-top: 144px;
    min-height: var(--min-hero-height);

    &__inner {
      padding: 38px 0;
    }

    &__title {
      max-width: var(--grid-column6);
    }

    &__description {
      margin-top: 25px;
      max-width: var(--grid-column5);
    }

    &__image-wrap {
      &_static {
        left: 50%;
        transform: translateX(-50%);
        max-height: 511px;
        width: 100%;
        max-width: 1804px;
      }

      &_dynamic {
        width: var(--grid-column5);
      }
    }

    &_light-bg {
      .hero__inner {
        &::before {
          right: -250px;
          top: 40px;
        }
      }
    }

    &_blue-bg {
      .hero__inner {
        &::before {
          width: 1255px;
          height: 1323px;
          left: var(--grid-column5);
          top: -131px;
        }
      }
    }

    &_light-blue-bg {
      .hero__inner {
        &::before {
          width: 1037px;
          height: 1094px;
          left: var(--grid-column5);
          top: -131px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .hero {
    &__button {
      margin-top: 30px;
    }

    &__image-container-inner {
      position: relative;
    }
  }
}

@include respond('medium') {
  .hero {
    margin-top: 72px;

    &__inner {
      padding: 30px 0;
      height: calc((362 / 768) * 100vw);
    }

    &__title {
      max-width: var(--grid-column4);
    }

    &__description {
      max-width: var(--grid-column3);
    }

    &__image-wrap {
      &_static {
        height: calc((362 / 768) * 100vw);
      }

      &_dynamic {
        height: calc((288 / 768) * 100vw);
        width: var(--grid-column3);
      }
    }

    &_light-bg {
      .hero__inner {
        &::before {
          left: calc(var(--grid-column) * 1.5);
          top: -20px;
        }
      }
    }

    &_blue-bg {
      .hero__inner {
        &::before {
          width: 1115px;
          height: 1176px;
          left: var(--grid-column2);
          top: -114px;
        }
      }
    }

    &_light-blue-bg {
      .hero__inner {
        &::before {
          width: 953px;
          height: 1005px;
          left: calc(var(--grid-column) * 1.5);
          top: -26px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .hero {
    margin-top: 58px;
    padding-bottom: calc((253 / 375) * 100vw);

    &__inner {
      padding: 22px 0;
    }

    &__image-wrap {
      height: calc((288 / 375) * 100vw);

      &_dynamic {
        width: 100%;
      }
    }

    &__button {
      margin-top: 25px;
    }

    &_light-bg {
      .hero__inner {
        &::before {
          left: -111px;
          top: 0;
        }
      }
    }

    &_blue-bg {
      .hero__inner {
        &::before {
          width: 786px;
          height: 829px;
        }
      }
    }

    &_light-blue-bg {
      .hero__inner {
        &::before {
          width: 852px;
          height: 899px;
          right: -354px;
          top: 220px;
        }
      }
    }
  }
}