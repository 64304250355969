.profile-service {
  &__table {
    background-color: white;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__new-record {
    display: flex;
    align-items: center;
  }

  &__new-record-icon {
    display: flex;

    svg {
      width: 12px;
      height: 12px;

      rect {
         fill: var(--grey-stroke-color);
      }
    }
  }

  &__new-record-title {
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: var(--grey-text-color);
    margin-left: 6px;
    padding-top: 1px;
    transition: color .4s;
  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__item-heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__item-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 89, 36, 0.15);
    border-radius: 50px;
    color: var(--primary-color);
    width: max-content;
  }

  &__item-title {
    margin-top: 12px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin: -10px;
  }

  &__edit,
  &__remove {
    display: flex;
    padding: 10px;
  }

  &__remove {
    svg path {
      stroke: var(--grey-stroke-color);
    }
  }

  &__item-list {
    border-top: 1px solid var(--grey-stroke-color);
    margin-top: 22px;
  }

  &__item-param {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-stroke-color);
    padding: 12px 0;
  }

  &__item-param-name {
    color: var(--grey-text-color);
  }
}

@include respond-up('large') {
  .profile-service {
    &__header {
      padding: 28px 38px;
    }

    &__new-record {
      &:hover {
        .profile-service__new-record-title {
          color: var(--black-color);
        }
      }
    }

    &__item {
      padding: 35px 38px;
      //display: flex;
      //align-items: center;
    }

    &__item-param-name {
      flex: 0 0 var(--grid-column3);
    }
  }
}

@include respond-up('medium') {
  .profile-service {
    &__new-record-title {
      font-size: 14px;
    }

    &__item-number {
      padding: 3px 6px;
    }

    &__item-param-value {
      flex: 0 1 100%;
    }
  }
}

@include respond('medium') {
  .profile-service {
    &__header {
      padding: 23px 28px;
    }

    &__item {
      padding: 25px 28px;
      //grid-template-columns: 1fr 1fr 1fr 1fr auto;
      //align-items: center;
    }

    &__item-param-name {
      flex: 0 0 var(--grid-column2);
    }
  }
}

@include respond-down('medium') {
  .profile-service {
    &__item {
      //display: grid;
      //grid-gap: 0 var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .profile-service {
    &__header {
      padding: 15px 18px;
    }

    &__new-record-title {
      font-size: 13px;
    }

    &__item {
      //grid-template-columns: repeat(4, 1fr);
      padding: 25px 18px;
      //align-items: end;
    }

    &__item-number {
      padding: 4px 16px;
    }

    &__item-param-name,
    &__item-param-value {
      flex: 0 0 50%;
    }
  }
}