.content-header {
  position: relative;
  &:before{
    transition: background .3s;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: transparent;
    pointer-events: none;
  }

  &._menu-open{
    &:before{
      background: rgba(0,0,0, .3);
    }
  }

  &__guarantee {
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: radial-gradient(50% 50% at 50% 50%, #A7B7C9 0%, rgba(167, 183, 201, 0) 100%);
      transform: rotate(-19.5deg);
    }
  }

  &_dark {
    background-color: var(--black-color);

    .content-header {
      &__title {
        color: #fff;
      }
    }
  }

  &__title{
    @extend .h2;
  }
}

@include respond-up('large') {
  .content-header {
    padding-top: 144px;
    padding-bottom: 50px;

    &__guarantee {
      padding-bottom: 170px;

      &:before {
        width: 1037.28px;
        height: 1094.03px;
        left: 560.59px;
        top: 214.73px;
      }
    }

    &__title {
      margin-top: 20px;
    }

    &__date {
      margin-top: 40px;
    }

    &_dark {
      .breadcrumbs {
        &__link {
          &:hover {
            .breadcrumbs {
              &__name {
                color: #fff;
              }
            }
          }
        }
      }
    }

    &_contacts,
    &_article {
      padding-bottom: 40px;
    }
  }
}

@include respond('medium') {
  .content-header {
    padding-top: 74px;
    padding-bottom: 30px;

    &__guarantee {
      padding-bottom: 125px;

      &:before {
        width: 953.55px;
        height: 1005.72px;
        left: 38.07px;
        top: 235.97px;
      }
    }

    &__title {
      margin-top: 15px;
    }

    &__date {
      margin-top: 30px;
    }

    &_contacts,
    &_article {
      padding-bottom: 30px;
    }
  }
}

@include respond-down('small') {
  .content-header {
    padding-top: 58px;
    padding-bottom: 22px;

    &__guarantee {
      padding-bottom: 300px;

      &:before {
        width: 852.26px;
        height: 898.9px;
        left: -265.34px;
        top: 484.04px;
      }
    }

    &__title {
      margin-top: 10px;
    }

    &__date {
      margin-top: 25px;
    }

    &_contacts {
      padding-bottom: 20px;
    }

    &_article {
      padding-bottom: 25px;
    }
  }
}