.article-page {
  &__bottom {
    border-top: 1px solid var(--grey-stroke-color);
    background-color: #fff;
  }

  &__bottom-inner {
    display: flex;
  }
}

@include respond-up('large') {
  .article-page {
    &__bottom-inner {
      padding: 30px 0 120px;
    }
  }
}

@include respond-up('medium') {
  .article-page {
    &__bottom-inner {
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .article-page {
    &__bottom-inner {
      padding: 28px 0 90px;
    }
  }
}

@include respond-down('medium') {
  .article-page {

  }
}

@include respond-down('small') {
  .article-page {
    &__bottom-inner {
      padding: 20px 0 50px;
      flex-direction: column-reverse;
    }

    &__all-news {
      margin-top: 20px;
      width: 100%;
    }
  }
}