.quantity {
  display: flex;
  align-items: center;

  &__input {
    border: none;
    background-color: var(--t);
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    color: var(--black-color);
    flex: 0 0 28px;
    width: 28px;
    height: 38px;
    margin: 0 4px;
    pointer-events: none;

    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  &__button {
    background-color: unset;
    color: var(--black-color);
    border-radius: 100px;
    flex: 0 0 38px;
    width: 38px;
    height: 38px;
    border: 1px solid var(--grey-stroke-color);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
      height: 10px;
      margin: 0;
    }
  }
}