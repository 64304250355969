.cart-modal {

}

@include respond-up('large') {
  .cart-modal {
    .modal__content {
      max-width: unset;
      width: var(--grid-column8);
    }
  }
}

@include respond-up('medium') {
  .cart-modal {
    padding: 35px 28px;
  }
}

@include respond('medium') {
  .cart-modal {

  }
}

@include respond-down('medium') {
  .cart-modal {

  }
}

@include respond-down('small') {
  .cart-modal {
    .modal__container {
      padding: 73px 18px;
    }
  }
}