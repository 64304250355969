.breadcrumbs {
  &__name {
    color: var(--grey-text-color);
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;

    &_delimiter {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
      font-size: 14px;
    }
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }
}

@include respond-up("large") {
  .breadcrumbs {
    margin: 40px 0 20px;

    &__name {
      font-size: 14px;
      transition: color var(--default-transition-timing) var(--default-transition-function);
    }

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px;

      &_delimiter {
        margin: 0 2px;
      }
    }

    &__link {
      padding: 4px;
      margin: -4px;

      &:hover {
        .breadcrumbs {
          &__name {
            color: var(--black-color);
          }
        }
      }
    }

    &_light {
      .breadcrumbs {
        &__link {
          &:hover {
            .breadcrumbs {
              &__name {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@include respond("medium") {
  .breadcrumbs {
    margin: 30px 0 15px;
  }
}

@include respond-down("medium") {
  .breadcrumbs {
    &__name {
      font-size: 13px;
    }

    &__list {
      margin: -3px;
    }

    &__item {
      padding: 3px;

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
    margin: 22px 0 10px;
    &__list{
      flex-wrap: nowrap;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__name{
      width: max-content;
    }
  }
}
