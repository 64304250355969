.round-index {
  display: inline-flex;
  padding: 5px;
  background-color: var(--gray-background-color);
  border-radius: 0 26px 26px 0;

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #fff;
    width: 44px;
    height: 44px;
    background-color: var(--primary-color);
    border-radius: 100%;
  }
}

@include respond-up('large') {
  .round-index {
    padding-left: 38px;
  }
}

@include respond('medium') {
  .round-index {
    padding-left: 28px;
  }
}

@include respond-down('small') {
  .round-index {
    padding-left: 18px;
  }
}