.product-page {
  &__top-price {
    grid-column: span 1;
  }

  &__tabs {
    &_mobile {
      display: none;
    }
  }
}

@include respond-up('large') {
  .product-page {
    &__top-price {
      height: max-content;
    }

    &__same-products{
      padding-bottom: 120px;
    }
  }
}

@include respond-up('medium') {
  .product-page {
    &__top-price {
      grid-column: 2/3;
      grid-row: 1;
    }
  }
}

@include respond('medium') {
  .product-page {
    .product-base__additional {
      grid-column: span 2;
      grid-row: 2;
    }

    &__same-products{
      padding-bottom: 90px;
    }
  }
}

@include respond-down('medium') {
  .product-page {

  }
}

@include respond-down('small') {
  .product-page {
    &__top-price {
      grid-row: 2;
    }

    &__tabs {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }

    &__same-products{
      padding-bottom: 50px;
    }

    .product-base__additional {
      grid-row: 3;
    }
  }
}