.recall-form-mini {
  background-color: var(--black-color);

  &__title {
    color: #fff;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #fff;
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__data {
    display: flex;
    z-index: 10;
  }

  &__bottom {
    display: flex;
  }

  &__button-wrap {
    display: flex;
    flex: 0 0 auto;
  }

  &__policy {
    flex: 0 1 auto;
  }

  &__success {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__success-text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    color: #fff;
  }

  &__success-description {
    color: #fff;
    margin-top: 12px;
    text-align: center;
  }


  &__success-icon {

  }
}

@include respond-up('large') {
  .recall-form-mini {
    &__data,
    &__success {
      padding: 30px 45px;
    }

    &__text-wrap {
      flex: 0 0 calc((278 / 1500) * 100vw);
    }

    &__form-wrap {
      flex: 0 1 calc(100% - var(--grid-column));
      margin-left: var(--grid-column);
    }

    &__button-wrap {
      margin-left: calc((27 / 1500) * 100vw);
    }

    &__success-text-wrap {
      margin-left: 40px;
    }
  }
}

@include respond-up('medium') {
  .recall-form-mini {
    &__description {
      margin-top: 12px;
    }

    &__bottom {
      margin-top: 18px;
    }

    &__data {
      justify-content: space-between;
    }

    &__success-text-wrap {
      padding-top: 10px;
    }
  }
}

@include respond('medium') {
  .recall-form-mini {
    &__icon {
      width: 173px;
      height: 61px;
    }

    &__data,
    &__success {
      padding: 30px;
    }

    &__text-wrap {
      flex: 0 1 100%;
    }

    &__form-wrap {
      flex: 0 0 calc((353 / 768) * 100vw);
      margin-left: 60px;
    }

    &__button-wrap {
      margin-left: 20px;
    }

    &__success-text-wrap {
      margin-left: 30px;
    }
  }
}

@include respond-down('medium') {
  .recall-form-mini {

  }
}

@include respond-down('small') {
  .recall-form-mini {
    &__description {
      margin-top: 10px;
    }

    &__icon {
      width: 179px;
      height: 58px;
    }

    &__data {
      padding: 30px 25px;
      flex-direction: column;
    }

    &__success {
      padding: 30px 25px;
      flex-direction: column;
      align-items: center;
    }

    &__form-wrap {
      margin-top: 20px;
    }

    &__bottom {
      margin-top: 14px;
      flex-direction: column;
    }

    &__button-wrap {
      justify-content: flex-end;
      margin-top: 14px;
    }

    &__success-text-wrap {
      margin-top: 20px;
      align-items: center;
    }
  }
}